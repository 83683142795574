/*
File generated by js-routes 1.4.14
Based on Rails 6.1.7.7 routes of Creditos::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, error, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// abonitos_callbacks_internal_requests_amortization_tables => /callbacks/internal_requests/amortization_tables/abonitos(.:format)
  // function(options)
  abonitos_callbacks_internal_requests_amortization_tables_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"internal_requests",false],[2,[7,"/",false],[2,[6,"amortization_tables",false],[2,[7,"/",false],[2,[6,"abonitos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// about => /about(.:format)
  // function(options)
  about_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"about",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// accept_offer_project => /projects/:id/accept_offer(.:format)
  // function(id, options)
  accept_offer_project_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"accept_offer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// accept_privacity_agreement_user_arbol_score_result => /users/:user_id/arbol_score_results/:id/accept_privacity_agreement(.:format)
  // function(user_id, id, options)
  accept_privacity_agreement_user_arbol_score_result_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"accept_privacity_agreement",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// activate_financier_product_application => /financier/product_applications/:id/activate(.:format)
  // function(id, options)
  activate_financier_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"activate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_admin => /admin/admins/:id(.:format)
  // function(id, options)
  admin_admin_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_admins => /admin/admins(.:format)
  // function(options)
  admin_admins_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"admins",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_authenticated_admin => /admin(.:format)
  // function(options)
  admin_authenticated_admin_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// admin_become_user => /admin/become_user/:user_id(.:format)
  // function(user_id, options)
  admin_become_user_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"become_user",false],[2,[7,"/",false],[2,[3,"user_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_car_dealership => /admin/car_dealerships/:id(.:format)
  // function(id, options)
  admin_car_dealership_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"car_dealerships",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_car_dealership_car_price => /admin/car_dealerships/:car_dealership_id/car_prices/:id(.:format)
  // function(car_dealership_id, id, options)
  admin_car_dealership_car_price_path: Utils.route([["car_dealership_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"car_dealerships",false],[2,[7,"/",false],[2,[3,"car_dealership_id",false],[2,[7,"/",false],[2,[6,"car_prices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_car_dealership_car_prices => /admin/car_dealerships/:car_dealership_id/car_prices(.:format)
  // function(car_dealership_id, options)
  admin_car_dealership_car_prices_path: Utils.route([["car_dealership_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"car_dealerships",false],[2,[7,"/",false],[2,[3,"car_dealership_id",false],[2,[7,"/",false],[2,[6,"car_prices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_car_dealerships => /admin/car_dealerships(.:format)
  // function(options)
  admin_car_dealerships_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"car_dealerships",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_financier => /admin/financiers/:id(.:format)
  // function(id, options)
  admin_financier_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_financier_custom_field => /admin/financiers/:financier_id/custom_fields/:id(.:format)
  // function(financier_id, id, options)
  admin_financier_custom_field_path: Utils.route([["financier_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_financier_custom_fields => /admin/financiers/:financier_id/custom_fields(.:format)
  // function(financier_id, options)
  admin_financier_custom_fields_path: Utils.route([["financier_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_financier_custom_fields_configuration => /admin/financiers/:financier_id/custom_fields/configuration(.:format)
  // function(financier_id, options)
  admin_financier_custom_fields_configuration_path: Utils.route([["financier_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[6,"configuration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_financier_custom_fields_divider => /admin/financiers/:financier_id/custom_fields/dividers/:id(.:format)
  // function(financier_id, id, options)
  admin_financier_custom_fields_divider_path: Utils.route([["financier_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[6,"dividers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// admin_financier_custom_fields_dividers => /admin/financiers/:financier_id/custom_fields/dividers(.:format)
  // function(financier_id, options)
  admin_financier_custom_fields_dividers_path: Utils.route([["financier_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[6,"dividers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_financier_institution => /admin/financiers/:financier_id/institutions/:id(.:format)
  // function(financier_id, id, options)
  admin_financier_institution_path: Utils.route([["financier_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"institutions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_financier_institution_campus => /admin/financiers/:financier_id/institutions/:institution_id/campuses/:id(.:format)
  // function(financier_id, institution_id, id, options)
  admin_financier_institution_campus_path: Utils.route([["financier_id",true],["institution_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"institutions",false],[2,[7,"/",false],[2,[3,"institution_id",false],[2,[7,"/",false],[2,[6,"campuses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// admin_financier_institution_campuses => /admin/financiers/:financier_id/institutions/:institution_id/campuses(.:format)
  // function(financier_id, institution_id, options)
  admin_financier_institution_campuses_path: Utils.route([["financier_id",true],["institution_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"institutions",false],[2,[7,"/",false],[2,[3,"institution_id",false],[2,[7,"/",false],[2,[6,"campuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// admin_financier_institutions => /admin/financiers/:financier_id/institutions(.:format)
  // function(financier_id, options)
  admin_financier_institutions_path: Utils.route([["financier_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"institutions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_financiers => /admin/financiers(.:format)
  // function(options)
  admin_financiers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_password => /admins/password(.:format)
  // function(options)
  admin_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_registration => /admins(.:format)
  // function(options)
  admin_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// admin_return_to_admin => /admin/return_to_admin(.:format)
  // function(options)
  admin_return_to_admin_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"return_to_admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_session => /admins/sign_in(.:format)
  // function(options)
  admin_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_tooltip_text => /admin/tooltip_texts/:id(.:format)
  // function(id, options)
  admin_tooltip_text_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tooltip_texts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_tooltip_texts => /admin/tooltip_texts(.:format)
  // function(options)
  admin_tooltip_texts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tooltip_texts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_unlock => /admins/unlock(.:format)
  // function(options)
  admin_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_whitelabel => /admin/whitelabels/:id(.:format)
  // function(id, options)
  admin_whitelabel_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"whitelabels",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_whitelabel_activation_whitelabels => /admin/whitelabels/:whitelabel_id/activation_whitelabels(.:format)
  // function(whitelabel_id, options)
  admin_whitelabel_activation_whitelabels_path: Utils.route([["whitelabel_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"whitelabels",false],[2,[7,"/",false],[2,[3,"whitelabel_id",false],[2,[7,"/",false],[2,[6,"activation_whitelabels",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_whitelabels => /admin/whitelabels(.:format)
  // function(options)
  admin_whitelabels_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"whitelabels",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// amifins_callbacks_internal_requests_amortization_tables => /callbacks/internal_requests/amortization_tables/amifins(.:format)
  // function(options)
  amifins_callbacks_internal_requests_amortization_tables_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"internal_requests",false],[2,[7,"/",false],[2,[6,"amortization_tables",false],[2,[7,"/",false],[2,[6,"amifins",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// amz_callbacks_internal_requests_amortization_tables => /callbacks/internal_requests/amortization_tables/amz(.:format)
  // function(options)
  amz_callbacks_internal_requests_amortization_tables_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"internal_requests",false],[2,[7,"/",false],[2,[6,"amortization_tables",false],[2,[7,"/",false],[2,[6,"amz",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_api_status => /api/api_status(.:format)
  // function(options)
  api_api_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"api_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_health => /api/health(.:format)
  // function(options)
  api_health_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"health",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_v1_car_manufacturer_car_model_car_versions => /api/v1/car_manufacturers/:car_manufacturer_id/car_models/:car_model_id/car_versions(.:format)
  // function(car_manufacturer_id, car_model_id, options)
  api_v1_car_manufacturer_car_model_car_versions_path: Utils.route([["car_manufacturer_id",true],["car_model_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"car_manufacturers",false],[2,[7,"/",false],[2,[3,"car_manufacturer_id",false],[2,[7,"/",false],[2,[6,"car_models",false],[2,[7,"/",false],[2,[3,"car_model_id",false],[2,[7,"/",false],[2,[6,"car_versions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// api_v1_car_manufacturer_car_models => /api/v1/car_manufacturers/:car_manufacturer_id/car_models(.:format)
  // function(car_manufacturer_id, options)
  api_v1_car_manufacturer_car_models_path: Utils.route([["car_manufacturer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"car_manufacturers",false],[2,[7,"/",false],[2,[3,"car_manufacturer_id",false],[2,[7,"/",false],[2,[6,"car_models",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_car_manufacturers => /api/v1/car_manufacturers(.:format)
  // function(options)
  api_v1_car_manufacturers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"car_manufacturers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_car_versions => /api/v1/car_versions(.:format)
  // function(options)
  api_v1_car_versions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"car_versions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_clabe_service => /api/v1/clabe_service(.:format)
  // function(options)
  api_v1_clabe_service_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"clabe_service",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_financial_objects => /api/v1/financial_objects(.:format)
  // function(options)
  api_v1_financial_objects_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"financial_objects",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_folio => /api/v1/folios/:id(.:format)
  // function(id, options)
  api_v1_folio_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"folios",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_folio_messages => /api/v1/folios/:folio_id/messages(.:format)
  // function(folio_id, options)
  api_v1_folio_messages_path: Utils.route([["folio_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"folios",false],[2,[7,"/",false],[2,[3,"folio_id",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_folios => /api/v1/folios(.:format)
  // function(options)
  api_v1_folios_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"folios",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_institution_campuses => /api/v1/institutions/:institution_id/campuses(.:format)
  // function(institution_id, options)
  api_v1_institution_campuses_path: Utils.route([["institution_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"institutions",false],[2,[7,"/",false],[2,[3,"institution_id",false],[2,[7,"/",false],[2,[6,"campuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_institution_study_programs => /api/v1/institutions/:institution_id/study_programs(.:format)
  // function(institution_id, options)
  api_v1_institution_study_programs_path: Utils.route([["institution_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"institutions",false],[2,[7,"/",false],[2,[3,"institution_id",false],[2,[7,"/",false],[2,[6,"study_programs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_institutions => /api/v1/institutions(.:format)
  // function(options)
  api_v1_institutions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"institutions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_preapproval => /api/v1/preapprovals/:id(.:format)
  // function(id, options)
  api_v1_preapproval_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"preapprovals",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_preapprovals => /api/v1/preapprovals(.:format)
  // function(options)
  api_v1_preapprovals_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"preapprovals",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_rfc => /api/v1/rfc(.:format)
  // function(options)
  api_v1_rfc_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"rfc",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_rfc_service => /api/v1/rfc_service(.:format)
  // function(options)
  api_v1_rfc_service_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"rfc_service",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_score => /api/v1/scores/:id(.:format)
  // function(id, options)
  api_v1_score_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"scores",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_scores => /api/v1/scores(.:format)
  // function(options)
  api_v1_scores_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"scores",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_state => /api/v1/states/:id(.:format)
  // function(id, options)
  api_v1_state_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_state_cities => /api/v1/states/:state_id/cities(.:format)
  // function(state_id, options)
  api_v1_state_cities_path: Utils.route([["state_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[3,"state_id",false],[2,[7,"/",false],[2,[6,"cities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_state_city => /api/v1/states/:state_id/cities/:id(.:format)
  // function(state_id, id, options)
  api_v1_state_city_path: Utils.route([["state_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[3,"state_id",false],[2,[7,"/",false],[2,[6,"cities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_v1_state_city_suburb => /api/v1/states/:state_id/cities/:city_id/suburbs/:id(.:format)
  // function(state_id, city_id, id, options)
  api_v1_state_city_suburb_path: Utils.route([["state_id",true],["city_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[3,"state_id",false],[2,[7,"/",false],[2,[6,"cities",false],[2,[7,"/",false],[2,[3,"city_id",false],[2,[7,"/",false],[2,[6,"suburbs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// api_v1_state_city_suburbs => /api/v1/states/:state_id/cities/:city_id/suburbs(.:format)
  // function(state_id, city_id, options)
  api_v1_state_city_suburbs_path: Utils.route([["state_id",true],["city_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[3,"state_id",false],[2,[7,"/",false],[2,[6,"cities",false],[2,[7,"/",false],[2,[3,"city_id",false],[2,[7,"/",false],[2,[6,"suburbs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// api_v1_states => /api/v1/states(.:format)
  // function(options)
  api_v1_states_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"states",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_study_levels => /api/v1/study_levels(.:format)
  // function(options)
  api_v1_study_levels_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"study_levels",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_user_address => /api/v1/users/:user_id/address(.:format)
  // function(user_id, options)
  api_v1_user_address_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_user_financial_data => /api/v1/users/:user_id/financial_data(.:format)
  // function(user_id, options)
  api_v1_user_financial_data_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_user_personal_data => /api/v1/users/:user_id/personal_data(.:format)
  // function(user_id, options)
  api_v1_user_personal_data_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"personal_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v1_user_scores => /api/v1/users/:user_id/scores(.:format)
  // function(user_id, options)
  api_v1_user_scores_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"scores",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_campus => /api/v2/campuses/:id(.:format)
  // function(id, options)
  api_v2_campus_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"campuses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_financial_object => /api/v2/financial_objects/:id(.:format)
  // function(id, options)
  api_v2_financial_object_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"financial_objects",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_folio => /api/v2/folios/:id(.:format)
  // function(id, options)
  api_v2_folio_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"folios",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_folio_exporter => /api/v2/folio_exporters/:id(.:format)
  // function(id, options)
  api_v2_folio_exporter_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"folio_exporters",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_folio_historic_bureaus => /api/v2/folios/:folio_id/historic_bureaus(.:format)
  // function(folio_id, options)
  api_v2_folio_historic_bureaus_path: Utils.route([["folio_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"folios",false],[2,[7,"/",false],[2,[3,"folio_id",false],[2,[7,"/",false],[2,[6,"historic_bureaus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_folio_importers => /api/v2/folio_importers(.:format)
  // function(options)
  api_v2_folio_importers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"folio_importers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v2_folio_messages => /api/v2/folios/:folio_id/messages(.:format)
  // function(folio_id, options)
  api_v2_folio_messages_path: Utils.route([["folio_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"folios",false],[2,[7,"/",false],[2,[3,"folio_id",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_folio_update_status => /api/v2/folios/:folio_id/update_status(.:format)
  // function(folio_id, options)
  api_v2_folio_update_status_path: Utils.route([["folio_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"folios",false],[2,[7,"/",false],[2,[3,"folio_id",false],[2,[7,"/",false],[2,[6,"update_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_folios => /api/v2/folios(.:format)
  // function(options)
  api_v2_folios_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"folios",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v2_folios_update_financial_request => /api/v2/folios/update_financial_request(.:format)
  // function(options)
  api_v2_folios_update_financial_request_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"folios",false],[2,[7,"/",false],[2,[6,"update_financial_request",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_institution => /api/v2/institutions/:id(.:format)
  // function(id, options)
  api_v2_institution_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"institutions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_project => /api/v2/projects/:id(.:format)
  // function(id, options)
  api_v2_project_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_project_boa_vista_report => /api/v2/projects/:project_id/boa_vista_report(.:format)
  // function(project_id, options)
  api_v2_project_boa_vista_report_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"boa_vista_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_project_document => /api/v2/projects/:project_id/documents/:id(.:format)
  // function(project_id, id, options)
  api_v2_project_document_path: Utils.route([["project_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_v2_project_document_update_status => /api/v2/projects/:project_id/documents/:document_id/update_status(.:format)
  // function(project_id, document_id, options)
  api_v2_project_document_update_status_path: Utils.route([["project_id",true],["document_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"document_id",false],[2,[7,"/",false],[2,[6,"update_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// api_v2_project_documents => /api/v2/projects/:project_id/documents(.:format)
  // function(project_id, options)
  api_v2_project_documents_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_project_documents_by_url => /api/v2/projects/:project_id/documents_by_url(.:format)
  // function(project_id, options)
  api_v2_project_documents_by_url_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"documents_by_url",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_project_documents_by_url_index => /api/v2/projects/:project_id/documents_by_url(.:format)
  // function(project_id, options)
  api_v2_project_documents_by_url_index_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"documents_by_url",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_project_joint_obligor => /api/v2/projects/:project_id/joint_obligors/:id(.:format)
  // function(project_id, id, options)
  api_v2_project_joint_obligor_path: Utils.route([["project_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_v2_project_joint_obligors => /api/v2/projects/:project_id/joint_obligors(.:format)
  // function(project_id, options)
  api_v2_project_joint_obligors_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"joint_obligors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_project_money_plus => /api/v2/projects/:project_id/money_plus(.:format)
  // function(project_id, options)
  api_v2_project_money_plus_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"money_plus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_project_record_collection => /api/v2/projects/:project_id/record_collections/:id(.:format)
  // function(project_id, id, options)
  api_v2_project_record_collection_path: Utils.route([["project_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"record_collections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_v2_project_record_collections => /api/v2/projects/:project_id/record_collections(.:format)
  // function(project_id, options)
  api_v2_project_record_collections_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"record_collections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_project_scores => /api/v2/projects/:project_id/scores(.:format)
  // function(project_id, options)
  api_v2_project_scores_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"scores",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v2_projects => /api/v2/projects(.:format)
  // function(options)
  api_v2_projects_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"projects",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v2_session => /api/v2/sessions/:id(.:format)
  // function(id, options)
  api_v2_session_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_sessions => /api/v2/sessions(.:format)
  // function(options)
  api_v2_sessions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"sessions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v2_study_level => /api/v2/study_levels/:id(.:format)
  // function(id, options)
  api_v2_study_level_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"study_levels",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_users => /api/v2/users(.:format)
  // function(options)
  api_v2_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v3_catalog => /api/v3/catalog(.:format)
  // function(options)
  api_v3_catalog_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"catalog",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v3_catalogs => /api/v3/catalogs(.:format)
  // function(options)
  api_v3_catalogs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"catalogs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v3_folio => /api/v3/folios/:id(.:format)
  // function(id, options)
  api_v3_folio_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"folios",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v3_folio_status_histories => /api/v3/folios/:folio_id/status_histories(.:format)
  // function(folio_id, options)
  api_v3_folio_status_histories_path: Utils.route([["folio_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"folios",false],[2,[7,"/",false],[2,[3,"folio_id",false],[2,[7,"/",false],[2,[6,"status_histories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v3_folio_validation_incomplete_folios => /api/v3/folios/:folio_id/validation_incomplete_folios(.:format)
  // function(folio_id, options)
  api_v3_folio_validation_incomplete_folios_path: Utils.route([["folio_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"folios",false],[2,[7,"/",false],[2,[3,"folio_id",false],[2,[7,"/",false],[2,[6,"validation_incomplete_folios",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_v3_folios => /api/v3/folios(.:format)
  // function(options)
  api_v3_folios_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"folios",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v3_user => /api/v3/users/:id(.:format)
  // function(id, options)
  api_v3_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v3_users_product_applications => /api/v3/users/product_applications(.:format)
  // function(options)
  api_v3_users_product_applications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"product_applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// authenticated_admin => /
  // function(options)
  authenticated_admin_path: Utils.route([], {}, [7,"/",false]),
// authenticated_car_dealership_user => /
  // function(options)
  authenticated_car_dealership_user_path: Utils.route([], {}, [7,"/",false]),
// authenticated_financier_user => /
  // function(options)
  authenticated_financier_user_path: Utils.route([], {}, [7,"/",false]),
// authenticated_observer => /
  // function(options)
  authenticated_observer_path: Utils.route([], {}, [7,"/",false]),
// authenticated_user => /
  // function(options)
  authenticated_user_path: Utils.route([], {}, [7,"/",false]),
// authorization_bureau_rejected => /authorization_bureau_rejected/:uuid(.:format)
  // function(uuid, options)
  authorization_bureau_rejected_path: Utils.route([["uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"authorization_bureau_rejected",false],[2,[7,"/",false],[2,[3,"uuid",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bill_document_callbacks_customers_kavak_clouds => /callbacks/customers/kavak_clouds/bill_document(.:format)
  // function(options)
  bill_document_callbacks_customers_kavak_clouds_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"kavak_clouds",false],[2,[7,"/",false],[2,[6,"bill_document",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// blog => /blog(.:format)
  // function(options)
  blog_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"blog",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// blog_post => /blog/:id(.:format)
  // function(id, options)
  blog_post_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"blog",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_send_to_financier_product_applications => /product_applications/bulk_send_to_financier(.:format)
  // function(options)
  bulk_send_to_financier_product_applications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[6,"bulk_send_to_financier",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bureau_request_format_financier_arbol_score_result => /financier/arbol_score_results/:id/bureau_request_format(.:format)
  // function(id, options)
  bureau_request_format_financier_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"bureau_request_format",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// bureau_request_format_observer_arbol_score_result => /observer/arbol_score_results/:id/bureau_request_format(.:format)
  // function(id, options)
  bureau_request_format_observer_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"bureau_request_format",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business => /business/:id(.:format)
  // function(id, options)
  business_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// business_address => /business/:business_id/addresses/:id(.:format)
  // function(business_id, id, options)
  business_address_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_addresses => /business/:business_id/addresses(.:format)
  // function(business_id, options)
  business_addresses_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_authorized_person => /business/:business_id/authorized_persons/:id(.:format)
  // function(business_id, id, options)
  business_authorized_person_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"authorized_persons",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_authorized_persons => /business/:business_id/authorized_persons(.:format)
  // function(business_id, options)
  business_authorized_persons_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"authorized_persons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_bank_account => /business/:business_id/bank_accounts/:id(.:format)
  // function(business_id, id, options)
  business_bank_account_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_bank_accounts => /business/:business_id/bank_accounts(.:format)
  // function(business_id, options)
  business_bank_accounts_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_beneficiary_account => /business/:business_id/beneficiary_accounts/:id(.:format)
  // function(business_id, id, options)
  business_beneficiary_account_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"beneficiary_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_beneficiary_accounts => /business/:business_id/beneficiary_accounts(.:format)
  // function(business_id, options)
  business_beneficiary_accounts_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"beneficiary_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_business_data => /business/:business_id/business_data(.:format)
  // function(business_id, options)
  business_business_data_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"business_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_customer => /business/:business_id/customers/:id(.:format)
  // function(business_id, id, options)
  business_customer_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_customers => /business/:business_id/customers(.:format)
  // function(business_id, options)
  business_customers_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_document => /business/:business_id/documents/:id(.:format)
  // function(business_id, id, options)
  business_document_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_document_type => /business/:business_id/document_types/:type(.:format)
  // function(business_id, type, options)
  business_document_type_path: Utils.route([["business_id",true],["type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_document_types => /business/:business_id/document_types(.:format)
  // function(business_id, options)
  business_document_types_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_documents => /business/:business_id/documents(.:format)
  // function(business_id, options)
  business_documents_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_financial_data => /business/:business_id/financial_data(.:format)
  // function(business_id, options)
  business_financial_data_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_guarantor => /business/:business_id/guarantors/:id(.:format)
  // function(business_id, id, options)
  business_guarantor_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_guarantors => /business/:business_id/guarantors(.:format)
  // function(business_id, options)
  business_guarantors_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_heritage => /business/:business_id/heritages/:id(.:format)
  // function(business_id, id, options)
  business_heritage_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_heritages => /business/:business_id/heritages(.:format)
  // function(business_id, options)
  business_heritages_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_index => /business(.:format)
  // function(options)
  business_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// business_judgment => /business/:business_id/judgments/:id(.:format)
  // function(business_id, id, options)
  business_judgment_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_judgments => /business/:business_id/judgments(.:format)
  // function(business_id, options)
  business_judgments_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_main_competitor => /business/:business_id/main_competitors/:id(.:format)
  // function(business_id, id, options)
  business_main_competitor_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"main_competitors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_main_competitors => /business/:business_id/main_competitors(.:format)
  // function(business_id, options)
  business_main_competitors_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"main_competitors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_moral_shareholder => /business/:business_id/moral_shareholders/:id(.:format)
  // function(business_id, id, options)
  business_moral_shareholder_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"moral_shareholders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_moral_shareholders => /business/:business_id/moral_shareholders(.:format)
  // function(business_id, options)
  business_moral_shareholders_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"moral_shareholders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_product_service => /business/:business_id/product_services/:id(.:format)
  // function(business_id, id, options)
  business_product_service_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_product_services => /business/:business_id/product_services(.:format)
  // function(business_id, options)
  business_product_services_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_provider => /business/:business_id/providers/:id(.:format)
  // function(business_id, id, options)
  business_provider_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_providers => /business/:business_id/providers(.:format)
  // function(business_id, options)
  business_providers_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"providers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_real_owner => /business/:business_id/real_owners/:id(.:format)
  // function(business_id, id, options)
  business_real_owner_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"real_owners",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_real_owners => /business/:business_id/real_owners(.:format)
  // function(business_id, options)
  business_real_owners_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"real_owners",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_shareholder => /business/:business_id/shareholders/:id(.:format)
  // function(business_id, id, options)
  business_shareholder_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"shareholders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_shareholders => /business/:business_id/shareholders(.:format)
  // function(business_id, options)
  business_shareholders_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"shareholders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_stp_researchable_account => /business/:business_id/stp_researchable_accounts/:id(.:format)
  // function(business_id, id, options)
  business_stp_researchable_account_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"stp_researchable_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_stp_researchable_accounts => /business/:business_id/stp_researchable_accounts(.:format)
  // function(business_id, options)
  business_stp_researchable_accounts_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"stp_researchable_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// by_country_api_v1_states => /api/v1/states/by_country(.:format)
  // function(options)
  by_country_api_v1_states_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"states",false],[2,[7,"/",false],[2,[6,"by_country",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// by_product_financier_product_rules_rejections => /financier/products/:product_id/rules_rejections/by_product(.:format)
  // function(product_id, options)
  by_product_financier_product_rules_rejections_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"rules_rejections",false],[2,[7,"/",false],[2,[6,"by_product",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// callbacks_customers_kavak_clouds => /callbacks/customers/kavak_clouds(.:format)
  // function(options)
  callbacks_customers_kavak_clouds_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"kavak_clouds",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// callbacks_generated_documents_conversions_callback => /callbacks/generated_documents/conversions/callback(.:format)
  // function(options)
  callbacks_generated_documents_conversions_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"generated_documents",false],[2,[7,"/",false],[2,[6,"conversions",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// callbacks_generated_documents_firmamex_callback => /callbacks/generated_documents/firmamex/callback(.:format)
  // function(options)
  callbacks_generated_documents_firmamex_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"generated_documents",false],[2,[7,"/",false],[2,[6,"firmamex",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// callbacks_generated_documents_mi_fiel_callback => /callbacks/generated_documents/mi_fiel/callback(.:format)
  // function(options)
  callbacks_generated_documents_mi_fiel_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"generated_documents",false],[2,[7,"/",false],[2,[6,"mi_fiel",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// callbacks_stp_responses => /callbacks/stp_responses(.:format)
  // function(options)
  callbacks_stp_responses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"stp_responses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// callbacks_veridoc_id => /callbacks/veridoc_id(.:format)
  // function(options)
  callbacks_veridoc_id_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"veridoc_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// callbacks_verification_request_callback => /callbacks/verification_requests/:verification_request_id/callback(.:format)
  // function(verification_request_id, options)
  callbacks_verification_request_callback_path: Utils.route([["verification_request_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"verification_requests",false],[2,[7,"/",false],[2,[3,"verification_request_id",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// cancel_admin_admin => /admin/admins/:id/cancel(.:format)
  // function(id, options)
  cancel_admin_admin_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// cancel_api_v3_folio => /api/v3/folios/:id/cancel(.:format)
  // function(id, options)
  cancel_api_v3_folio_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"folios",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// cancel_car_dealership_user_registration => /agencia/cuenta/cancel(.:format)
  // function(options)
  cancel_car_dealership_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"cuenta",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_financier_arbol_score_result => /financier/arbol_score_results/:id/cancel(.:format)
  // function(id, options)
  cancel_financier_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// cancel_financier_product_application => /financier/product_applications/:id/cancel(.:format)
  // function(id, options)
  cancel_financier_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// cancel_folio_api_v3_score => /api/v3/scores/:id/cancel_folio(.:format)
  // function(id, options)
  cancel_folio_api_v3_score_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"scores",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel_folio",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// cancel_user_registration => /users/cancel(.:format)
  // function(options)
  cancel_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// car_dealership_user_authenticated_car_dealership_user => /agencia(.:format)
  // function(options)
  car_dealership_user_authenticated_car_dealership_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// car_dealership_user_become_user => /agencia/become_user/:user_id(.:format)
  // function(user_id, options)
  car_dealership_user_become_user_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"become_user",false],[2,[7,"/",false],[2,[3,"user_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// car_dealership_user_password => /agencia/cuenta/password(.:format)
  // function(options)
  car_dealership_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"cuenta",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// car_dealership_user_product_application => /agencia/solicitudes/:id(.:format)
  // function(id, options)
  car_dealership_user_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"solicitudes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// car_dealership_user_product_application_reports_csv => /agencia/solicitudes/:product_application_id/reports/csv(.:format)
  // function(product_application_id, options)
  car_dealership_user_product_application_reports_csv_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"solicitudes",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// car_dealership_user_product_application_reports_xml => /agencia/solicitudes/:product_application_id/reports/xml(.:format)
  // function(product_application_id, options)
  car_dealership_user_product_application_reports_xml_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"solicitudes",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"xml",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// car_dealership_user_product_application_reports_zipfile => /agencia/solicitudes/:product_application_id/reports/zipfile(.:format)
  // function(product_application_id, options)
  car_dealership_user_product_application_reports_zipfile_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"solicitudes",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"zipfile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// car_dealership_user_product_applications => /agencia/solicitudes(.:format)
  // function(options)
  car_dealership_user_product_applications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"solicitudes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// car_dealership_user_registration => /agencia/cuenta(.:format)
  // function(options)
  car_dealership_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"cuenta",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// car_dealership_user_return_to_car_dealership_user => /agencia/return_to_car_dealership_user(.:format)
  // function(options)
  car_dealership_user_return_to_car_dealership_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"return_to_car_dealership_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// car_dealership_user_session => /agencia/cuenta/sign_in(.:format)
  // function(options)
  car_dealership_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"cuenta",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// car_dealership_user_user => /agencia/clientes/:id(.:format)
  // function(id, options)
  car_dealership_user_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// car_dealership_user_user_document => /agencia/clientes/:user_id/documents/:id(.:format)
  // function(user_id, id, options)
  car_dealership_user_user_document_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// car_dealership_user_user_documents => /agencia/clientes/:user_id/documents(.:format)
  // function(user_id, options)
  car_dealership_user_user_documents_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// car_dealership_user_users => /agencia/clientes(.:format)
  // function(options)
  car_dealership_user_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"clientes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ch_capitals_callbacks_internal_requests_amortization_tables => /callbacks/internal_requests/amortization_tables/ch_capitals(.:format)
  // function(options)
  ch_capitals_callbacks_internal_requests_amortization_tables_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"internal_requests",false],[2,[7,"/",false],[2,[6,"amortization_tables",false],[2,[7,"/",false],[2,[6,"ch_capitals",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// change_request_kyc_financier_product => /financier/products/:id/change_request_kyc(.:format)
  // function(id, options)
  change_request_kyc_financier_product_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_request_kyc",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// change_score_financier_arbol_score_result => /financier/arbol_score_results/:id/change_score(.:format)
  // function(id, options)
  change_score_financier_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_score",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// change_score_observer_arbol_score_result => /observer/arbol_score_results/:id/change_score(.:format)
  // function(id, options)
  change_score_observer_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_score",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// change_status_financier_preapproval_result => /financier/preapproval_results/:id/change_status(.:format)
  // function(id, options)
  change_status_financier_preapproval_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"preapproval_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// change_status_financier_product_application => /financier/product_applications/:id/change_status(.:format)
  // function(id, options)
  change_status_financier_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// change_status_financier_product_custom_smart_score_cluster => /financier/products/:product_id/custom_smart_scores/:custom_smart_score_id/clusters/:id/change_status(.:format)
  // function(product_id, custom_smart_score_id, id, options)
  change_status_financier_product_custom_smart_score_cluster_path: Utils.route([["product_id",true],["custom_smart_score_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_smart_scores",false],[2,[7,"/",false],[2,[3,"custom_smart_score_id",false],[2,[7,"/",false],[2,[6,"clusters",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// change_status_financier_product_smart_score_rule => /financier/products/:product_id/smart_score_rules/:id/change_status(.:format)
  // function(product_id, id, options)
  change_status_financier_product_smart_score_rule_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"smart_score_rules",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// check_credentials_status_satws_reports => /satws_reports/check_credentials_status(.:format)
  // function(options)
  check_credentials_status_satws_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"satws_reports",false],[2,[7,"/",false],[2,[6,"check_credentials_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// check_for_data_completion_users => /users/check_for_data_completion(.:format)
  // function(options)
  check_for_data_completion_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"check_for_data_completion",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// check_for_status_sent_or_failed_product_application => /product_applications/:id/check_for_status_sent_or_failed(.:format)
  // function(id, options)
  check_for_status_sent_or_failed_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"check_for_status_sent_or_failed",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// close_financier_product_application => /financier/product_applications/:id/close(.:format)
  // function(id, options)
  close_financier_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"close",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// complete_actors_project => /projects/:id/complete_actors(.:format)
  // function(id, options)
  complete_actors_project_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"complete_actors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// confirm_project_videocall => /projects/:project_id/videocalls/:id/confirm(.:format)
  // function(project_id, id, options)
  confirm_project_videocall_path: Utils.route([["project_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"videocalls",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirm",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// copy_address_to_jo_financier_arbol_score_result => /financier/arbol_score_results/:id/copy_address_to_jo(.:format)
  // function(id, options)
  copy_address_to_jo_financier_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_address_to_jo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// copy_address_to_jo_user_arbol_score_result => /users/:user_id/arbol_score_results/:id/copy_address_to_jo(.:format)
  // function(user_id, id, options)
  copy_address_to_jo_user_arbol_score_result_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_address_to_jo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// copy_address_to_jopfae_financier_arbol_score_result => /financier/arbol_score_results/:id/copy_address_to_jopfae(.:format)
  // function(id, options)
  copy_address_to_jopfae_financier_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_address_to_jopfae",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// create_credentials_satws_reports => /satws_reports/create_credentials(.:format)
  // function(options)
  create_credentials_satws_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"satws_reports",false],[2,[7,"/",false],[2,[6,"create_credentials",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_user_observer_arbol_score_results => /observer/arbol_score_results/create_user(.:format)
  // function(options)
  create_user_observer_arbol_score_results_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[6,"create_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// credit_renews => /credit_renews/:id(.:format)
  // function(id, options)
  credit_renews_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"credit_renews",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// credit_renews_index => /credit_renews(.:format)
  // function(options)
  credit_renews_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"credit_renews",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// credit_report_request_pin_code => /request_pin_code/:uuid(.:format)
  // function(uuid, options)
  credit_report_request_pin_code_path: Utils.route([["uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"request_pin_code",false],[2,[7,"/",false],[2,[3,"uuid",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// credit_report_request_report => /request_report/:uuid(.:format)
  // function(uuid, options)
  credit_report_request_report_path: Utils.route([["uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"request_report",false],[2,[7,"/",false],[2,[3,"uuid",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// credit_report_request_report_with_document => /request_report_with_document/:uuid(.:format)
  // function(uuid, options)
  credit_report_request_report_with_document_path: Utils.route([["uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"request_report_with_document",false],[2,[7,"/",false],[2,[3,"uuid",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// credit_report_request_report_with_pin_code => /request_report_with_pin_code/:uuid(.:format)
  // function(uuid, options)
  credit_report_request_report_with_pin_code_path: Utils.route([["uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"request_report_with_pin_code",false],[2,[7,"/",false],[2,[3,"uuid",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// credit_report_status => /report_status/:uuid(.:format)
  // function(uuid, options)
  credit_report_status_path: Utils.route([["uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"report_status",false],[2,[7,"/",false],[2,[3,"uuid",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// credit_report_validate_pin_code => /validate_pin_code/:uuid(.:format)
  // function(uuid, options)
  credit_report_validate_pin_code_path: Utils.route([["uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"validate_pin_code",false],[2,[7,"/",false],[2,[3,"uuid",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// current_api_v1_users => /api/v1/users/current(.:format)
  // function(options)
  current_api_v1_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"current",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// custom_search_products => /products/custom_search(.:format)
  // function(options)
  custom_search_products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[6,"custom_search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// delayed_job => /delayed_job(.:format)
  // function(options)
  delayed_job_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"delayed_job",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// delete_admin_financier_custom_field => /admin/financiers/:financier_id/custom_fields/:id/delete(.:format)
  // function(financier_id, id, options)
  delete_admin_financier_custom_field_path: Utils.route([["financier_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// destroy_admin_session => /admins/sign_out(.:format)
  // function(options)
  destroy_admin_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_car_dealership_user_session => /agencia/cuenta/sign_out(.:format)
  // function(options)
  destroy_car_dealership_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"cuenta",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// destroy_financier_user_session => /financier_users/sign_out(.:format)
  // function(options)
  destroy_financier_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_multiple_financier_leads => /financier/leads/destroy_multiple(.:format)
  // function(options)
  destroy_multiple_financier_leads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"leads",false],[2,[7,"/",false],[2,[6,"destroy_multiple",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// destroy_observer_session => /observers/sign_out(.:format)
  // function(options)
  destroy_observer_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"observers",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// discards_financier_product_application_tabs_generated_document_index => /financier/product_applications/:product_application_id/tabs/generated_document/discards(.:format)
  // function(product_application_id, options)
  discards_financier_product_application_tabs_generated_document_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"generated_document",false],[2,[7,"/",false],[2,[6,"discards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// download_admin_tooltip_texts => /admin/tooltip_texts/download(.:format)
  // function(options)
  download_admin_tooltip_texts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tooltip_texts",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// duplicate_financier_product_custom_smart_score => /financier/products/:product_id/custom_smart_scores/:id/duplicate(.:format)
  // function(product_id, id, options)
  duplicate_financier_product_custom_smart_score_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_smart_scores",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"duplicate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// duplicate_financier_product_custom_smart_score_cluster => /financier/products/:product_id/custom_smart_scores/:custom_smart_score_id/clusters/:id/duplicate(.:format)
  // function(product_id, custom_smart_score_id, id, options)
  duplicate_financier_product_custom_smart_score_cluster_path: Utils.route([["product_id",true],["custom_smart_score_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_smart_scores",false],[2,[7,"/",false],[2,[3,"custom_smart_score_id",false],[2,[7,"/",false],[2,[6,"clusters",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"duplicate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// duplicate_financier_product_smart_score_rule => /financier/products/:product_id/smart_score_rules/:id/duplicate(.:format)
  // function(product_id, id, options)
  duplicate_financier_product_smart_score_rule_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"smart_score_rules",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"duplicate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_admin => /admin/admins/:id/edit(.:format)
  // function(id, options)
  edit_admin_admin_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_car_dealership => /admin/car_dealerships/:id/edit(.:format)
  // function(id, options)
  edit_admin_car_dealership_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"car_dealerships",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_car_dealership_car_price => /admin/car_dealerships/:car_dealership_id/car_prices/:id/edit(.:format)
  // function(car_dealership_id, id, options)
  edit_admin_car_dealership_car_price_path: Utils.route([["car_dealership_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"car_dealerships",false],[2,[7,"/",false],[2,[3,"car_dealership_id",false],[2,[7,"/",false],[2,[6,"car_prices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_financier => /admin/financiers/:id/edit(.:format)
  // function(id, options)
  edit_admin_financier_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_financier_custom_field => /admin/financiers/:financier_id/custom_fields/:id/edit(.:format)
  // function(financier_id, id, options)
  edit_admin_financier_custom_field_path: Utils.route([["financier_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_financier_custom_fields_configuration => /admin/financiers/:financier_id/custom_fields/configuration/edit(.:format)
  // function(financier_id, options)
  edit_admin_financier_custom_fields_configuration_path: Utils.route([["financier_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[6,"configuration",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_financier_custom_fields_divider => /admin/financiers/:financier_id/custom_fields/dividers/:id/edit(.:format)
  // function(financier_id, id, options)
  edit_admin_financier_custom_fields_divider_path: Utils.route([["financier_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[6,"dividers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// edit_admin_financier_institution => /admin/financiers/:financier_id/institutions/:id/edit(.:format)
  // function(financier_id, id, options)
  edit_admin_financier_institution_path: Utils.route([["financier_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"institutions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_financier_institution_campus => /admin/financiers/:financier_id/institutions/:institution_id/campuses/:id/edit(.:format)
  // function(financier_id, institution_id, id, options)
  edit_admin_financier_institution_campus_path: Utils.route([["financier_id",true],["institution_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"institutions",false],[2,[7,"/",false],[2,[3,"institution_id",false],[2,[7,"/",false],[2,[6,"campuses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_admin_password => /admins/password/edit(.:format)
  // function(options)
  edit_admin_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_admin_registration => /admins/edit(.:format)
  // function(options)
  edit_admin_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_admin_tooltip_text => /admin/tooltip_texts/:id/edit(.:format)
  // function(id, options)
  edit_admin_tooltip_text_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tooltip_texts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_whitelabel => /admin/whitelabels/:id/edit(.:format)
  // function(id, options)
  edit_admin_whitelabel_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"whitelabels",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_business => /business/:id/edit(.:format)
  // function(id, options)
  edit_business_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_business_address => /business/:business_id/addresses/:id/edit(.:format)
  // function(business_id, id, options)
  edit_business_address_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_authorized_person => /business/:business_id/authorized_persons/:id/edit(.:format)
  // function(business_id, id, options)
  edit_business_authorized_person_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"authorized_persons",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_bank_account => /business/:business_id/bank_accounts/:id/edit(.:format)
  // function(business_id, id, options)
  edit_business_bank_account_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_beneficiary_account => /business/:business_id/beneficiary_accounts/:id/edit(.:format)
  // function(business_id, id, options)
  edit_business_beneficiary_account_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"beneficiary_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_business_data => /business/:business_id/business_data/edit(.:format)
  // function(business_id, options)
  edit_business_business_data_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"business_data",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_business_customer => /business/:business_id/customers/:id/edit(.:format)
  // function(business_id, id, options)
  edit_business_customer_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_financial_data => /business/:business_id/financial_data/edit(.:format)
  // function(business_id, options)
  edit_business_financial_data_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_business_guarantor => /business/:business_id/guarantors/:id/edit(.:format)
  // function(business_id, id, options)
  edit_business_guarantor_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_heritage => /business/:business_id/heritages/:id/edit(.:format)
  // function(business_id, id, options)
  edit_business_heritage_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_judgment => /business/:business_id/judgments/:id/edit(.:format)
  // function(business_id, id, options)
  edit_business_judgment_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_main_competitor => /business/:business_id/main_competitors/:id/edit(.:format)
  // function(business_id, id, options)
  edit_business_main_competitor_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"main_competitors",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_moral_shareholder => /business/:business_id/moral_shareholders/:id/edit(.:format)
  // function(business_id, id, options)
  edit_business_moral_shareholder_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"moral_shareholders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_product_service => /business/:business_id/product_services/:id/edit(.:format)
  // function(business_id, id, options)
  edit_business_product_service_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_provider => /business/:business_id/providers/:id/edit(.:format)
  // function(business_id, id, options)
  edit_business_provider_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_real_owner => /business/:business_id/real_owners/:id/edit(.:format)
  // function(business_id, id, options)
  edit_business_real_owner_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"real_owners",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_shareholder => /business/:business_id/shareholders/:id/edit(.:format)
  // function(business_id, id, options)
  edit_business_shareholder_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"shareholders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_stp_researchable_account => /business/:business_id/stp_researchable_accounts/:id/edit(.:format)
  // function(business_id, id, options)
  edit_business_stp_researchable_account_path: Utils.route([["business_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"stp_researchable_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_car_dealership_user_password => /agencia/cuenta/password/edit(.:format)
  // function(options)
  edit_car_dealership_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"cuenta",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_car_dealership_user_registration => /agencia/cuenta/edit(.:format)
  // function(options)
  edit_car_dealership_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"cuenta",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_car_dealership_user_user_document => /agencia/clientes/:user_id/documents/:id/edit(.:format)
  // function(user_id, id, options)
  edit_car_dealership_user_user_document_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_credit_renews => /credit_renews/:id/edit(.:format)
  // function(id, options)
  edit_credit_renews_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"credit_renews",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_financier_arbol_score_result => /financier/arbol_score_results/:id/edit(.:format)
  // function(id, options)
  edit_financier_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_bureau_template => /financier/bureau_templates/:id/edit(.:format)
  // function(id, options)
  edit_financier_bureau_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"bureau_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_campaign => /financier/campaigns/:id/edit(.:format)
  // function(id, options)
  edit_financier_campaign_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_contact_request => /financier/contact_requests/:id/edit(.:format)
  // function(id, options)
  edit_financier_contact_request_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"contact_requests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_custom_field => /financier/custom_fields/:id/edit(.:format)
  // function(id, options)
  edit_financier_custom_field_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_document_template => /financier/document_templates/:id/edit(.:format)
  // function(id, options)
  edit_financier_document_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"document_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_external_api => /financier/external_apis/:id/edit(.:format)
  // function(id, options)
  edit_financier_external_api_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"external_apis",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_financier_user => /financier/financier_users/:id/edit(.:format)
  // function(id, options)
  edit_financier_financier_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_landing_search_group => /financier/landing_search_groups/:id/edit(.:format)
  // function(id, options)
  edit_financier_landing_search_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"landing_search_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_lead => /financier/leads/:id/edit(.:format)
  // function(id, options)
  edit_financier_lead_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"leads",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_product => /financier/products/:id/edit(.:format)
  // function(id, options)
  edit_financier_product_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_product_application => /financier/product_applications/:id/edit(.:format)
  // function(id, options)
  edit_financier_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_product_application_money_plus_request => /financier/product_applications/:product_application_id/money_plus_requests/:id/edit(.:format)
  // function(product_application_id, id, options)
  edit_financier_product_application_money_plus_request_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"money_plus_requests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_financier_product_application_product_application_message => /financier/product_applications/:product_application_id/product_application_messages/:id/edit(.:format)
  // function(product_application_id, id, options)
  edit_financier_product_application_product_application_message_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"product_application_messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_financier_product_application_sat_report => /financier/product_applications/:product_application_id/sat_reports/:id/edit(.:format)
  // function(product_application_id, id, options)
  edit_financier_product_application_sat_report_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"sat_reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_financier_product_application_video_records => /financier/product_applications/:product_application_id/video_records/edit(.:format)
  // function(product_application_id, options)
  edit_financier_product_application_video_records_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"video_records",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_financier_product_application_videocall => /financier/product_applications/:product_application_id/videocalls/:id/edit(.:format)
  // function(product_application_id, id, options)
  edit_financier_product_application_videocall_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"videocalls",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_financier_product_custom_form => /financier/products/:product_id/custom_form/edit(.:format)
  // function(product_id, options)
  edit_financier_product_custom_form_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_form",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_financier_product_custom_preapproval_form => /financier/products/:product_id/custom_preapproval_form/edit(.:format)
  // function(product_id, options)
  edit_financier_product_custom_preapproval_form_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_preapproval_form",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_financier_product_custom_profile_score => /financier/products/:product_id/custom_profile_score/edit(.:format)
  // function(product_id, options)
  edit_financier_product_custom_profile_score_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_profile_score",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_financier_product_custom_score => /financier/products/:product_id/custom_score/edit(.:format)
  // function(product_id, options)
  edit_financier_product_custom_score_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_score",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_financier_product_custom_smart_score_cluster => /financier/products/:product_id/custom_smart_scores/:custom_smart_score_id/clusters/:id/edit(.:format)
  // function(product_id, custom_smart_score_id, id, options)
  edit_financier_product_custom_smart_score_cluster_path: Utils.route([["product_id",true],["custom_smart_score_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_smart_scores",false],[2,[7,"/",false],[2,[3,"custom_smart_score_id",false],[2,[7,"/",false],[2,[6,"clusters",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_financier_product_custom_smart_score_custom_score_profile => /financier/products/:product_id/custom_smart_scores/:custom_smart_score_id/custom_score_profiles/:id/edit(.:format)
  // function(product_id, custom_smart_score_id, id, options)
  edit_financier_product_custom_smart_score_custom_score_profile_path: Utils.route([["product_id",true],["custom_smart_score_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_smart_scores",false],[2,[7,"/",false],[2,[3,"custom_smart_score_id",false],[2,[7,"/",false],[2,[6,"custom_score_profiles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_financier_product_document_catalog => /financier/products/:product_id/document_catalogs/:id/edit(.:format)
  // function(product_id, id, options)
  edit_financier_product_document_catalog_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"document_catalogs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_financier_product_document_catalog_document_type => /financier/products/:product_id/document_catalogs/:document_catalog_id/document_types/:id/edit(.:format)
  // function(product_id, document_catalog_id, id, options)
  edit_financier_product_document_catalog_document_type_path: Utils.route([["product_id",true],["document_catalog_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"document_catalogs",false],[2,[7,"/",false],[2,[3,"document_catalog_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_financier_product_landing_page => /financier/product_landing_pages/:id/edit(.:format)
  // function(id, options)
  edit_financier_product_landing_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_landing_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_product_media_record_motive => /financier/products/:product_id/media_record_motives/:id/edit(.:format)
  // function(product_id, id, options)
  edit_financier_product_media_record_motive_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"media_record_motives",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_financier_product_product_application_priority => /financier/products/:product_id/product_application_priorities/:id/edit(.:format)
  // function(product_id, id, options)
  edit_financier_product_product_application_priority_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"product_application_priorities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_financier_product_profile => /financier/products/:product_id/profiles/:id/edit(.:format)
  // function(product_id, id, options)
  edit_financier_product_profile_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"profiles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_financier_product_profiles_offers => /financier/products/:product_id/profiles_offers/edit(.:format)
  // function(product_id, options)
  edit_financier_product_profiles_offers_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"profiles_offers",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_financier_product_rules_rejections => /financier/products/:product_id/rules_rejections/edit(.:format)
  // function(product_id, options)
  edit_financier_product_rules_rejections_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"rules_rejections",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_financier_product_sat_report_settings => /financier/products/:product_id/sat_report_settings/edit(.:format)
  // function(product_id, options)
  edit_financier_product_sat_report_settings_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"sat_report_settings",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_financier_product_smart_score_rule => /financier/products/:product_id/smart_score_rules/:id/edit(.:format)
  // function(product_id, id, options)
  edit_financier_product_smart_score_rule_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"smart_score_rules",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_financier_product_videocall_event => /financier/products/:product_id/videocall_events/:id/edit(.:format)
  // function(product_id, id, options)
  edit_financier_product_videocall_event_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"videocall_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_financier_product_workflow => /financier/products/:product_id/workflow/edit(.:format)
  // function(product_id, options)
  edit_financier_product_workflow_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"workflow",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_financier_report_report_custom_form => /financier/reports/:report_id/report_custom_form/edit(.:format)
  // function(report_id, options)
  edit_financier_report_report_custom_form_path: Utils.route([["report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_id",false],[2,[7,"/",false],[2,[6,"report_custom_form",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_financier_settings_arbol_score_results => /financier/settings/arbol_score_results/edit(.:format)
  // function(options)
  edit_financier_settings_arbol_score_results_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_settings_asr_thank_you_page => /financier/settings/asr_thank_you_page/edit(.:format)
  // function(options)
  edit_financier_settings_asr_thank_you_page_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"asr_thank_you_page",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_settings_branding => /financier/settings/branding/edit(.:format)
  // function(options)
  edit_financier_settings_branding_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"branding",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_settings_bureau_member_code => /financier/settings/bureau_member_code/edit(.:format)
  // function(options)
  edit_financier_settings_bureau_member_code_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"bureau_member_code",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_settings_bureau_moral_member_code => /financier/settings/bureau_moral_member_code/edit(.:format)
  // function(options)
  edit_financier_settings_bureau_moral_member_code_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"bureau_moral_member_code",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_settings_circulo_report => /financier/settings/circulo_report/edit(.:format)
  // function(options)
  edit_financier_settings_circulo_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"circulo_report",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_settings_contact_details => /financier/settings/contact_details/edit(.:format)
  // function(options)
  edit_financier_settings_contact_details_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"contact_details",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_settings_custom_catalog => /financier/settings/custom_catalogs/:id/edit(.:format)
  // function(id, options)
  edit_financier_settings_custom_catalog_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"custom_catalogs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_financier_settings_general_settings => /financier/settings/general_settings/edit(.:format)
  // function(options)
  edit_financier_settings_general_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"general_settings",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_settings_money_plus_settings => /financier/settings/money_plus_settings/edit(.:format)
  // function(options)
  edit_financier_settings_money_plus_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"money_plus_settings",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_settings_password_settings => /financier/settings/password_settings/edit(.:format)
  // function(options)
  edit_financier_settings_password_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"password_settings",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_settings_password_settings_actor_tfas => /financier/settings/password_settings/actor_tfas/edit(.:format)
  // function(options)
  edit_financier_settings_password_settings_actor_tfas_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"password_settings",false],[2,[7,"/",false],[2,[6,"actor_tfas",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_financier_settings_password_settings_api_keys => /financier/settings/password_settings/api_keys/edit(.:format)
  // function(options)
  edit_financier_settings_password_settings_api_keys_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"password_settings",false],[2,[7,"/",false],[2,[6,"api_keys",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_financier_settings_password_settings_password_options => /financier/settings/password_settings/password_options/edit(.:format)
  // function(options)
  edit_financier_settings_password_settings_password_options_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"password_settings",false],[2,[7,"/",false],[2,[6,"password_options",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_financier_settings_privacy_policy => /financier/settings/privacy_policy/edit(.:format)
  // function(options)
  edit_financier_settings_privacy_policy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"privacy_policy",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_settings_project_thank_you_page => /financier/settings/project_thank_you_page/edit(.:format)
  // function(options)
  edit_financier_settings_project_thank_you_page_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"project_thank_you_page",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_settings_secure_id_verification => /financier/settings/secure_id_verification/edit(.:format)
  // function(options)
  edit_financier_settings_secure_id_verification_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"secure_id_verification",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_settings_signature_message => /financier/settings/signature_messages/:id/edit(.:format)
  // function(id, options)
  edit_financier_settings_signature_message_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"signature_messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_financier_settings_thank_you_page => /financier/settings/thank_you_page/edit(.:format)
  // function(options)
  edit_financier_settings_thank_you_page_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"thank_you_page",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_superuser => /financier/superusers/:id/edit(.:format)
  // function(id, options)
  edit_financier_superuser_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"superusers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_user_password => /financier_users/password/edit(.:format)
  // function(options)
  edit_financier_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_financier_user_registration => /financier_users/edit(.:format)
  // function(options)
  edit_financier_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_financier_webhook => /financier/webhooks/:id/edit(.:format)
  // function(id, options)
  edit_financier_webhook_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_financier_whitelabel => /financier/whitelabels/:id/edit(.:format)
  // function(id, options)
  edit_financier_whitelabel_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"whitelabels",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_joint_moral_obligor => /joint_moral_obligor/:id/edit(.:format)
  // function(id, options)
  edit_joint_moral_obligor_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_joint_moral_obligor_address => /joint_moral_obligor/:joint_moral_obligor_id/addresses/:id/edit(.:format)
  // function(joint_moral_obligor_id, id, options)
  edit_joint_moral_obligor_address_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_moral_obligor_authorized_person => /joint_moral_obligor/:joint_moral_obligor_id/authorized_persons/:id/edit(.:format)
  // function(joint_moral_obligor_id, id, options)
  edit_joint_moral_obligor_authorized_person_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"authorized_persons",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_moral_obligor_bank_account => /joint_moral_obligor/:joint_moral_obligor_id/bank_accounts/:id/edit(.:format)
  // function(joint_moral_obligor_id, id, options)
  edit_joint_moral_obligor_bank_account_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_moral_obligor_beneficiary_account => /joint_moral_obligor/:joint_moral_obligor_id/beneficiary_accounts/:id/edit(.:format)
  // function(joint_moral_obligor_id, id, options)
  edit_joint_moral_obligor_beneficiary_account_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"beneficiary_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_moral_obligor_business_data => /joint_moral_obligor/:joint_moral_obligor_id/business_data/edit(.:format)
  // function(joint_moral_obligor_id, options)
  edit_joint_moral_obligor_business_data_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"business_data",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_joint_moral_obligor_credit_report => /joint_moral_obligor/:joint_moral_obligor_id/credit_report/edit(.:format)
  // function(joint_moral_obligor_id, options)
  edit_joint_moral_obligor_credit_report_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_joint_moral_obligor_customer => /joint_moral_obligor/:joint_moral_obligor_id/customers/:id/edit(.:format)
  // function(joint_moral_obligor_id, id, options)
  edit_joint_moral_obligor_customer_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_moral_obligor_debt => /joint_moral_obligor/:joint_moral_obligor_id/debts/:id/edit(.:format)
  // function(joint_moral_obligor_id, id, options)
  edit_joint_moral_obligor_debt_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"debts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_moral_obligor_financial_data => /joint_moral_obligor/:joint_moral_obligor_id/financial_data/edit(.:format)
  // function(joint_moral_obligor_id, options)
  edit_joint_moral_obligor_financial_data_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_joint_moral_obligor_guarantor => /joint_moral_obligor/:joint_moral_obligor_id/guarantors/:id/edit(.:format)
  // function(joint_moral_obligor_id, id, options)
  edit_joint_moral_obligor_guarantor_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_moral_obligor_heritage => /joint_moral_obligor/:joint_moral_obligor_id/heritages/:id/edit(.:format)
  // function(joint_moral_obligor_id, id, options)
  edit_joint_moral_obligor_heritage_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_moral_obligor_judgment => /joint_moral_obligor/:joint_moral_obligor_id/judgments/:id/edit(.:format)
  // function(joint_moral_obligor_id, id, options)
  edit_joint_moral_obligor_judgment_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_moral_obligor_main_competitor => /joint_moral_obligor/:joint_moral_obligor_id/main_competitors/:id/edit(.:format)
  // function(joint_moral_obligor_id, id, options)
  edit_joint_moral_obligor_main_competitor_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"main_competitors",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_moral_obligor_moral_shareholder => /joint_moral_obligor/:joint_moral_obligor_id/moral_shareholders/:id/edit(.:format)
  // function(joint_moral_obligor_id, id, options)
  edit_joint_moral_obligor_moral_shareholder_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"moral_shareholders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_moral_obligor_product_service => /joint_moral_obligor/:joint_moral_obligor_id/product_services/:id/edit(.:format)
  // function(joint_moral_obligor_id, id, options)
  edit_joint_moral_obligor_product_service_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_moral_obligor_provider => /joint_moral_obligor/:joint_moral_obligor_id/providers/:id/edit(.:format)
  // function(joint_moral_obligor_id, id, options)
  edit_joint_moral_obligor_provider_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_moral_obligor_real_owner => /joint_moral_obligor/:joint_moral_obligor_id/real_owners/:id/edit(.:format)
  // function(joint_moral_obligor_id, id, options)
  edit_joint_moral_obligor_real_owner_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"real_owners",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_moral_obligor_shareholder => /joint_moral_obligor/:joint_moral_obligor_id/shareholders/:id/edit(.:format)
  // function(joint_moral_obligor_id, id, options)
  edit_joint_moral_obligor_shareholder_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"shareholders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor => /joint_obligors/:id/edit(.:format)
  // function(id, options)
  edit_joint_obligor_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_joint_obligor_active => /joint_obligors/:joint_obligor_id/actives/:id/edit(.:format)
  // function(joint_obligor_id, id, options)
  edit_joint_obligor_active_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"actives",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_address => /joint_obligors/:joint_obligor_id/addresses/:id/edit(.:format)
  // function(joint_obligor_id, id, options)
  edit_joint_obligor_address_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_bank_account => /joint_obligors/:joint_obligor_id/bank_accounts/:id/edit(.:format)
  // function(joint_obligor_id, id, options)
  edit_joint_obligor_bank_account_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_credit_report => /joint_obligors/:joint_obligor_id/credit_report/edit(.:format)
  // function(joint_obligor_id, options)
  edit_joint_obligor_credit_report_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_joint_obligor_debt => /joint_obligors/:joint_obligor_id/debts/:id/edit(.:format)
  // function(joint_obligor_id, id, options)
  edit_joint_obligor_debt_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"debts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_employment_record => /joint_obligors/:joint_obligor_id/employment_records/:id/edit(.:format)
  // function(joint_obligor_id, id, options)
  edit_joint_obligor_employment_record_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"employment_records",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_family_data => /joint_obligors/:joint_obligor_id/family_data/edit(.:format)
  // function(joint_obligor_id, options)
  edit_joint_obligor_family_data_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"family_data",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_joint_obligor_financial_asset => /joint_obligors/:joint_obligor_id/financial_assets/:id/edit(.:format)
  // function(joint_obligor_id, id, options)
  edit_joint_obligor_financial_asset_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"financial_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_financial_data => /joint_obligors/:joint_obligor_id/financial_data/edit(.:format)
  // function(joint_obligor_id, options)
  edit_joint_obligor_financial_data_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_joint_obligor_guarantor => /joint_obligors/:joint_obligor_id/guarantors/:id/edit(.:format)
  // function(joint_obligor_id, id, options)
  edit_joint_obligor_guarantor_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_heritage => /joint_obligors/:joint_obligor_id/heritages/:id/edit(.:format)
  // function(joint_obligor_id, id, options)
  edit_joint_obligor_heritage_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_judgment => /joint_obligors/:joint_obligor_id/judgments/:id/edit(.:format)
  // function(joint_obligor_id, id, options)
  edit_joint_obligor_judgment_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_passiv => /joint_obligors/:joint_obligor_id/passivs/:id/edit(.:format)
  // function(joint_obligor_id, id, options)
  edit_joint_obligor_passiv_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"passivs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_personal_data => /joint_obligors/:joint_obligor_id/personal_data/edit(.:format)
  // function(joint_obligor_id, options)
  edit_joint_obligor_personal_data_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"personal_data",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_joint_obligor_personal_reference => /joint_obligors/:joint_obligor_id/personal_references/:id/edit(.:format)
  // function(joint_obligor_id, id, options)
  edit_joint_obligor_personal_reference_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"personal_references",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_pfae => /joint_obligor_pfae/:id/edit(.:format)
  // function(id, options)
  edit_joint_obligor_pfae_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_joint_obligor_pfae_active => /joint_obligor_pfae/:joint_obligor_pfae_id/actives/:id/edit(.:format)
  // function(joint_obligor_pfae_id, id, options)
  edit_joint_obligor_pfae_active_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"actives",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_pfae_address => /joint_obligor_pfae/:joint_obligor_pfae_id/addresses/:id/edit(.:format)
  // function(joint_obligor_pfae_id, id, options)
  edit_joint_obligor_pfae_address_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_pfae_bank_account => /joint_obligor_pfae/:joint_obligor_pfae_id/bank_accounts/:id/edit(.:format)
  // function(joint_obligor_pfae_id, id, options)
  edit_joint_obligor_pfae_bank_account_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_pfae_credit_report => /joint_obligor_pfae/:joint_obligor_pfae_id/credit_report/edit(.:format)
  // function(joint_obligor_pfae_id, options)
  edit_joint_obligor_pfae_credit_report_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_joint_obligor_pfae_customer => /joint_obligor_pfae/:joint_obligor_pfae_id/customers/:id/edit(.:format)
  // function(joint_obligor_pfae_id, id, options)
  edit_joint_obligor_pfae_customer_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_pfae_debt => /joint_obligor_pfae/:joint_obligor_pfae_id/debts/:id/edit(.:format)
  // function(joint_obligor_pfae_id, id, options)
  edit_joint_obligor_pfae_debt_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"debts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_pfae_employment_record => /joint_obligor_pfae/:joint_obligor_pfae_id/employment_records/:id/edit(.:format)
  // function(joint_obligor_pfae_id, id, options)
  edit_joint_obligor_pfae_employment_record_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"employment_records",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_pfae_family_data => /joint_obligor_pfae/:joint_obligor_pfae_id/family_data/edit(.:format)
  // function(joint_obligor_pfae_id, options)
  edit_joint_obligor_pfae_family_data_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"family_data",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_joint_obligor_pfae_financial_asset => /joint_obligor_pfae/:joint_obligor_pfae_id/financial_assets/:id/edit(.:format)
  // function(joint_obligor_pfae_id, id, options)
  edit_joint_obligor_pfae_financial_asset_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"financial_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_pfae_financial_data => /joint_obligor_pfae/:joint_obligor_pfae_id/financial_data/edit(.:format)
  // function(joint_obligor_pfae_id, options)
  edit_joint_obligor_pfae_financial_data_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_joint_obligor_pfae_guarantor => /joint_obligor_pfae/:joint_obligor_pfae_id/guarantors/:id/edit(.:format)
  // function(joint_obligor_pfae_id, id, options)
  edit_joint_obligor_pfae_guarantor_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_pfae_heritage => /joint_obligor_pfae/:joint_obligor_pfae_id/heritages/:id/edit(.:format)
  // function(joint_obligor_pfae_id, id, options)
  edit_joint_obligor_pfae_heritage_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_pfae_judgment => /joint_obligor_pfae/:joint_obligor_pfae_id/judgments/:id/edit(.:format)
  // function(joint_obligor_pfae_id, id, options)
  edit_joint_obligor_pfae_judgment_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_pfae_main_competitor => /joint_obligor_pfae/:joint_obligor_pfae_id/main_competitors/:id/edit(.:format)
  // function(joint_obligor_pfae_id, id, options)
  edit_joint_obligor_pfae_main_competitor_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"main_competitors",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_pfae_moral_shareholder => /joint_obligor_pfae/:joint_obligor_pfae_id/moral_shareholders/:id/edit(.:format)
  // function(joint_obligor_pfae_id, id, options)
  edit_joint_obligor_pfae_moral_shareholder_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"moral_shareholders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_pfae_passife => /joint_obligor_pfae/:joint_obligor_pfae_id/passives/:id/edit(.:format)
  // function(joint_obligor_pfae_id, id, options)
  edit_joint_obligor_pfae_passife_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"passives",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_pfae_personal_data => /joint_obligor_pfae/:joint_obligor_pfae_id/personal_data/edit(.:format)
  // function(joint_obligor_pfae_id, options)
  edit_joint_obligor_pfae_personal_data_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"personal_data",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_joint_obligor_pfae_personal_reference => /joint_obligor_pfae/:joint_obligor_pfae_id/personal_references/:id/edit(.:format)
  // function(joint_obligor_pfae_id, id, options)
  edit_joint_obligor_pfae_personal_reference_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"personal_references",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_pfae_product_service => /joint_obligor_pfae/:joint_obligor_pfae_id/product_services/:id/edit(.:format)
  // function(joint_obligor_pfae_id, id, options)
  edit_joint_obligor_pfae_product_service_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_pfae_provider => /joint_obligor_pfae/:joint_obligor_pfae_id/providers/:id/edit(.:format)
  // function(joint_obligor_pfae_id, id, options)
  edit_joint_obligor_pfae_provider_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_pfae_share => /joint_obligor_pfae/:joint_obligor_pfae_id/shares/:id/edit(.:format)
  // function(joint_obligor_pfae_id, id, options)
  edit_joint_obligor_pfae_share_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"shares",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_pfae_shareholder => /joint_obligor_pfae/:joint_obligor_pfae_id/shareholders/:id/edit(.:format)
  // function(joint_obligor_pfae_id, id, options)
  edit_joint_obligor_pfae_shareholder_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"shareholders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_product_service => /joint_obligors/:joint_obligor_id/product_services/:id/edit(.:format)
  // function(joint_obligor_id, id, options)
  edit_joint_obligor_product_service_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_joint_obligor_share => /joint_obligors/:joint_obligor_id/shares/:id/edit(.:format)
  // function(joint_obligor_id, id, options)
  edit_joint_obligor_share_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"shares",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_observer_arbol_score_result => /observer/arbol_score_results/:id/edit(.:format)
  // function(id, options)
  edit_observer_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_observer_password => /observers/password/edit(.:format)
  // function(options)
  edit_observer_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"observers",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_observer_product_application_product_application_message => /observer/product_applications/:product_application_id/product_application_messages/:id/edit(.:format)
  // function(product_application_id, id, options)
  edit_observer_product_application_product_application_message_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"product_application_messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_observer_registration => /observers/edit(.:format)
  // function(options)
  edit_observer_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"observers",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_product => /products/:id/edit(.:format)
  // function(id, options)
  edit_product_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_product_application => /product_applications/:id/edit(.:format)
  // function(id, options)
  edit_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_product_application_product_application_message => /product_applications/:product_application_id/product_application_messages/:id/edit(.:format)
  // function(product_application_id, id, options)
  edit_product_application_product_application_message_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"product_application_messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_project => /projects/:id/edit(.:format)
  // function(id, options)
  edit_project_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_project_otp => /projects/:project_id/otp/edit(.:format)
  // function(project_id, options)
  edit_project_otp_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"otp",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_project_otp_media_record => /projects/:project_id/otp_media_record/edit(.:format)
  // function(project_id, options)
  edit_project_otp_media_record_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"otp_media_record",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_project_video_record => /projects/:project_id/video_record/edit(.:format)
  // function(project_id, options)
  edit_project_video_record_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"video_record",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_report_request => /report_requests/:id/edit(.:format)
  // function(id, options)
  edit_report_request_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"report_requests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user => /users/:id/edit(.:format)
  // function(id, options)
  edit_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_active => /users/:user_id/actives/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_active_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"actives",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_address => /users/:user_id/addresses/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_address_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_arbol_score_result => /users/:user_id/arbol_score_results/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_arbol_score_result_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_bank_account => /users/:user_id/bank_accounts/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_bank_account_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_contact_request => /users/:user_id/contact_requests/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_contact_request_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"contact_requests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_credit_report => /users/:user_id/credit_report/edit(.:format)
  // function(user_id, options)
  edit_user_credit_report_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user_customer => /users/:user_id/customers/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_customer_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_debt => /users/:user_id/debts/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_debt_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"debts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_employment_record => /users/:user_id/employment_records/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_employment_record_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"employment_records",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_family_data => /users/:user_id/family_data/edit(.:format)
  // function(user_id, options)
  edit_user_family_data_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"family_data",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user_financial_asset => /users/:user_id/financial_assets/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_financial_asset_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"financial_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_financial_data => /users/:user_id/financial_data/edit(.:format)
  // function(user_id, options)
  edit_user_financial_data_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user_financing_config => /users/:user_id/financing_config/edit(.:format)
  // function(user_id, options)
  edit_user_financing_config_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"financing_config",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user_geolocation_data => /users/:user_id/geolocation_data/edit(.:format)
  // function(user_id, options)
  edit_user_geolocation_data_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"geolocation_data",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user_guarantor => /users/:user_id/guarantors/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_guarantor_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_heritage => /users/:user_id/heritages/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_heritage_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_judgment => /users/:user_id/judgments/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_judgment_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_main_competitor => /users/:user_id/main_competitors/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_main_competitor_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"main_competitors",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_passiv => /users/:user_id/passivs/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_passiv_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"passivs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_password => /users/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_personal_data => /users/:user_id/personal_data/edit(.:format)
  // function(user_id, options)
  edit_user_personal_data_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"personal_data",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user_personal_reference => /users/:user_id/personal_references/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_personal_reference_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"personal_references",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_preapproval => /users/:user_id/preapproval/edit(.:format)
  // function(user_id, options)
  edit_user_preapproval_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"preapproval",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user_previous_study => /users/:user_id/previous_studies/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_previous_study_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"previous_studies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_product_service => /users/:user_id/product_services/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_product_service_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_provider => /users/:user_id/providers/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_provider_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_registration => /users/edit(.:format)
  // function(options)
  edit_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_user_share => /users/:user_id/shares/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_share_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"shares",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user_stp_researchable_account => /users/:user_id/stp_researchable_accounts/:id/edit(.:format)
  // function(user_id, id, options)
  edit_user_stp_researchable_account_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"stp_researchable_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_warranty => /warranties/:id/edit(.:format)
  // function(id, options)
  edit_warranty_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warranties",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_warranty_warranty_document => /warranties/:warranty_id/warranty_documents/:id/edit(.:format)
  // function(warranty_id, id, options)
  edit_warranty_warranty_document_path: Utils.route([["warranty_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warranties",false],[2,[7,"/",false],[2,[3,"warranty_id",false],[2,[7,"/",false],[2,[6,"warranty_documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// export_financier_product_rules_rejections => /financier/products/:product_id/rules_rejections/export(.:format)
  // function(product_id, options)
  export_financier_product_rules_rejections_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"rules_rejections",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// faq => /faq(.:format)
  // function(options)
  faq_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"faq",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// file_packaging_folio_financier_product_application => /financier/product_applications/:id/file_packaging_folio(.:format)
  // function(id, options)
  file_packaging_folio_financier_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"file_packaging_folio",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_arbol_score_result => /financier/arbol_score_results/:id(.:format)
  // function(id, options)
  financier_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_arbol_score_result_address => /financier/arbol_score_results/:arbol_score_result_id/address(.:format)
  // function(arbol_score_result_id, options)
  financier_arbol_score_result_address_path: Utils.route([["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_arbol_score_result_credit_report => /financier/arbol_score_results/:arbol_score_result_id/credit_report(.:format)
  // function(arbol_score_result_id, options)
  financier_arbol_score_result_credit_report_path: Utils.route([["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_arbol_score_result_family_data => /financier/arbol_score_results/:arbol_score_result_id/family_data(.:format)
  // function(arbol_score_result_id, options)
  financier_arbol_score_result_family_data_path: Utils.route([["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"family_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_arbol_score_result_financial_data => /financier/arbol_score_results/:arbol_score_result_id/financial_data(.:format)
  // function(arbol_score_result_id, options)
  financier_arbol_score_result_financial_data_path: Utils.route([["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_arbol_score_result_geolocation_data => /financier/arbol_score_results/:arbol_score_result_id/geolocation_data(.:format)
  // function(arbol_score_result_id, options)
  financier_arbol_score_result_geolocation_data_path: Utils.route([["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"geolocation_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_arbol_score_result_personal_data => /financier/arbol_score_results/:arbol_score_result_id/personal_data(.:format)
  // function(arbol_score_result_id, options)
  financier_arbol_score_result_personal_data_path: Utils.route([["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"personal_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_arbol_score_result_rejections => /financier/arbol_score_results/:arbol_score_result_id/rejections(.:format)
  // function(arbol_score_result_id, options)
  financier_arbol_score_result_rejections_path: Utils.route([["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"rejections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_arbol_score_result_resets => /financier/arbol_score_results/:arbol_score_result_id/resets(.:format)
  // function(arbol_score_result_id, options)
  financier_arbol_score_result_resets_path: Utils.route([["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"resets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_arbol_score_results => /financier/arbol_score_results(.:format)
  // function(options)
  financier_arbol_score_results_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_authenticated_financier_user => /financier(.:format)
  // function(options)
  financier_authenticated_financier_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// financier_become_user => /financier/become_user/:user_id(.:format)
  // function(user_id, options)
  financier_become_user_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"become_user",false],[2,[7,"/",false],[2,[3,"user_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_boa_vista_reports => /financier/boa_vista_reports(.:format)
  // function(options)
  financier_boa_vista_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"boa_vista_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_bureau_template => /financier/bureau_templates/:id(.:format)
  // function(id, options)
  financier_bureau_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"bureau_templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_bureau_templates => /financier/bureau_templates(.:format)
  // function(options)
  financier_bureau_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"bureau_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_business_document_type => /financier/business/:business_id/document_types/:type(.:format)
  // function(business_id, type, options)
  financier_business_document_type_path: Utils.route([["business_id",true],["type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_business_document_types => /financier/business/:business_id/document_types(.:format)
  // function(business_id, options)
  financier_business_document_types_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_campaign => /financier/campaigns/:id(.:format)
  // function(id, options)
  financier_campaign_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_campaign_csv_reports => /financier/campaigns/:campaign_id/csv_reports(.:format)
  // function(campaign_id, options)
  financier_campaign_csv_reports_path: Utils.route([["campaign_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"campaign_id",false],[2,[7,"/",false],[2,[6,"csv_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_campaign_notify_campaign => /financier/campaigns/:campaign_id/notify_campaigns/:id(.:format)
  // function(campaign_id, id, options)
  financier_campaign_notify_campaign_path: Utils.route([["campaign_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"campaign_id",false],[2,[7,"/",false],[2,[6,"notify_campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_campaign_notify_campaigns => /financier/campaigns/:campaign_id/notify_campaigns(.:format)
  // function(campaign_id, options)
  financier_campaign_notify_campaigns_path: Utils.route([["campaign_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"campaign_id",false],[2,[7,"/",false],[2,[6,"notify_campaigns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_campaign_notify_prospects => /financier/campaigns/:campaign_id/notify_prospects(.:format)
  // function(campaign_id, options)
  financier_campaign_notify_prospects_path: Utils.route([["campaign_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"campaign_id",false],[2,[7,"/",false],[2,[6,"notify_prospects",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_campaign_renew_credit => /financier/campaigns/:campaign_id/renew_credits/:id(.:format)
  // function(campaign_id, id, options)
  financier_campaign_renew_credit_path: Utils.route([["campaign_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"campaign_id",false],[2,[7,"/",false],[2,[6,"renew_credits",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_campaign_renew_credits => /financier/campaigns/:campaign_id/renew_credits(.:format)
  // function(campaign_id, options)
  financier_campaign_renew_credits_path: Utils.route([["campaign_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"campaign_id",false],[2,[7,"/",false],[2,[6,"renew_credits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_campaigns => /financier/campaigns(.:format)
  // function(options)
  financier_campaigns_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"campaigns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_circulo_authentication_report => /financier/circulo_authentication_reports/:id(.:format)
  // function(id, options)
  financier_circulo_authentication_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"circulo_authentication_reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_circulo_authentication_reports => /financier/circulo_authentication_reports(.:format)
  // function(options)
  financier_circulo_authentication_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"circulo_authentication_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_circulo_report_authorizations => /financier/circulo_reports/:circulo_report_id/authorizations(.:format)
  // function(circulo_report_id, options)
  financier_circulo_report_authorizations_path: Utils.route([["circulo_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"circulo_reports",false],[2,[7,"/",false],[2,[3,"circulo_report_id",false],[2,[7,"/",false],[2,[6,"authorizations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_circulo_report_pin_codes => /financier/circulo_reports/:circulo_report_id/pin_codes(.:format)
  // function(circulo_report_id, options)
  financier_circulo_report_pin_codes_path: Utils.route([["circulo_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"circulo_reports",false],[2,[7,"/",false],[2,[3,"circulo_report_id",false],[2,[7,"/",false],[2,[6,"pin_codes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_confirmation => /financier/confirmations/:id(.:format)
  // function(id, options)
  financier_confirmation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"confirmations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_contact_request => /financier/contact_requests/:id(.:format)
  // function(id, options)
  financier_contact_request_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"contact_requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_contact_requests => /financier/contact_requests(.:format)
  // function(options)
  financier_contact_requests_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"contact_requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_custom_field => /financier/custom_fields/:id(.:format)
  // function(id, options)
  financier_custom_field_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_custom_fields => /financier/custom_fields(.:format)
  // function(options)
  financier_custom_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_dashboard => /financier/dashboard(.:format)
  // function(options)
  financier_dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_dashboard_marketplace_dashboard_tabs_analysts => /financier/dashboard/marketplace_dashboard/tabs/analysts(.:format)
  // function(options)
  financier_dashboard_marketplace_dashboard_tabs_analysts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"marketplace_dashboard",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"analysts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_dashboard_marketplace_dashboard_tabs_commercials => /financier/dashboard/marketplace_dashboard/tabs/commercials(.:format)
  // function(options)
  financier_dashboard_marketplace_dashboard_tabs_commercials_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"marketplace_dashboard",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"commercials",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_dashboard_marketplace_dashboard_tabs_inplants => /financier/dashboard/marketplace_dashboard/tabs/inplants(.:format)
  // function(options)
  financier_dashboard_marketplace_dashboard_tabs_inplants_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"marketplace_dashboard",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"inplants",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_dashboard_marketplace_dashboard_tabs_whitelabels => /financier/dashboard/marketplace_dashboard/tabs/whitelabels(.:format)
  // function(options)
  financier_dashboard_marketplace_dashboard_tabs_whitelabels_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"marketplace_dashboard",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"whitelabels",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_document => /financier/documents/:id(.:format)
  // function(id, options)
  financier_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_document_template => /financier/document_templates/:id(.:format)
  // function(id, options)
  financier_document_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"document_templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_document_templates => /financier/document_templates(.:format)
  // function(options)
  financier_document_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"document_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_documents => /financier/documents(.:format)
  // function(options)
  financier_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_drop => /financier/drops/:id(.:format)
  // function(id, options)
  financier_drop_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"drops",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_drops => /financier/drops(.:format)
  // function(options)
  financier_drops_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"drops",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_external_api => /financier/external_apis/:id(.:format)
  // function(id, options)
  financier_external_api_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"external_apis",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_external_apis => /financier/external_apis(.:format)
  // function(options)
  financier_external_apis_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"external_apis",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_financier_user => /financier/financier_users/:id(.:format)
  // function(id, options)
  financier_financier_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_financier_user_blocking => /financier/financier_users/:financier_user_id/blocking(.:format)
  // function(financier_user_id, options)
  financier_financier_user_blocking_path: Utils.route([["financier_user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[3,"financier_user_id",false],[2,[7,"/",false],[2,[6,"blocking",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_financier_user_cancellation => /financier/financier_users/:financier_user_id/cancellation(.:format)
  // function(financier_user_id, options)
  financier_financier_user_cancellation_path: Utils.route([["financier_user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[3,"financier_user_id",false],[2,[7,"/",false],[2,[6,"cancellation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_financier_users => /financier/financier_users(.:format)
  // function(options)
  financier_financier_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"financier_users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_generated_documents => /financier/generated_documents(.:format)
  // function(options)
  financier_generated_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"generated_documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_id_verification => /financier/id_verifications/:id(.:format)
  // function(id, options)
  financier_id_verification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"id_verifications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_id_verification_status => /financier/id_verification_status(.:format)
  // function(options)
  financier_id_verification_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"id_verification_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_id_verifications => /financier/id_verifications(.:format)
  // function(options)
  financier_id_verifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"id_verifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_joint_moral_obligor_document_type => /financier/joint_moral_obligor/:joint_moral_obligor_id/document_types/:type(.:format)
  // function(joint_moral_obligor_id, type, options)
  financier_joint_moral_obligor_document_type_path: Utils.route([["joint_moral_obligor_id",true],["type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_joint_moral_obligor_document_types => /financier/joint_moral_obligor/:joint_moral_obligor_id/document_types(.:format)
  // function(joint_moral_obligor_id, options)
  financier_joint_moral_obligor_document_types_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_joint_obligor_pfae_document_type => /financier/joint_obligor_pfae/:joint_obligor_pfae_id/document_types/:type(.:format)
  // function(joint_obligor_pfae_id, type, options)
  financier_joint_obligor_pfae_document_type_path: Utils.route([["joint_obligor_pfae_id",true],["type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_joint_obligor_pfae_document_types => /financier/joint_obligor_pfae/:joint_obligor_pfae_id/document_types(.:format)
  // function(joint_obligor_pfae_id, options)
  financier_joint_obligor_pfae_document_types_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_kikoya_widget_notify_applicants => /financier/kikoya_widget_notify_applicants(.:format)
  // function(options)
  financier_kikoya_widget_notify_applicants_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"kikoya_widget_notify_applicants",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_kyc360_actions_product_application_user_geolocation => /financier/kyc360_actions/product_application_user/:product_application_user_id/geolocation(.:format)
  // function(product_application_user_id, options)
  financier_kyc360_actions_product_application_user_geolocation_path: Utils.route([["product_application_user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"kyc360_actions",false],[2,[7,"/",false],[2,[6,"product_application_user",false],[2,[7,"/",false],[2,[3,"product_application_user_id",false],[2,[7,"/",false],[2,[6,"geolocation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_landing_search_group => /financier/landing_search_groups/:id(.:format)
  // function(id, options)
  financier_landing_search_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"landing_search_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_landing_search_groups => /financier/landing_search_groups(.:format)
  // function(options)
  financier_landing_search_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"landing_search_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_lead => /financier/leads/:id(.:format)
  // function(id, options)
  financier_lead_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"leads",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_leads => /financier/leads(.:format)
  // function(options)
  financier_leads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"leads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_leads_importer => /financier/leads/importers/:id(.:format)
  // function(id, options)
  financier_leads_importer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"leads",false],[2,[7,"/",false],[2,[6,"importers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_leads_importers => /financier/leads/importers(.:format)
  // function(options)
  financier_leads_importers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"leads",false],[2,[7,"/",false],[2,[6,"importers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_money_plus_request => /financier/money_plus_requests/:id(.:format)
  // function(id, options)
  financier_money_plus_request_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"money_plus_requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_notification => /financier/notifications/:id(.:format)
  // function(id, options)
  financier_notification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_notifications => /financier/notifications(.:format)
  // function(options)
  financier_notifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_preapproval_result => /financier/preapproval_results/:id(.:format)
  // function(id, options)
  financier_preapproval_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"preapproval_results",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_preapproval_results => /financier/preapproval_results(.:format)
  // function(options)
  financier_preapproval_results_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"preapproval_results",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_product => /financier/products/:id(.:format)
  // function(id, options)
  financier_product_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_product_activations => /financier/products/:product_id/activations(.:format)
  // function(product_id, options)
  financier_product_activations_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"activations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application => /financier/product_applications/:id(.:format)
  // function(id, options)
  financier_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_product_application_actors_actor_obligor => /financier/product_applications/:product_application_id/actors/actor_obligors/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_actors_actor_obligor_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"actors",false],[2,[7,"/",false],[2,[6,"actor_obligors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_actors_actor_obligors => /financier/product_applications/:product_application_id/actors/actor_obligors(.:format)
  // function(product_application_id, options)
  financier_product_application_actors_actor_obligors_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"actors",false],[2,[7,"/",false],[2,[6,"actor_obligors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_actors_send_authentication_token_notifications => /financier/product_applications/:product_application_id/actors/send_authentication_token_notifications(.:format)
  // function(product_application_id, options)
  financier_product_application_actors_send_authentication_token_notifications_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"actors",false],[2,[7,"/",false],[2,[6,"send_authentication_token_notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_blacklist_verification => /financier/product_applications/:product_application_id/blacklist_verifications/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_blacklist_verification_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"blacklist_verifications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_blacklist_verifications => /financier/product_applications/:product_application_id/blacklist_verifications(.:format)
  // function(product_application_id, options)
  financier_product_application_blacklist_verifications_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"blacklist_verifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_boa_vista_reports => /financier/product_applications/:product_application_id/boa_vista_reports(.:format)
  // function(product_application_id, options)
  financier_product_application_boa_vista_reports_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"boa_vista_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_boa_vista_widgets => /financier/product_applications/:product_application_id/boa_vista_widgets(.:format)
  // function(product_application_id, options)
  financier_product_application_boa_vista_widgets_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"boa_vista_widgets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_cep_request => /financier/product_applications/:product_application_id/cep_requests/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_cep_request_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"cep_requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_change_download_status => /financier/product_applications/:product_application_id/change_download_status(.:format)
  // function(product_application_id, options)
  financier_product_application_change_download_status_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"change_download_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_circulo_report_authorizations => /financier/product_applications/:product_application_id/circulo_reports/:circulo_report_id/authorizations(.:format)
  // function(product_application_id, circulo_report_id, options)
  financier_product_application_circulo_report_authorizations_path: Utils.route([["product_application_id",true],["circulo_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"circulo_reports",false],[2,[7,"/",false],[2,[3,"circulo_report_id",false],[2,[7,"/",false],[2,[6,"authorizations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_circulo_report_pin_codes => /financier/product_applications/:product_application_id/circulo_reports/:circulo_report_id/pin_codes(.:format)
  // function(product_application_id, circulo_report_id, options)
  financier_product_application_circulo_report_pin_codes_path: Utils.route([["product_application_id",true],["circulo_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"circulo_reports",false],[2,[7,"/",false],[2,[3,"circulo_report_id",false],[2,[7,"/",false],[2,[6,"pin_codes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_circulo_report_requests => /financier/product_applications/:product_application_id/circulo_reports/:circulo_report_id/requests(.:format)
  // function(product_application_id, circulo_report_id, options)
  financier_product_application_circulo_report_requests_path: Utils.route([["product_application_id",true],["circulo_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"circulo_reports",false],[2,[7,"/",false],[2,[3,"circulo_report_id",false],[2,[7,"/",false],[2,[6,"requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_clone_product_applications => /financier/product_applications/:product_application_id/clone_product_applications(.:format)
  // function(product_application_id, options)
  financier_product_application_clone_product_applications_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"clone_product_applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_credit_option_change_status => /financier/product_applications/:product_application_id/credit_options/:credit_option_id/change_status/:status(.:format)
  // function(product_application_id, credit_option_id, status, options)
  financier_product_application_credit_option_change_status_path: Utils.route([["product_application_id",true],["credit_option_id",true],["status",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"credit_options",false],[2,[7,"/",false],[2,[3,"credit_option_id",false],[2,[7,"/",false],[2,[6,"change_status",false],[2,[7,"/",false],[2,[3,"status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// financier_product_application_credit_report => /financier/product_applications/:product_application_id/credit_reports/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_credit_report_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"credit_reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_delete_external_mercantile_document => /financier/product_applications/:product_application_id/delete_external_mercantile_document(.:format)
  // function(product_application_id, options)
  financier_product_application_delete_external_mercantile_document_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"delete_external_mercantile_document",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_document_templates_zipped_templates => /financier/product_applications/:product_application_id/document_templates/zipped_templates(.:format)
  // function(product_application_id, options)
  financier_product_application_document_templates_zipped_templates_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"document_templates",false],[2,[7,"/",false],[2,[6,"zipped_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_export_user_information_to_pdf => /financier/product_applications/:product_application_id/export_user_information_to_pdf(.:format)
  // function(product_application_id, options)
  financier_product_application_export_user_information_to_pdf_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"export_user_information_to_pdf",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_external_generated_documents => /financier/product_applications/:product_application_id/external_generated_documents(.:format)
  // function(product_application_id, options)
  financier_product_application_external_generated_documents_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"external_generated_documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_generated_document_change_document_status => /financier/product_applications/:product_application_id/generated_documents/:generated_document_id/change_document_status(.:format)
  // function(product_application_id, generated_document_id, options)
  financier_product_application_generated_document_change_document_status_path: Utils.route([["product_application_id",true],["generated_document_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"generated_documents",false],[2,[7,"/",false],[2,[3,"generated_document_id",false],[2,[7,"/",false],[2,[6,"change_document_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_generated_document_deliver_document_to_signers => /financier/product_applications/:product_application_id/generated_documents/:generated_document_id/deliver_document_to_signers(.:format)
  // function(product_application_id, generated_document_id, options)
  financier_product_application_generated_document_deliver_document_to_signers_path: Utils.route([["product_application_id",true],["generated_document_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"generated_documents",false],[2,[7,"/",false],[2,[3,"generated_document_id",false],[2,[7,"/",false],[2,[6,"deliver_document_to_signers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_generated_document_digital_signature => /financier/product_applications/:product_application_id/generated_documents/:generated_document_id/digital_signature(.:format)
  // function(product_application_id, generated_document_id, options)
  financier_product_application_generated_document_digital_signature_path: Utils.route([["product_application_id",true],["generated_document_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"generated_documents",false],[2,[7,"/",false],[2,[3,"generated_document_id",false],[2,[7,"/",false],[2,[6,"digital_signature",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_generated_document_digital_signature_callback => /financier/product_applications/:product_application_id/generated_documents/:generated_document_id/digital_signature/callback(.:format)
  // function(product_application_id, generated_document_id, options)
  financier_product_application_generated_document_digital_signature_callback_path: Utils.route([["product_application_id",true],["generated_document_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"generated_documents",false],[2,[7,"/",false],[2,[3,"generated_document_id",false],[2,[7,"/",false],[2,[6,"digital_signature",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// financier_product_application_generated_document_mi_fiel_signer => /financier/product_applications/:product_application_id/generated_documents/:generated_document_id/mi_fiel/signers/:id(.:format)
  // function(product_application_id, generated_document_id, id, options)
  financier_product_application_generated_document_mi_fiel_signer_path: Utils.route([["product_application_id",true],["generated_document_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"generated_documents",false],[2,[7,"/",false],[2,[3,"generated_document_id",false],[2,[7,"/",false],[2,[6,"mi_fiel",false],[2,[7,"/",false],[2,[6,"signers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// financier_product_application_generated_document_mi_fiel_signers => /financier/product_applications/:product_application_id/generated_documents/:generated_document_id/mi_fiel/signers(.:format)
  // function(product_application_id, generated_document_id, options)
  financier_product_application_generated_document_mi_fiel_signers_path: Utils.route([["product_application_id",true],["generated_document_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"generated_documents",false],[2,[7,"/",false],[2,[3,"generated_document_id",false],[2,[7,"/",false],[2,[6,"mi_fiel",false],[2,[7,"/",false],[2,[6,"signers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// financier_product_application_generated_document_signer => /financier/product_applications/:product_application_id/generated_documents/:generated_document_id/signers/:id(.:format)
  // function(product_application_id, generated_document_id, id, options)
  financier_product_application_generated_document_signer_path: Utils.route([["product_application_id",true],["generated_document_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"generated_documents",false],[2,[7,"/",false],[2,[3,"generated_document_id",false],[2,[7,"/",false],[2,[6,"signers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// financier_product_application_generated_document_signers => /financier/product_applications/:product_application_id/generated_documents/:generated_document_id/signers(.:format)
  // function(product_application_id, generated_document_id, options)
  financier_product_application_generated_document_signers_path: Utils.route([["product_application_id",true],["generated_document_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"generated_documents",false],[2,[7,"/",false],[2,[3,"generated_document_id",false],[2,[7,"/",false],[2,[6,"signers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_generated_documents => /financier/product_applications/:product_application_id/generated_documents(.:format)
  // function(product_application_id, options)
  financier_product_application_generated_documents_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"generated_documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_geolocation_data => /financier/product_applications/:product_application_id/geolocation_data/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_geolocation_data_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"geolocation_data",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_get_form_external_mercantile_document => /financier/product_applications/:product_application_id/get_form_external_mercantile_document(.:format)
  // function(product_application_id, options)
  financier_product_application_get_form_external_mercantile_document_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"get_form_external_mercantile_document",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_history_blacklist_reports => /financier/product_applications/:product_application_id/history_blacklist_reports(.:format)
  // function(product_application_id, options)
  financier_product_application_history_blacklist_reports_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"history_blacklist_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_history_bureau_credit_reports => /financier/product_applications/:product_application_id/history_bureau_credit_reports(.:format)
  // function(product_application_id, options)
  financier_product_application_history_bureau_credit_reports_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"history_bureau_credit_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_id_verification_event => /financier/product_applications/:product_application_id/id_verification_events/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_id_verification_event_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"id_verification_events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_identity_validation => /financier/product_applications/:product_application_id/identity_validations/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_identity_validation_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"identity_validations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_kyc360_index => /financier/product_applications/:product_application_id/kyc360(.:format)
  // function(product_application_id, options)
  financier_product_application_kyc360_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"kyc360",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_market_place_inplant_user_assignations => /financier/product_applications/:product_application_id/market_place/inplant_user_assignations(.:format)
  // function(product_application_id, options)
  financier_product_application_market_place_inplant_user_assignations_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"market_place",false],[2,[7,"/",false],[2,[6,"inplant_user_assignations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_money_plus_request => /financier/product_applications/:product_application_id/money_plus_requests/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_money_plus_request_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"money_plus_requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_moral_person_credit_report => /financier/product_applications/:product_application_id/moral_person_credit_reports/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_moral_person_credit_report_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"moral_person_credit_reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_ocular_visits_ocular_visit_geolocalizations => /financier/product_applications/:product_application_id/ocular_visits/ocular_visit_geolocalizations(.:format)
  // function(product_application_id, options)
  financier_product_application_ocular_visits_ocular_visit_geolocalizations_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"ocular_visits",false],[2,[7,"/",false],[2,[6,"ocular_visit_geolocalizations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_ocular_visits_ocular_visit_media_records => /financier/product_applications/:product_application_id/ocular_visits/ocular_visit_media_records(.:format)
  // function(product_application_id, options)
  financier_product_application_ocular_visits_ocular_visit_media_records_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"ocular_visits",false],[2,[7,"/",false],[2,[6,"ocular_visit_media_records",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_ocular_visits_product_application_photos => /financier/product_applications/:product_application_id/ocular_visits/product_application_photos(.:format)
  // function(product_application_id, options)
  financier_product_application_ocular_visits_product_application_photos_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"ocular_visits",false],[2,[7,"/",false],[2,[6,"product_application_photos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_otp_request => /financier/product_applications/:product_application_id/otp_requests/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_otp_request_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"otp_requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_package_certificate => /financier/product_applications/:product_application_id/package_certificates/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_package_certificate_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"package_certificates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_process_fmx_document => /financier/product_applications/:product_application_id/process_fmx_document(.:format)
  // function(product_application_id, options)
  financier_product_application_process_fmx_document_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"process_fmx_document",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_product_application_address_document => /financier/product_applications/:product_application_id/product_application_address_documents/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_product_application_address_document_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"product_application_address_documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_product_application_document => /financier/product_applications/:product_application_id/product_application_documents/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_product_application_document_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"product_application_documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_product_application_message => /financier/product_applications/:product_application_id/product_application_messages/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_product_application_message_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"product_application_messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_product_application_messages => /financier/product_applications/:product_application_id/product_application_messages(.:format)
  // function(product_application_id, options)
  financier_product_application_product_application_messages_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"product_application_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_reports_csv => /financier/product_applications/:product_application_id/reports/csv(.:format)
  // function(product_application_id, options)
  financier_product_application_reports_csv_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_reports_pdf => /financier/product_applications/:product_application_id/reports/pdf(.:format)
  // function(product_application_id, options)
  financier_product_application_reports_pdf_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"pdf",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_reports_xml => /financier/product_applications/:product_application_id/reports/xml(.:format)
  // function(product_application_id, options)
  financier_product_application_reports_xml_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"xml",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_reports_zipfile => /financier/product_applications/:product_application_id/reports/zipfile(.:format)
  // function(product_application_id, options)
  financier_product_application_reports_zipfile_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"zipfile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_sat_report => /financier/product_applications/:product_application_id/sat_reports/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_sat_report_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"sat_reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_sat_report_zip_files => /financier/product_applications/:product_application_id/sat_reports/:sat_report_id/zip_files(.:format)
  // function(product_application_id, sat_report_id, options)
  financier_product_application_sat_report_zip_files_path: Utils.route([["product_application_id",true],["sat_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"sat_reports",false],[2,[7,"/",false],[2,[3,"sat_report_id",false],[2,[7,"/",false],[2,[6,"zip_files",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_sat_reports => /financier/product_applications/:product_application_id/sat_reports(.:format)
  // function(product_application_id, options)
  financier_product_application_sat_reports_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"sat_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_sub_product_application => /financier/product_applications/:product_application_id/sub_product_applications/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_sub_product_application_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"sub_product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_sub_product_applications => /financier/product_applications/:product_application_id/sub_product_applications(.:format)
  // function(product_application_id, options)
  financier_product_application_sub_product_applications_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"sub_product_applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_tabs_actor_index => /financier/product_applications/:product_application_id/tabs/actor(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_actor_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"actor",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_business_index => /financier/product_applications/:product_application_id/tabs/business(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_business_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"business",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_credit_information_index => /financier/product_applications/:product_application_id/tabs/credit_information(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_credit_information_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"credit_information",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_credits => /financier/product_applications/:product_application_id/tabs/credits(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_credits_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"credits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_file_packaging_index => /financier/product_applications/:product_application_id/tabs/file_packaging(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_file_packaging_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"file_packaging",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_financier_user_assign_index => /financier/product_applications/:product_application_id/tabs/financier_user_assign(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_financier_user_assign_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"financier_user_assign",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_fraud_alert_index => /financier/product_applications/:product_application_id/tabs/fraud_alert(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_fraud_alert_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"fraud_alert",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_generated_document_index => /financier/product_applications/:product_application_id/tabs/generated_document(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_generated_document_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"generated_document",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_history_folio_index => /financier/product_applications/:product_application_id/tabs/history_folio(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_history_folio_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"history_folio",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_internal_control_index => /financier/product_applications/:product_application_id/tabs/internal_control(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_internal_control_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"internal_control",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_joint_moral_obligor => /financier/product_applications/:product_application_id/tabs/joint_moral_obligors/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_tabs_joint_moral_obligor_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"joint_moral_obligors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_tabs_joint_obligor => /financier/product_applications/:product_application_id/tabs/joint_obligors/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_tabs_joint_obligor_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_tabs_joint_obligor_pfae => /financier/product_applications/:product_application_id/tabs/joint_obligor_pfaes/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_tabs_joint_obligor_pfae_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"joint_obligor_pfaes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_tabs_kyc360_summary_index => /financier/product_applications/:product_application_id/tabs/kyc360_summary(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_kyc360_summary_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"kyc360_summary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_marketplace_index => /financier/product_applications/:product_application_id/tabs/marketplace(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_marketplace_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"marketplace",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_money_plus_request => /financier/product_applications/:product_application_id/tabs/money_plus_requests/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_tabs_money_plus_request_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"money_plus_requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_tabs_money_plus_requests => /financier/product_applications/:product_application_id/tabs/money_plus_requests(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_money_plus_requests_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"money_plus_requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_ocular_visit_index => /financier/product_applications/:product_application_id/tabs/ocular_visit(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_ocular_visit_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"ocular_visit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_pld_scoring_index => /financier/product_applications/:product_application_id/tabs/pld_scoring(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_pld_scoring_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"pld_scoring",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_priorization_index => /financier/product_applications/:product_application_id/tabs/priorization(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_priorization_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"priorization",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_product_application_message_index => /financier/product_applications/:product_application_id/tabs/product_application_message(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_product_application_message_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"product_application_message",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_product_application_scoring_index => /financier/product_applications/:product_application_id/tabs/product_application_scoring(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_product_application_scoring_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"product_application_scoring",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_sat_report_index => /financier/product_applications/:product_application_id/tabs/sat_report(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_sat_report_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"sat_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_segmentation_folio_index => /financier/product_applications/:product_application_id/tabs/segmentation_folio(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_segmentation_folio_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"segmentation_folio",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_status_change_log_index => /financier/product_applications/:product_application_id/tabs/status_change_log(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_status_change_log_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"status_change_log",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_verification_index => /financier/product_applications/:product_application_id/tabs/verification(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_verification_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"verification",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_video_authorization_index => /financier/product_applications/:product_application_id/tabs/video_authorization(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_video_authorization_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"video_authorization",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_tabs_video_call_index => /financier/product_applications/:product_application_id/tabs/video_call(.:format)
  // function(product_application_id, options)
  financier_product_application_tabs_video_call_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"video_call",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_update_folio_data => /financier/product_applications/:product_application_id/update_folio_data(.:format)
  // function(product_application_id, options)
  financier_product_application_update_folio_data_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"update_folio_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_upload_external_mercantile_document => /financier/product_applications/:product_application_id/upload_external_mercantile_document(.:format)
  // function(product_application_id, options)
  financier_product_application_upload_external_mercantile_document_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"upload_external_mercantile_document",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_validate_stp_account => /financier/product_applications/:product_application_id/validate_stp_accounts/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_validate_stp_account_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"validate_stp_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_validate_stp_accounts => /financier/product_applications/:product_application_id/validate_stp_accounts(.:format)
  // function(product_application_id, options)
  financier_product_application_validate_stp_accounts_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"validate_stp_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_verification_request => /financier/product_applications/:product_application_id/verification_requests/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_verification_request_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"verification_requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_verification_requests => /financier/product_applications/:product_application_id/verification_requests(.:format)
  // function(product_application_id, options)
  financier_product_application_verification_requests_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"verification_requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_video_authorization => /financier/product_applications/:product_application_id/video_authorizations/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_video_authorization_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"video_authorizations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_video_records => /financier/product_applications/:product_application_id/video_records(.:format)
  // function(product_application_id, options)
  financier_product_application_video_records_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"video_records",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_application_videocall => /financier/product_applications/:product_application_id/videocalls/:id(.:format)
  // function(product_application_id, id, options)
  financier_product_application_videocall_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"videocalls",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_application_videocall_change_validation_status => /financier/product_applications/:product_application_id/videocalls/:videocall_id/change_validation_status(.:format)
  // function(product_application_id, videocall_id, options)
  financier_product_application_videocall_change_validation_status_path: Utils.route([["product_application_id",true],["videocall_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"videocalls",false],[2,[7,"/",false],[2,[3,"videocall_id",false],[2,[7,"/",false],[2,[6,"change_validation_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_videocall_reminders => /financier/product_applications/:product_application_id/videocalls/:videocall_id/reminders(.:format)
  // function(product_application_id, videocall_id, options)
  financier_product_application_videocall_reminders_path: Utils.route([["product_application_id",true],["videocall_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"videocalls",false],[2,[7,"/",false],[2,[3,"videocall_id",false],[2,[7,"/",false],[2,[6,"reminders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_videocall_results => /financier/product_applications/:product_application_id/videocalls/:videocall_id/results(.:format)
  // function(product_application_id, videocall_id, options)
  financier_product_application_videocall_results_path: Utils.route([["product_application_id",true],["videocall_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"videocalls",false],[2,[7,"/",false],[2,[3,"videocall_id",false],[2,[7,"/",false],[2,[6,"results",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_videocall_videocall_answers => /financier/product_applications/:product_application_id/videocalls/:videocall_id/videocall_answers(.:format)
  // function(product_application_id, videocall_id, options)
  financier_product_application_videocall_videocall_answers_path: Utils.route([["product_application_id",true],["videocall_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"videocalls",false],[2,[7,"/",false],[2,[3,"videocall_id",false],[2,[7,"/",false],[2,[6,"videocall_answers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_videocall_videocall_archives => /financier/product_applications/:product_application_id/videocalls/:videocall_id/videocall_archives(.:format)
  // function(product_application_id, videocall_id, options)
  financier_product_application_videocall_videocall_archives_path: Utils.route([["product_application_id",true],["videocall_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"videocalls",false],[2,[7,"/",false],[2,[3,"videocall_id",false],[2,[7,"/",false],[2,[6,"videocall_archives",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_videocall_videocall_comments => /financier/product_applications/:product_application_id/videocalls/:videocall_id/videocall_comments(.:format)
  // function(product_application_id, videocall_id, options)
  financier_product_application_videocall_videocall_comments_path: Utils.route([["product_application_id",true],["videocall_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"videocalls",false],[2,[7,"/",false],[2,[3,"videocall_id",false],[2,[7,"/",false],[2,[6,"videocall_comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_videocall_videocall_document_validators => /financier/product_applications/:product_application_id/videocalls/:videocall_id/videocall_document_validators(.:format)
  // function(product_application_id, videocall_id, options)
  financier_product_application_videocall_videocall_document_validators_path: Utils.route([["product_application_id",true],["videocall_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"videocalls",false],[2,[7,"/",false],[2,[3,"videocall_id",false],[2,[7,"/",false],[2,[6,"videocall_document_validators",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_videocall_videocall_rates => /financier/product_applications/:product_application_id/videocalls/:videocall_id/videocall_rates(.:format)
  // function(product_application_id, videocall_id, options)
  financier_product_application_videocall_videocall_rates_path: Utils.route([["product_application_id",true],["videocall_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"videocalls",false],[2,[7,"/",false],[2,[3,"videocall_id",false],[2,[7,"/",false],[2,[6,"videocall_rates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_videocall_videocall_validations => /financier/product_applications/:product_application_id/videocalls/:videocall_id/videocall_validations(.:format)
  // function(product_application_id, videocall_id, options)
  financier_product_application_videocall_videocall_validations_path: Utils.route([["product_application_id",true],["videocall_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"videocalls",false],[2,[7,"/",false],[2,[3,"videocall_id",false],[2,[7,"/",false],[2,[6,"videocall_validations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_application_videocalls => /financier/product_applications/:product_application_id/videocalls(.:format)
  // function(product_application_id, options)
  financier_product_application_videocalls_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"videocalls",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_applications => /financier/product_applications(.:format)
  // function(options)
  financier_product_applications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_product_clone_element => /financier/products/:product_id/clone_element(.:format)
  // function(product_id, options)
  financier_product_clone_element_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"clone_element",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_custom_form => /financier/products/:product_id/custom_form(.:format)
  // function(product_id, options)
  financier_product_custom_form_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_custom_form_field => /financier/products/:product_id/custom_form/field(.:format)
  // function(product_id, options)
  financier_product_custom_form_field_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_form",false],[2,[7,"/",false],[2,[6,"field",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_custom_form_part => /financier/products/:product_id/custom_form/part(.:format)
  // function(product_id, options)
  financier_product_custom_form_part_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_form",false],[2,[7,"/",false],[2,[6,"part",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_custom_form_widget => /financier/products/:product_id/custom_form/widget(.:format)
  // function(product_id, options)
  financier_product_custom_form_widget_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_form",false],[2,[7,"/",false],[2,[6,"widget",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_custom_preapproval_form => /financier/products/:product_id/custom_preapproval_form(.:format)
  // function(product_id, options)
  financier_product_custom_preapproval_form_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_preapproval_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_custom_profile_score => /financier/products/:product_id/custom_profile_score(.:format)
  // function(product_id, options)
  financier_product_custom_profile_score_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_profile_score",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_custom_score => /financier/products/:product_id/custom_score(.:format)
  // function(product_id, options)
  financier_product_custom_score_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_score",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_custom_smart_score => /financier/products/:product_id/custom_smart_scores/:id(.:format)
  // function(product_id, id, options)
  financier_product_custom_smart_score_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_smart_scores",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_custom_smart_score_cluster => /financier/products/:product_id/custom_smart_scores/:custom_smart_score_id/clusters/:id(.:format)
  // function(product_id, custom_smart_score_id, id, options)
  financier_product_custom_smart_score_cluster_path: Utils.route([["product_id",true],["custom_smart_score_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_smart_scores",false],[2,[7,"/",false],[2,[3,"custom_smart_score_id",false],[2,[7,"/",false],[2,[6,"clusters",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// financier_product_custom_smart_score_cluster_components => /financier/products/:product_id/custom_smart_scores/:custom_smart_score_id/clusters/:cluster_id/components(.:format)
  // function(product_id, custom_smart_score_id, cluster_id, options)
  financier_product_custom_smart_score_cluster_components_path: Utils.route([["product_id",true],["custom_smart_score_id",true],["cluster_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_smart_scores",false],[2,[7,"/",false],[2,[3,"custom_smart_score_id",false],[2,[7,"/",false],[2,[6,"clusters",false],[2,[7,"/",false],[2,[3,"cluster_id",false],[2,[7,"/",false],[2,[6,"components",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// financier_product_custom_smart_score_cluster_prioritizations => /financier/products/:product_id/custom_smart_scores/:custom_smart_score_id/cluster_prioritizations(.:format)
  // function(product_id, custom_smart_score_id, options)
  financier_product_custom_smart_score_cluster_prioritizations_path: Utils.route([["product_id",true],["custom_smart_score_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_smart_scores",false],[2,[7,"/",false],[2,[3,"custom_smart_score_id",false],[2,[7,"/",false],[2,[6,"cluster_prioritizations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_custom_smart_score_clusters => /financier/products/:product_id/custom_smart_scores/:custom_smart_score_id/clusters(.:format)
  // function(product_id, custom_smart_score_id, options)
  financier_product_custom_smart_score_clusters_path: Utils.route([["product_id",true],["custom_smart_score_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_smart_scores",false],[2,[7,"/",false],[2,[3,"custom_smart_score_id",false],[2,[7,"/",false],[2,[6,"clusters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_custom_smart_score_custom_score_profile => /financier/products/:product_id/custom_smart_scores/:custom_smart_score_id/custom_score_profiles/:id(.:format)
  // function(product_id, custom_smart_score_id, id, options)
  financier_product_custom_smart_score_custom_score_profile_path: Utils.route([["product_id",true],["custom_smart_score_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_smart_scores",false],[2,[7,"/",false],[2,[3,"custom_smart_score_id",false],[2,[7,"/",false],[2,[6,"custom_score_profiles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// financier_product_custom_smart_score_custom_score_profile_components => /financier/products/:product_id/custom_smart_scores/:custom_smart_score_id/custom_score_profiles/:custom_score_profile_id/components(.:format)
  // function(product_id, custom_smart_score_id, custom_score_profile_id, options)
  financier_product_custom_smart_score_custom_score_profile_components_path: Utils.route([["product_id",true],["custom_smart_score_id",true],["custom_score_profile_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_smart_scores",false],[2,[7,"/",false],[2,[3,"custom_smart_score_id",false],[2,[7,"/",false],[2,[6,"custom_score_profiles",false],[2,[7,"/",false],[2,[3,"custom_score_profile_id",false],[2,[7,"/",false],[2,[6,"components",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// financier_product_custom_smart_score_custom_score_profiles => /financier/products/:product_id/custom_smart_scores/:custom_smart_score_id/custom_score_profiles(.:format)
  // function(product_id, custom_smart_score_id, options)
  financier_product_custom_smart_score_custom_score_profiles_path: Utils.route([["product_id",true],["custom_smart_score_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_smart_scores",false],[2,[7,"/",false],[2,[3,"custom_smart_score_id",false],[2,[7,"/",false],[2,[6,"custom_score_profiles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_custom_smart_scores => /financier/products/:product_id/custom_smart_scores(.:format)
  // function(product_id, options)
  financier_product_custom_smart_scores_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_smart_scores",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_document_catalog => /financier/products/:product_id/document_catalogs/:id(.:format)
  // function(product_id, id, options)
  financier_product_document_catalog_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"document_catalogs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_document_catalog_document_type => /financier/products/:product_id/document_catalogs/:document_catalog_id/document_types/:id(.:format)
  // function(product_id, document_catalog_id, id, options)
  financier_product_document_catalog_document_type_path: Utils.route([["product_id",true],["document_catalog_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"document_catalogs",false],[2,[7,"/",false],[2,[3,"document_catalog_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// financier_product_document_catalog_document_types => /financier/products/:product_id/document_catalogs/:document_catalog_id/document_types(.:format)
  // function(product_id, document_catalog_id, options)
  financier_product_document_catalog_document_types_path: Utils.route([["product_id",true],["document_catalog_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"document_catalogs",false],[2,[7,"/",false],[2,[3,"document_catalog_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// financier_product_document_catalogs => /financier/products/:product_id/document_catalogs(.:format)
  // function(product_id, options)
  financier_product_document_catalogs_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"document_catalogs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_export_data => /financier/products/:product_id/export_data(.:format)
  // function(product_id, options)
  financier_product_export_data_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"export_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_import_data => /financier/products/:product_id/import_data(.:format)
  // function(product_id, options)
  financier_product_import_data_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"import_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_landing_page => /financier/product_landing_pages/:id(.:format)
  // function(id, options)
  financier_product_landing_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_landing_pages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_product_landing_pages => /financier/product_landing_pages(.:format)
  // function(options)
  financier_product_landing_pages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_landing_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_product_media_record_motive => /financier/products/:product_id/media_record_motives/:id(.:format)
  // function(product_id, id, options)
  financier_product_media_record_motive_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"media_record_motives",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_media_record_motives => /financier/products/:product_id/media_record_motives(.:format)
  // function(product_id, options)
  financier_product_media_record_motives_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"media_record_motives",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_product_application_priorities => /financier/products/:product_id/product_application_priorities(.:format)
  // function(product_id, options)
  financier_product_product_application_priorities_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"product_application_priorities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_product_application_priority => /financier/products/:product_id/product_application_priorities/:id(.:format)
  // function(product_id, id, options)
  financier_product_product_application_priority_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"product_application_priorities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_profile => /financier/products/:product_id/profiles/:id(.:format)
  // function(product_id, id, options)
  financier_product_profile_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"profiles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_profiles => /financier/products/:product_id/profiles(.:format)
  // function(product_id, options)
  financier_product_profiles_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"profiles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_profiles_offers => /financier/products/:product_id/profiles_offers(.:format)
  // function(product_id, options)
  financier_product_profiles_offers_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"profiles_offers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_quoter_activations => /financier/products/:product_id/quoter_activations(.:format)
  // function(product_id, options)
  financier_product_quoter_activations_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"quoter_activations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_renewal_option => /financier/products/:product_id/renewal_options/:id(.:format)
  // function(product_id, id, options)
  financier_product_renewal_option_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"renewal_options",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_renewal_options => /financier/products/:product_id/renewal_options(.:format)
  // function(product_id, options)
  financier_product_renewal_options_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"renewal_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_rules_rejections => /financier/products/:product_id/rules_rejections(.:format)
  // function(product_id, options)
  financier_product_rules_rejections_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"rules_rejections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_sat_report_settings => /financier/products/:product_id/sat_report_settings(.:format)
  // function(product_id, options)
  financier_product_sat_report_settings_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"sat_report_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_smart_score_conditions => /financier/products/:product_id/smart_score_conditions(.:format)
  // function(product_id, options)
  financier_product_smart_score_conditions_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"smart_score_conditions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_smart_score_rule => /financier/products/:product_id/smart_score_rules/:id(.:format)
  // function(product_id, id, options)
  financier_product_smart_score_rule_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"smart_score_rules",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_smart_score_rules => /financier/products/:product_id/smart_score_rules(.:format)
  // function(product_id, options)
  financier_product_smart_score_rules_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"smart_score_rules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_subproducts => /financier/products/:product_id/subproducts(.:format)
  // function(product_id, options)
  financier_product_subproducts_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"subproducts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_videocall_event => /financier/products/:product_id/videocall_events/:id(.:format)
  // function(product_id, id, options)
  financier_product_videocall_event_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"videocall_events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_product_videocall_events => /financier/products/:product_id/videocall_events(.:format)
  // function(product_id, options)
  financier_product_videocall_events_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"videocall_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_whatsapp_configuration => /financier/products/:product_id/whatsapp_configuration(.:format)
  // function(product_id, options)
  financier_product_whatsapp_configuration_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"whatsapp_configuration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_product_workflow => /financier/products/:product_id/workflow(.:format)
  // function(product_id, options)
  financier_product_workflow_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"workflow",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_products => /financier/products(.:format)
  // function(options)
  financier_products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_project => /financier/projects/:id(.:format)
  // function(id, options)
  financier_project_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_projects => /financier/projects(.:format)
  // function(options)
  financier_projects_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"projects",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_projects_kavak_external_api_calls => /financier/projects/kavak/external_api_calls(.:format)
  // function(options)
  financier_projects_kavak_external_api_calls_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[6,"kavak",false],[2,[7,"/",false],[2,[6,"external_api_calls",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_report => /financier/reports/:id(.:format)
  // function(id, options)
  financier_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_report_report_custom_form => /financier/reports/:report_id/report_custom_form(.:format)
  // function(report_id, options)
  financier_report_report_custom_form_path: Utils.route([["report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"report_id",false],[2,[7,"/",false],[2,[6,"report_custom_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_reports => /financier/reports(.:format)
  // function(options)
  financier_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_request_mailers => /financier/request_mailers(.:format)
  // function(options)
  financier_request_mailers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"request_mailers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_return_to_financier => /financier/return_to_financier(.:format)
  // function(options)
  financier_return_to_financier_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"return_to_financier",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_settings => /financier/settings(.:format)
  // function(options)
  financier_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_settings_additional_settings => /financier/settings/additional_settings(.:format)
  // function(options)
  financier_settings_additional_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"additional_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_arbol_score_results => /financier/settings/arbol_score_results(.:format)
  // function(options)
  financier_settings_arbol_score_results_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_asr_thank_you_page => /financier/settings/asr_thank_you_page(.:format)
  // function(options)
  financier_settings_asr_thank_you_page_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"asr_thank_you_page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_branding => /financier/settings/branding(.:format)
  // function(options)
  financier_settings_branding_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"branding",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_bureau_member_code => /financier/settings/bureau_member_code(.:format)
  // function(options)
  financier_settings_bureau_member_code_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"bureau_member_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_bureau_moral_member_code => /financier/settings/bureau_moral_member_code(.:format)
  // function(options)
  financier_settings_bureau_moral_member_code_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"bureau_moral_member_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_circulo_report => /financier/settings/circulo_report(.:format)
  // function(options)
  financier_settings_circulo_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"circulo_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_contact_details => /financier/settings/contact_details(.:format)
  // function(options)
  financier_settings_contact_details_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"contact_details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_custom_catalog => /financier/settings/custom_catalogs/:id(.:format)
  // function(id, options)
  financier_settings_custom_catalog_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"custom_catalogs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_settings_custom_catalogs => /financier/settings/custom_catalogs(.:format)
  // function(options)
  financier_settings_custom_catalogs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"custom_catalogs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_email_settings => /financier/settings/email_settings(.:format)
  // function(options)
  financier_settings_email_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"email_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_email_template => /financier/settings/email_templates/:id(.:format)
  // function(id, options)
  financier_settings_email_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"email_templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_settings_email_templates => /financier/settings/email_templates(.:format)
  // function(options)
  financier_settings_email_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"email_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_email_templates_email_token => /financier/settings/email_templates/email_token(.:format)
  // function(options)
  financier_settings_email_templates_email_token_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"email_templates",false],[2,[7,"/",false],[2,[6,"email_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_settings_financier_users => /financier/settings/financier_users(.:format)
  // function(options)
  financier_settings_financier_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"financier_users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_general_settings => /financier/settings/general_settings(.:format)
  // function(options)
  financier_settings_general_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"general_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_leads_key => /financier/settings/leads_key(.:format)
  // function(options)
  financier_settings_leads_key_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"leads_key",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_leads_settings => /financier/settings/leads_settings(.:format)
  // function(options)
  financier_settings_leads_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"leads_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_marketplace_dashboard => /financier/settings/marketplace_dashboard(.:format)
  // function(options)
  financier_settings_marketplace_dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"marketplace_dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_money_plus_settings => /financier/settings/money_plus_settings(.:format)
  // function(options)
  financier_settings_money_plus_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"money_plus_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_multi_bureau_requests => /financier/settings/multi_bureau_requests(.:format)
  // function(options)
  financier_settings_multi_bureau_requests_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"multi_bureau_requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_password_settings_actor_tfas => /financier/settings/password_settings/actor_tfas(.:format)
  // function(options)
  financier_settings_password_settings_actor_tfas_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"password_settings",false],[2,[7,"/",false],[2,[6,"actor_tfas",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_settings_password_settings_api_keys => /financier/settings/password_settings/api_keys(.:format)
  // function(options)
  financier_settings_password_settings_api_keys_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"password_settings",false],[2,[7,"/",false],[2,[6,"api_keys",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_settings_password_settings_password_options => /financier/settings/password_settings/password_options(.:format)
  // function(options)
  financier_settings_password_settings_password_options_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"password_settings",false],[2,[7,"/",false],[2,[6,"password_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_settings_privacy_policy => /financier/settings/privacy_policy(.:format)
  // function(options)
  financier_settings_privacy_policy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"privacy_policy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_private_blacklist => /financier/settings/private_blacklists/:id(.:format)
  // function(id, options)
  financier_settings_private_blacklist_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"private_blacklists",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_settings_private_blacklists => /financier/settings/private_blacklists(.:format)
  // function(options)
  financier_settings_private_blacklists_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"private_blacklists",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_program_email => /financier/settings/program_emails/:id(.:format)
  // function(id, options)
  financier_settings_program_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"program_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_settings_project_thank_you_page => /financier/settings/project_thank_you_page(.:format)
  // function(options)
  financier_settings_project_thank_you_page_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"project_thank_you_page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_secure_id_verification => /financier/settings/secure_id_verification(.:format)
  // function(options)
  financier_settings_secure_id_verification_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"secure_id_verification",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_signature_message => /financier/settings/signature_messages/:id(.:format)
  // function(id, options)
  financier_settings_signature_message_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"signature_messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_settings_signature_messages => /financier/settings/signature_messages(.:format)
  // function(options)
  financier_settings_signature_messages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"signature_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_sms_template => /financier/settings/sms_templates/:id(.:format)
  // function(id, options)
  financier_settings_sms_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"sms_templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_settings_sms_templates => /financier/settings/sms_templates(.:format)
  // function(options)
  financier_settings_sms_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"sms_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_thank_you_page => /financier/settings/thank_you_page(.:format)
  // function(options)
  financier_settings_thank_you_page_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"thank_you_page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_settings_whatsapp_details => /financier/settings/whatsapp_details(.:format)
  // function(options)
  financier_settings_whatsapp_details_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"whatsapp_details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_superuser => /financier/superusers/:id(.:format)
  // function(id, options)
  financier_superuser_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"superusers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_superuser_privacy => /financier/superuser/privacy(.:format)
  // function(options)
  financier_superuser_privacy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"superuser",false],[2,[7,"/",false],[2,[6,"privacy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_superusers => /financier/superusers(.:format)
  // function(options)
  financier_superusers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"superusers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_user_document_type => /financier/user/:user_id/document_types/:type(.:format)
  // function(user_id, type, options)
  financier_user_document_type_path: Utils.route([["user_id",true],["type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_user_document_types => /financier/user/:user_id/document_types(.:format)
  // function(user_id, options)
  financier_user_document_types_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_user_password => /financier_users/password(.:format)
  // function(options)
  financier_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_user_password_expired => /financier_users/password_expired(.:format)
  // function(options)
  financier_user_password_expired_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[6,"password_expired",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_user_registration => /financier_users(.:format)
  // function(options)
  financier_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier_users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// financier_user_session => /financier_users/sign_in(.:format)
  // function(options)
  financier_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_user_two_factor_authentication => /financier_users/two_factor_authentication(.:format)
  // function(options)
  financier_user_two_factor_authentication_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[6,"two_factor_authentication",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_user_unlock => /financier_users/unlock(.:format)
  // function(options)
  financier_user_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_webhook => /financier/webhooks/:id(.:format)
  // function(id, options)
  financier_webhook_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_webhooks => /financier/webhooks(.:format)
  // function(options)
  financier_webhooks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"webhooks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financier_whitelabel => /financier/whitelabels/:id(.:format)
  // function(id, options)
  financier_whitelabel_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"whitelabels",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// financier_whitelabel_copy_data_tabs => /financier/whitelabels/:whitelabel_id/copy_data_tabs(.:format)
  // function(whitelabel_id, options)
  financier_whitelabel_copy_data_tabs_path: Utils.route([["whitelabel_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"whitelabels",false],[2,[7,"/",false],[2,[3,"whitelabel_id",false],[2,[7,"/",false],[2,[6,"copy_data_tabs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_whitelabel_offered_product => /financier/whitelabels/:whitelabel_id/offered_products/:id(.:format)
  // function(whitelabel_id, id, options)
  financier_whitelabel_offered_product_path: Utils.route([["whitelabel_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"whitelabels",false],[2,[7,"/",false],[2,[3,"whitelabel_id",false],[2,[7,"/",false],[2,[6,"offered_products",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// financier_whitelabel_offered_products => /financier/whitelabels/:whitelabel_id/offered_products(.:format)
  // function(whitelabel_id, options)
  financier_whitelabel_offered_products_path: Utils.route([["whitelabel_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"whitelabels",false],[2,[7,"/",false],[2,[3,"whitelabel_id",false],[2,[7,"/",false],[2,[6,"offered_products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// financier_whitelabels => /financier/whitelabels(.:format)
  // function(options)
  financier_whitelabels_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"whitelabels",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// financiero => /financiero(.:format)
  // function(options)
  financiero_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financiero",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// folios_updates_callbacks_internal_requests_market_places => /callbacks/internal_requests/market_places/folios_updates(.:format)
  // function(options)
  folios_updates_callbacks_internal_requests_market_places_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"internal_requests",false],[2,[7,"/",false],[2,[6,"market_places",false],[2,[7,"/",false],[2,[6,"folios_updates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// full_data_api_v3_folios => /api/v3/folios/full_data(.:format)
  // function(options)
  full_data_api_v3_folios_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"folios",false],[2,[7,"/",false],[2,[6,"full_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// generated_card_financier_product_application_sub_product_applications => /financier/product_applications/:product_application_id/sub_product_applications/generated_card(.:format)
  // function(product_application_id, options)
  generated_card_financier_product_application_sub_product_applications_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"sub_product_applications",false],[2,[7,"/",false],[2,[6,"generated_card",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// geolocation_reminder_financier_arbol_score_result => /financier/arbol_score_results/:id/geolocation_reminder(.:format)
  // function(id, options)
  geolocation_reminder_financier_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"geolocation_reminder",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// geolocation_reminder_user_arbol_score_result => /users/:user_id/arbol_score_results/:id/geolocation_reminder(.:format)
  // function(user_id, id, options)
  geolocation_reminder_user_arbol_score_result_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"geolocation_reminder",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// history_financier_product_application_tabs_generated_document_index => /financier/product_applications/:product_application_id/tabs/generated_document/history(.:format)
  // function(product_application_id, options)
  history_financier_product_application_tabs_generated_document_index_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"tabs",false],[2,[7,"/",false],[2,[6,"generated_document",false],[2,[7,"/",false],[2,[6,"history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// id_verification_events => /id_verification_events(.:format)
  // function(options)
  id_verification_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"id_verification_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// import_financier_leads_importer => /financier/leads/importers/:id/import(.:format)
  // function(id, options)
  import_financier_leads_importer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"leads",false],[2,[7,"/",false],[2,[6,"importers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// import_financier_settings_private_blacklists => /financier/settings/private_blacklists/import(.:format)
  // function(options)
  import_financier_settings_private_blacklists_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"private_blacklists",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// intention_products => /products/intention(.:format)
  // function(options)
  intention_products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[6,"intention",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// joint_moral_obligor => /joint_moral_obligor/:id(.:format)
  // function(id, options)
  joint_moral_obligor_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// joint_moral_obligor_address => /joint_moral_obligor/:joint_moral_obligor_id/addresses/:id(.:format)
  // function(joint_moral_obligor_id, id, options)
  joint_moral_obligor_address_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_moral_obligor_addresses => /joint_moral_obligor/:joint_moral_obligor_id/addresses(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_addresses_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_authorized_person => /joint_moral_obligor/:joint_moral_obligor_id/authorized_persons/:id(.:format)
  // function(joint_moral_obligor_id, id, options)
  joint_moral_obligor_authorized_person_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"authorized_persons",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_moral_obligor_authorized_persons => /joint_moral_obligor/:joint_moral_obligor_id/authorized_persons(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_authorized_persons_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"authorized_persons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_bank_account => /joint_moral_obligor/:joint_moral_obligor_id/bank_accounts/:id(.:format)
  // function(joint_moral_obligor_id, id, options)
  joint_moral_obligor_bank_account_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_moral_obligor_bank_accounts => /joint_moral_obligor/:joint_moral_obligor_id/bank_accounts(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_bank_accounts_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_beneficiary_account => /joint_moral_obligor/:joint_moral_obligor_id/beneficiary_accounts/:id(.:format)
  // function(joint_moral_obligor_id, id, options)
  joint_moral_obligor_beneficiary_account_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"beneficiary_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_moral_obligor_beneficiary_accounts => /joint_moral_obligor/:joint_moral_obligor_id/beneficiary_accounts(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_beneficiary_accounts_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"beneficiary_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_business_data => /joint_moral_obligor/:joint_moral_obligor_id/business_data(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_business_data_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"business_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_credit_report => /joint_moral_obligor/:joint_moral_obligor_id/credit_report(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_credit_report_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_customer => /joint_moral_obligor/:joint_moral_obligor_id/customers/:id(.:format)
  // function(joint_moral_obligor_id, id, options)
  joint_moral_obligor_customer_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_moral_obligor_customers => /joint_moral_obligor/:joint_moral_obligor_id/customers(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_customers_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_debt => /joint_moral_obligor/:joint_moral_obligor_id/debts/:id(.:format)
  // function(joint_moral_obligor_id, id, options)
  joint_moral_obligor_debt_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"debts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_moral_obligor_debts => /joint_moral_obligor/:joint_moral_obligor_id/debts(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_debts_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"debts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_document => /joint_moral_obligor/:joint_moral_obligor_id/documents/:id(.:format)
  // function(joint_moral_obligor_id, id, options)
  joint_moral_obligor_document_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_moral_obligor_document_type => /joint_moral_obligor/:joint_moral_obligor_id/document_types/:type(.:format)
  // function(joint_moral_obligor_id, type, options)
  joint_moral_obligor_document_type_path: Utils.route([["joint_moral_obligor_id",true],["type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_moral_obligor_document_types => /joint_moral_obligor/:joint_moral_obligor_id/document_types(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_document_types_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_documents => /joint_moral_obligor/:joint_moral_obligor_id/documents(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_documents_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_financial_data => /joint_moral_obligor/:joint_moral_obligor_id/financial_data(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_financial_data_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_guarantor => /joint_moral_obligor/:joint_moral_obligor_id/guarantors/:id(.:format)
  // function(joint_moral_obligor_id, id, options)
  joint_moral_obligor_guarantor_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_moral_obligor_guarantors => /joint_moral_obligor/:joint_moral_obligor_id/guarantors(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_guarantors_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_heritage => /joint_moral_obligor/:joint_moral_obligor_id/heritages/:id(.:format)
  // function(joint_moral_obligor_id, id, options)
  joint_moral_obligor_heritage_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_moral_obligor_heritages => /joint_moral_obligor/:joint_moral_obligor_id/heritages(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_heritages_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_index => /joint_moral_obligor(.:format)
  // function(options)
  joint_moral_obligor_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// joint_moral_obligor_judgment => /joint_moral_obligor/:joint_moral_obligor_id/judgments/:id(.:format)
  // function(joint_moral_obligor_id, id, options)
  joint_moral_obligor_judgment_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_moral_obligor_judgments => /joint_moral_obligor/:joint_moral_obligor_id/judgments(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_judgments_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_main_competitor => /joint_moral_obligor/:joint_moral_obligor_id/main_competitors/:id(.:format)
  // function(joint_moral_obligor_id, id, options)
  joint_moral_obligor_main_competitor_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"main_competitors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_moral_obligor_main_competitors => /joint_moral_obligor/:joint_moral_obligor_id/main_competitors(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_main_competitors_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"main_competitors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_moral_shareholder => /joint_moral_obligor/:joint_moral_obligor_id/moral_shareholders/:id(.:format)
  // function(joint_moral_obligor_id, id, options)
  joint_moral_obligor_moral_shareholder_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"moral_shareholders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_moral_obligor_moral_shareholders => /joint_moral_obligor/:joint_moral_obligor_id/moral_shareholders(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_moral_shareholders_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"moral_shareholders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_product_service => /joint_moral_obligor/:joint_moral_obligor_id/product_services/:id(.:format)
  // function(joint_moral_obligor_id, id, options)
  joint_moral_obligor_product_service_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_moral_obligor_product_services => /joint_moral_obligor/:joint_moral_obligor_id/product_services(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_product_services_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_provider => /joint_moral_obligor/:joint_moral_obligor_id/providers/:id(.:format)
  // function(joint_moral_obligor_id, id, options)
  joint_moral_obligor_provider_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_moral_obligor_providers => /joint_moral_obligor/:joint_moral_obligor_id/providers(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_providers_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"providers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_real_owner => /joint_moral_obligor/:joint_moral_obligor_id/real_owners/:id(.:format)
  // function(joint_moral_obligor_id, id, options)
  joint_moral_obligor_real_owner_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"real_owners",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_moral_obligor_real_owners => /joint_moral_obligor/:joint_moral_obligor_id/real_owners(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_real_owners_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"real_owners",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_moral_obligor_shareholder => /joint_moral_obligor/:joint_moral_obligor_id/shareholders/:id(.:format)
  // function(joint_moral_obligor_id, id, options)
  joint_moral_obligor_shareholder_path: Utils.route([["joint_moral_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"shareholders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_moral_obligor_shareholders => /joint_moral_obligor/:joint_moral_obligor_id/shareholders(.:format)
  // function(joint_moral_obligor_id, options)
  joint_moral_obligor_shareholders_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"shareholders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor => /joint_obligors/:id(.:format)
  // function(id, options)
  joint_obligor_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// joint_obligor_active => /joint_obligors/:joint_obligor_id/actives/:id(.:format)
  // function(joint_obligor_id, id, options)
  joint_obligor_active_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"actives",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_actives => /joint_obligors/:joint_obligor_id/actives(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_actives_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"actives",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_address => /joint_obligors/:joint_obligor_id/addresses/:id(.:format)
  // function(joint_obligor_id, id, options)
  joint_obligor_address_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_addresses => /joint_obligors/:joint_obligor_id/addresses(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_addresses_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_bank_account => /joint_obligors/:joint_obligor_id/bank_accounts/:id(.:format)
  // function(joint_obligor_id, id, options)
  joint_obligor_bank_account_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_bank_accounts => /joint_obligors/:joint_obligor_id/bank_accounts(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_bank_accounts_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_credit_report => /joint_obligors/:joint_obligor_id/credit_report(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_credit_report_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_debt => /joint_obligors/:joint_obligor_id/debts/:id(.:format)
  // function(joint_obligor_id, id, options)
  joint_obligor_debt_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"debts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_debts => /joint_obligors/:joint_obligor_id/debts(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_debts_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"debts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_document => /joint_obligors/:joint_obligor_id/documents/:id(.:format)
  // function(joint_obligor_id, id, options)
  joint_obligor_document_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_document_type => /joint_obligors/:joint_obligor_id/document_types/:type(.:format)
  // function(joint_obligor_id, type, options)
  joint_obligor_document_type_path: Utils.route([["joint_obligor_id",true],["type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_document_types => /joint_obligors/:joint_obligor_id/document_types(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_document_types_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_documents => /joint_obligors/:joint_obligor_id/documents(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_documents_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_employment_record => /joint_obligors/:joint_obligor_id/employment_records/:id(.:format)
  // function(joint_obligor_id, id, options)
  joint_obligor_employment_record_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"employment_records",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_employment_records => /joint_obligors/:joint_obligor_id/employment_records(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_employment_records_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"employment_records",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_family_data => /joint_obligors/:joint_obligor_id/family_data(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_family_data_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"family_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_financial_asset => /joint_obligors/:joint_obligor_id/financial_assets/:id(.:format)
  // function(joint_obligor_id, id, options)
  joint_obligor_financial_asset_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"financial_assets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_financial_assets => /joint_obligors/:joint_obligor_id/financial_assets(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_financial_assets_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"financial_assets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_financial_data => /joint_obligors/:joint_obligor_id/financial_data(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_financial_data_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_guarantor => /joint_obligors/:joint_obligor_id/guarantors/:id(.:format)
  // function(joint_obligor_id, id, options)
  joint_obligor_guarantor_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_guarantors => /joint_obligors/:joint_obligor_id/guarantors(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_guarantors_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_heritage => /joint_obligors/:joint_obligor_id/heritages/:id(.:format)
  // function(joint_obligor_id, id, options)
  joint_obligor_heritage_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_heritages => /joint_obligors/:joint_obligor_id/heritages(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_heritages_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_judgment => /joint_obligors/:joint_obligor_id/judgments/:id(.:format)
  // function(joint_obligor_id, id, options)
  joint_obligor_judgment_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_judgments => /joint_obligors/:joint_obligor_id/judgments(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_judgments_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_passiv => /joint_obligors/:joint_obligor_id/passivs/:id(.:format)
  // function(joint_obligor_id, id, options)
  joint_obligor_passiv_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"passivs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_passivs => /joint_obligors/:joint_obligor_id/passivs(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_passivs_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"passivs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_personal_data => /joint_obligors/:joint_obligor_id/personal_data(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_personal_data_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"personal_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_personal_reference => /joint_obligors/:joint_obligor_id/personal_references/:id(.:format)
  // function(joint_obligor_id, id, options)
  joint_obligor_personal_reference_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"personal_references",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_personal_references => /joint_obligors/:joint_obligor_id/personal_references(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_personal_references_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"personal_references",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae => /joint_obligor_pfae/:id(.:format)
  // function(id, options)
  joint_obligor_pfae_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// joint_obligor_pfae_active => /joint_obligor_pfae/:joint_obligor_pfae_id/actives/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_active_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"actives",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_actives => /joint_obligor_pfae/:joint_obligor_pfae_id/actives(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_actives_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"actives",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_address => /joint_obligor_pfae/:joint_obligor_pfae_id/addresses/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_address_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_addresses => /joint_obligor_pfae/:joint_obligor_pfae_id/addresses(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_addresses_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_bank_account => /joint_obligor_pfae/:joint_obligor_pfae_id/bank_accounts/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_bank_account_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_bank_accounts => /joint_obligor_pfae/:joint_obligor_pfae_id/bank_accounts(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_bank_accounts_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_credit_report => /joint_obligor_pfae/:joint_obligor_pfae_id/credit_report(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_credit_report_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_customer => /joint_obligor_pfae/:joint_obligor_pfae_id/customers/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_customer_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_customers => /joint_obligor_pfae/:joint_obligor_pfae_id/customers(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_customers_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_debt => /joint_obligor_pfae/:joint_obligor_pfae_id/debts/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_debt_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"debts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_debts => /joint_obligor_pfae/:joint_obligor_pfae_id/debts(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_debts_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"debts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_document => /joint_obligor_pfae/:joint_obligor_pfae_id/documents/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_document_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_document_type => /joint_obligor_pfae/:joint_obligor_pfae_id/document_types/:type(.:format)
  // function(joint_obligor_pfae_id, type, options)
  joint_obligor_pfae_document_type_path: Utils.route([["joint_obligor_pfae_id",true],["type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_document_types => /joint_obligor_pfae/:joint_obligor_pfae_id/document_types(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_document_types_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_documents => /joint_obligor_pfae/:joint_obligor_pfae_id/documents(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_documents_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_employment_record => /joint_obligor_pfae/:joint_obligor_pfae_id/employment_records/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_employment_record_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"employment_records",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_employment_records => /joint_obligor_pfae/:joint_obligor_pfae_id/employment_records(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_employment_records_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"employment_records",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_family_data => /joint_obligor_pfae/:joint_obligor_pfae_id/family_data(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_family_data_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"family_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_financial_asset => /joint_obligor_pfae/:joint_obligor_pfae_id/financial_assets/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_financial_asset_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"financial_assets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_financial_assets => /joint_obligor_pfae/:joint_obligor_pfae_id/financial_assets(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_financial_assets_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"financial_assets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_financial_data => /joint_obligor_pfae/:joint_obligor_pfae_id/financial_data(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_financial_data_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_guarantor => /joint_obligor_pfae/:joint_obligor_pfae_id/guarantors/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_guarantor_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_guarantors => /joint_obligor_pfae/:joint_obligor_pfae_id/guarantors(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_guarantors_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_heritage => /joint_obligor_pfae/:joint_obligor_pfae_id/heritages/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_heritage_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_heritages => /joint_obligor_pfae/:joint_obligor_pfae_id/heritages(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_heritages_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_index => /joint_obligor_pfae(.:format)
  // function(options)
  joint_obligor_pfae_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// joint_obligor_pfae_judgment => /joint_obligor_pfae/:joint_obligor_pfae_id/judgments/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_judgment_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_judgments => /joint_obligor_pfae/:joint_obligor_pfae_id/judgments(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_judgments_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_main_competitor => /joint_obligor_pfae/:joint_obligor_pfae_id/main_competitors/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_main_competitor_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"main_competitors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_main_competitors => /joint_obligor_pfae/:joint_obligor_pfae_id/main_competitors(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_main_competitors_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"main_competitors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_moral_shareholder => /joint_obligor_pfae/:joint_obligor_pfae_id/moral_shareholders/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_moral_shareholder_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"moral_shareholders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_moral_shareholders => /joint_obligor_pfae/:joint_obligor_pfae_id/moral_shareholders(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_moral_shareholders_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"moral_shareholders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_passife => /joint_obligor_pfae/:joint_obligor_pfae_id/passives/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_passife_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"passives",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_passives => /joint_obligor_pfae/:joint_obligor_pfae_id/passives(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_passives_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"passives",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_personal_data => /joint_obligor_pfae/:joint_obligor_pfae_id/personal_data(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_personal_data_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"personal_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_personal_reference => /joint_obligor_pfae/:joint_obligor_pfae_id/personal_references/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_personal_reference_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"personal_references",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_personal_references => /joint_obligor_pfae/:joint_obligor_pfae_id/personal_references(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_personal_references_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"personal_references",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_product_service => /joint_obligor_pfae/:joint_obligor_pfae_id/product_services/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_product_service_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_product_services => /joint_obligor_pfae/:joint_obligor_pfae_id/product_services(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_product_services_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_provider => /joint_obligor_pfae/:joint_obligor_pfae_id/providers/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_provider_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_providers => /joint_obligor_pfae/:joint_obligor_pfae_id/providers(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_providers_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"providers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_share => /joint_obligor_pfae/:joint_obligor_pfae_id/shares/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_share_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"shares",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_shareholder => /joint_obligor_pfae/:joint_obligor_pfae_id/shareholders/:id(.:format)
  // function(joint_obligor_pfae_id, id, options)
  joint_obligor_pfae_shareholder_path: Utils.route([["joint_obligor_pfae_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"shareholders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_pfae_shareholders => /joint_obligor_pfae/:joint_obligor_pfae_id/shareholders(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_shareholders_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"shareholders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_pfae_shares => /joint_obligor_pfae/:joint_obligor_pfae_id/shares(.:format)
  // function(joint_obligor_pfae_id, options)
  joint_obligor_pfae_shares_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"shares",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_product_service => /joint_obligors/:joint_obligor_id/product_services/:id(.:format)
  // function(joint_obligor_id, id, options)
  joint_obligor_product_service_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_product_services => /joint_obligors/:joint_obligor_id/product_services(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_product_services_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligor_share => /joint_obligors/:joint_obligor_id/shares/:id(.:format)
  // function(joint_obligor_id, id, options)
  joint_obligor_share_path: Utils.route([["joint_obligor_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"shares",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// joint_obligor_shares => /joint_obligors/:joint_obligor_id/shares(.:format)
  // function(joint_obligor_id, options)
  joint_obligor_shares_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"shares",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// joint_obligors => /joint_obligors(.:format)
  // function(options)
  joint_obligors_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// kyc_id_verification_events => /kyc_id_verification_events(.:format)
  // function(options)
  kyc_id_verification_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"kyc_id_verification_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// landing => /landing(.:format)
  // function(options)
  landing_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"landing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// landing_health_products => /products/landing_health(.:format)
  // function(options)
  landing_health_products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[6,"landing_health",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// landing_new => /landing_new(.:format)
  // function(options)
  landing_new_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"landing_new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// landing_page => /landing_pages/:host(/:provider)(.:format)
  // function(host, options)
  landing_page_path: Utils.route([["host",true],["provider",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"landing_pages",false],[2,[7,"/",false],[2,[3,"host",false],[2,[1,[2,[7,"/",false],[3,"provider",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// landing_products => /products/landing(.:format)
  // function(options)
  landing_products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[6,"landing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// latest_external_score_financier_product_application => /financier/product_applications/:id/latest_external_score(.:format)
  // function(id, options)
  latest_external_score_financier_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"latest_external_score",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// liberate_financier_product_custom_smart_score => /financier/products/:product_id/custom_smart_scores/:id/liberate(.:format)
  // function(product_id, id, options)
  liberate_financier_product_custom_smart_score_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_smart_scores",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"liberate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// login_token_users => /users/login_token(.:format)
  // function(options)
  login_token_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"login_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// manual_update_callbacks_customers_kavak_clouds => /callbacks/customers/kavak_clouds/manual_update(.:format)
  // function(options)
  manual_update_callbacks_customers_kavak_clouds_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"kavak_clouds",false],[2,[7,"/",false],[2,[6,"manual_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// money_plus_zipfile_financier_arbol_score_result => /financier/arbol_score_results/:id/money_plus_zipfile(.:format)
  // function(id, options)
  money_plus_zipfile_financier_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"money_plus_zipfile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// my_account => /my_account(.:format)
  // function(options)
  my_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"my_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// new_admin_admin => /admin/admins/new(.:format)
  // function(options)
  new_admin_admin_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_car_dealership => /admin/car_dealerships/new(.:format)
  // function(options)
  new_admin_car_dealership_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"car_dealerships",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_car_dealership_car_price => /admin/car_dealerships/:car_dealership_id/car_prices/new(.:format)
  // function(car_dealership_id, options)
  new_admin_car_dealership_car_price_path: Utils.route([["car_dealership_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"car_dealerships",false],[2,[7,"/",false],[2,[3,"car_dealership_id",false],[2,[7,"/",false],[2,[6,"car_prices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_financier => /admin/financiers/new(.:format)
  // function(options)
  new_admin_financier_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_financier_custom_field => /admin/financiers/:financier_id/custom_fields/new(.:format)
  // function(financier_id, options)
  new_admin_financier_custom_field_path: Utils.route([["financier_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_financier_custom_fields_configuration => /admin/financiers/:financier_id/custom_fields/configuration/new(.:format)
  // function(financier_id, options)
  new_admin_financier_custom_fields_configuration_path: Utils.route([["financier_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[6,"configuration",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_admin_financier_custom_fields_divider => /admin/financiers/:financier_id/custom_fields/dividers/new(.:format)
  // function(financier_id, options)
  new_admin_financier_custom_fields_divider_path: Utils.route([["financier_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[6,"dividers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_admin_financier_institution => /admin/financiers/:financier_id/institutions/new(.:format)
  // function(financier_id, options)
  new_admin_financier_institution_path: Utils.route([["financier_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"institutions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_financier_institution_campus => /admin/financiers/:financier_id/institutions/:institution_id/campuses/new(.:format)
  // function(financier_id, institution_id, options)
  new_admin_financier_institution_campus_path: Utils.route([["financier_id",true],["institution_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"financiers",false],[2,[7,"/",false],[2,[3,"financier_id",false],[2,[7,"/",false],[2,[6,"institutions",false],[2,[7,"/",false],[2,[3,"institution_id",false],[2,[7,"/",false],[2,[6,"campuses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// new_admin_password => /admins/password/new(.:format)
  // function(options)
  new_admin_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_session => /admins/sign_in(.:format)
  // function(options)
  new_admin_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_admin_tooltip_text => /admin/tooltip_texts/new(.:format)
  // function(options)
  new_admin_tooltip_text_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tooltip_texts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_unlock => /admins/unlock/new(.:format)
  // function(options)
  new_admin_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"unlock",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_whitelabel => /admin/whitelabels/new(.:format)
  // function(options)
  new_admin_whitelabel_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"whitelabels",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_business => /business/new(.:format)
  // function(options)
  new_business_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_business_address => /business/:business_id/addresses/new(.:format)
  // function(business_id, options)
  new_business_address_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_authorized_person => /business/:business_id/authorized_persons/new(.:format)
  // function(business_id, options)
  new_business_authorized_person_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"authorized_persons",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_bank_account => /business/:business_id/bank_accounts/new(.:format)
  // function(business_id, options)
  new_business_bank_account_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_beneficiary_account => /business/:business_id/beneficiary_accounts/new(.:format)
  // function(business_id, options)
  new_business_beneficiary_account_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"beneficiary_accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_business_data => /business/:business_id/business_data/new(.:format)
  // function(business_id, options)
  new_business_business_data_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"business_data",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_customer => /business/:business_id/customers/new(.:format)
  // function(business_id, options)
  new_business_customer_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_financial_data => /business/:business_id/financial_data/new(.:format)
  // function(business_id, options)
  new_business_financial_data_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_guarantor => /business/:business_id/guarantors/new(.:format)
  // function(business_id, options)
  new_business_guarantor_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_heritage => /business/:business_id/heritages/new(.:format)
  // function(business_id, options)
  new_business_heritage_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_judgment => /business/:business_id/judgments/new(.:format)
  // function(business_id, options)
  new_business_judgment_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_main_competitor => /business/:business_id/main_competitors/new(.:format)
  // function(business_id, options)
  new_business_main_competitor_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"main_competitors",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_moral_shareholder => /business/:business_id/moral_shareholders/new(.:format)
  // function(business_id, options)
  new_business_moral_shareholder_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"moral_shareholders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_product_service => /business/:business_id/product_services/new(.:format)
  // function(business_id, options)
  new_business_product_service_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_provider => /business/:business_id/providers/new(.:format)
  // function(business_id, options)
  new_business_provider_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_real_owner => /business/:business_id/real_owners/new(.:format)
  // function(business_id, options)
  new_business_real_owner_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"real_owners",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_shareholder => /business/:business_id/shareholders/new(.:format)
  // function(business_id, options)
  new_business_shareholder_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"shareholders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_stp_researchable_account => /business/:business_id/stp_researchable_accounts/new(.:format)
  // function(business_id, options)
  new_business_stp_researchable_account_path: Utils.route([["business_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business",false],[2,[7,"/",false],[2,[3,"business_id",false],[2,[7,"/",false],[2,[6,"stp_researchable_accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_car_dealership_user_password => /agencia/cuenta/password/new(.:format)
  // function(options)
  new_car_dealership_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"cuenta",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_car_dealership_user_registration => /agencia/cuenta/sign_up(.:format)
  // function(options)
  new_car_dealership_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"cuenta",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_car_dealership_user_session => /agencia/cuenta/sign_in(.:format)
  // function(options)
  new_car_dealership_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"cuenta",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_car_dealership_user_user => /agencia/clientes/new(.:format)
  // function(options)
  new_car_dealership_user_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_car_dealership_user_user_document => /agencia/clientes/:user_id/documents/new(.:format)
  // function(user_id, options)
  new_car_dealership_user_user_document_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_financier_campaign => /financier/campaigns/new(.:format)
  // function(options)
  new_financier_campaign_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_financier_circulo_authentication_report => /financier/circulo_authentication_reports/new(.:format)
  // function(options)
  new_financier_circulo_authentication_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"circulo_authentication_reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_financier_contact_request => /financier/contact_requests/new(.:format)
  // function(options)
  new_financier_contact_request_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"contact_requests",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_financier_custom_field => /financier/custom_fields/new(.:format)
  // function(options)
  new_financier_custom_field_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_financier_document_template => /financier/document_templates/new(.:format)
  // function(options)
  new_financier_document_template_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"document_templates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_financier_external_api => /financier/external_apis/new(.:format)
  // function(options)
  new_financier_external_api_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"external_apis",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_financier_financier_user => /financier/financier_users/new(.:format)
  // function(options)
  new_financier_financier_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_financier_landing_search_group => /financier/landing_search_groups/new(.:format)
  // function(options)
  new_financier_landing_search_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"landing_search_groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_financier_leads_importer => /financier/leads/importers/new(.:format)
  // function(options)
  new_financier_leads_importer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"leads",false],[2,[7,"/",false],[2,[6,"importers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_financier_product => /financier/products/new(.:format)
  // function(options)
  new_financier_product_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_financier_product_application => /financier/product_applications/new(.:format)
  // function(options)
  new_financier_product_application_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_financier_product_application_boa_vista_widget => /financier/product_applications/:product_application_id/boa_vista_widgets/new(.:format)
  // function(product_application_id, options)
  new_financier_product_application_boa_vista_widget_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"boa_vista_widgets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_financier_product_application_identity_validation => /financier/product_applications/:product_application_id/identity_validations/new(.:format)
  // function(product_application_id, options)
  new_financier_product_application_identity_validation_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"identity_validations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_financier_product_application_ocular_visits_ocular_visit_media_records => /financier/product_applications/:product_application_id/ocular_visits/ocular_visit_media_records/new(.:format)
  // function(product_application_id, options)
  new_financier_product_application_ocular_visits_ocular_visit_media_records_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"ocular_visits",false],[2,[7,"/",false],[2,[6,"ocular_visit_media_records",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_financier_product_application_ocular_visits_product_application_photos => /financier/product_applications/:product_application_id/ocular_visits/product_application_photos/new(.:format)
  // function(product_application_id, options)
  new_financier_product_application_ocular_visits_product_application_photos_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"ocular_visits",false],[2,[7,"/",false],[2,[6,"product_application_photos",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_financier_product_application_otp_request => /financier/product_applications/:product_application_id/otp_requests/new(.:format)
  // function(product_application_id, options)
  new_financier_product_application_otp_request_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"otp_requests",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_financier_product_application_product_application_message => /financier/product_applications/:product_application_id/product_application_messages/new(.:format)
  // function(product_application_id, options)
  new_financier_product_application_product_application_message_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"product_application_messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_financier_product_application_sat_report => /financier/product_applications/:product_application_id/sat_reports/new(.:format)
  // function(product_application_id, options)
  new_financier_product_application_sat_report_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"sat_reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_financier_product_application_videocall => /financier/product_applications/:product_application_id/videocalls/new(.:format)
  // function(product_application_id, options)
  new_financier_product_application_videocall_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"videocalls",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_financier_product_custom_smart_score_cluster => /financier/products/:product_id/custom_smart_scores/:custom_smart_score_id/clusters/new(.:format)
  // function(product_id, custom_smart_score_id, options)
  new_financier_product_custom_smart_score_cluster_path: Utils.route([["product_id",true],["custom_smart_score_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_smart_scores",false],[2,[7,"/",false],[2,[3,"custom_smart_score_id",false],[2,[7,"/",false],[2,[6,"clusters",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// new_financier_product_document_catalog => /financier/products/:product_id/document_catalogs/new(.:format)
  // function(product_id, options)
  new_financier_product_document_catalog_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"document_catalogs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_financier_product_document_catalog_document_type => /financier/products/:product_id/document_catalogs/:document_catalog_id/document_types/new(.:format)
  // function(product_id, document_catalog_id, options)
  new_financier_product_document_catalog_document_type_path: Utils.route([["product_id",true],["document_catalog_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"document_catalogs",false],[2,[7,"/",false],[2,[3,"document_catalog_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// new_financier_product_landing_page => /financier/product_landing_pages/new(.:format)
  // function(options)
  new_financier_product_landing_page_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_landing_pages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_financier_product_media_record_motive => /financier/products/:product_id/media_record_motives/new(.:format)
  // function(product_id, options)
  new_financier_product_media_record_motive_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"media_record_motives",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_financier_product_product_application_priority => /financier/products/:product_id/product_application_priorities/new(.:format)
  // function(product_id, options)
  new_financier_product_product_application_priority_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"product_application_priorities",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_financier_product_profile => /financier/products/:product_id/profiles/new(.:format)
  // function(product_id, options)
  new_financier_product_profile_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"profiles",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_financier_product_subproduct => /financier/products/:product_id/subproducts/new(.:format)
  // function(product_id, options)
  new_financier_product_subproduct_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"subproducts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_financier_product_videocall_event => /financier/products/:product_id/videocall_events/new(.:format)
  // function(product_id, options)
  new_financier_product_videocall_event_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"videocall_events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_financier_project => /financier/projects/new(.:format)
  // function(options)
  new_financier_project_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_financier_settings_custom_catalog => /financier/settings/custom_catalogs/new(.:format)
  // function(options)
  new_financier_settings_custom_catalog_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"custom_catalogs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_financier_settings_private_blacklist => /financier/settings/private_blacklists/new(.:format)
  // function(options)
  new_financier_settings_private_blacklist_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"private_blacklists",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_financier_settings_signature_message => /financier/settings/signature_messages/new(.:format)
  // function(options)
  new_financier_settings_signature_message_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"signature_messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_financier_superuser => /financier/superusers/new(.:format)
  // function(options)
  new_financier_superuser_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"superusers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_financier_user_password => /financier_users/password/new(.:format)
  // function(options)
  new_financier_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_financier_user_session => /financier_users/sign_in(.:format)
  // function(options)
  new_financier_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_financier_user_unlock => /financier_users/unlock/new(.:format)
  // function(options)
  new_financier_user_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[6,"unlock",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_financier_webhook => /financier/webhooks/new(.:format)
  // function(options)
  new_financier_webhook_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_financier_whitelabel => /financier/whitelabels/new(.:format)
  // function(options)
  new_financier_whitelabel_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"whitelabels",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_joint_moral_obligor => /joint_moral_obligor/new(.:format)
  // function(options)
  new_joint_moral_obligor_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_joint_moral_obligor_address => /joint_moral_obligor/:joint_moral_obligor_id/addresses/new(.:format)
  // function(joint_moral_obligor_id, options)
  new_joint_moral_obligor_address_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_moral_obligor_authorized_person => /joint_moral_obligor/:joint_moral_obligor_id/authorized_persons/new(.:format)
  // function(joint_moral_obligor_id, options)
  new_joint_moral_obligor_authorized_person_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"authorized_persons",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_moral_obligor_bank_account => /joint_moral_obligor/:joint_moral_obligor_id/bank_accounts/new(.:format)
  // function(joint_moral_obligor_id, options)
  new_joint_moral_obligor_bank_account_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_moral_obligor_beneficiary_account => /joint_moral_obligor/:joint_moral_obligor_id/beneficiary_accounts/new(.:format)
  // function(joint_moral_obligor_id, options)
  new_joint_moral_obligor_beneficiary_account_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"beneficiary_accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_moral_obligor_business_data => /joint_moral_obligor/:joint_moral_obligor_id/business_data/new(.:format)
  // function(joint_moral_obligor_id, options)
  new_joint_moral_obligor_business_data_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"business_data",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_moral_obligor_credit_report => /joint_moral_obligor/:joint_moral_obligor_id/credit_report/new(.:format)
  // function(joint_moral_obligor_id, options)
  new_joint_moral_obligor_credit_report_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_moral_obligor_customer => /joint_moral_obligor/:joint_moral_obligor_id/customers/new(.:format)
  // function(joint_moral_obligor_id, options)
  new_joint_moral_obligor_customer_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_moral_obligor_debt => /joint_moral_obligor/:joint_moral_obligor_id/debts/new(.:format)
  // function(joint_moral_obligor_id, options)
  new_joint_moral_obligor_debt_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"debts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_moral_obligor_financial_data => /joint_moral_obligor/:joint_moral_obligor_id/financial_data/new(.:format)
  // function(joint_moral_obligor_id, options)
  new_joint_moral_obligor_financial_data_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_moral_obligor_guarantor => /joint_moral_obligor/:joint_moral_obligor_id/guarantors/new(.:format)
  // function(joint_moral_obligor_id, options)
  new_joint_moral_obligor_guarantor_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_moral_obligor_heritage => /joint_moral_obligor/:joint_moral_obligor_id/heritages/new(.:format)
  // function(joint_moral_obligor_id, options)
  new_joint_moral_obligor_heritage_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_moral_obligor_judgment => /joint_moral_obligor/:joint_moral_obligor_id/judgments/new(.:format)
  // function(joint_moral_obligor_id, options)
  new_joint_moral_obligor_judgment_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_moral_obligor_main_competitor => /joint_moral_obligor/:joint_moral_obligor_id/main_competitors/new(.:format)
  // function(joint_moral_obligor_id, options)
  new_joint_moral_obligor_main_competitor_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"main_competitors",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_moral_obligor_moral_shareholder => /joint_moral_obligor/:joint_moral_obligor_id/moral_shareholders/new(.:format)
  // function(joint_moral_obligor_id, options)
  new_joint_moral_obligor_moral_shareholder_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"moral_shareholders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_moral_obligor_product_service => /joint_moral_obligor/:joint_moral_obligor_id/product_services/new(.:format)
  // function(joint_moral_obligor_id, options)
  new_joint_moral_obligor_product_service_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_moral_obligor_provider => /joint_moral_obligor/:joint_moral_obligor_id/providers/new(.:format)
  // function(joint_moral_obligor_id, options)
  new_joint_moral_obligor_provider_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_moral_obligor_real_owner => /joint_moral_obligor/:joint_moral_obligor_id/real_owners/new(.:format)
  // function(joint_moral_obligor_id, options)
  new_joint_moral_obligor_real_owner_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"real_owners",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_moral_obligor_shareholder => /joint_moral_obligor/:joint_moral_obligor_id/shareholders/new(.:format)
  // function(joint_moral_obligor_id, options)
  new_joint_moral_obligor_shareholder_path: Utils.route([["joint_moral_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_moral_obligor",false],[2,[7,"/",false],[2,[3,"joint_moral_obligor_id",false],[2,[7,"/",false],[2,[6,"shareholders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor => /joint_obligors/new(.:format)
  // function(options)
  new_joint_obligor_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_joint_obligor_active => /joint_obligors/:joint_obligor_id/actives/new(.:format)
  // function(joint_obligor_id, options)
  new_joint_obligor_active_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"actives",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_address => /joint_obligors/:joint_obligor_id/addresses/new(.:format)
  // function(joint_obligor_id, options)
  new_joint_obligor_address_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_bank_account => /joint_obligors/:joint_obligor_id/bank_accounts/new(.:format)
  // function(joint_obligor_id, options)
  new_joint_obligor_bank_account_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_credit_report => /joint_obligors/:joint_obligor_id/credit_report/new(.:format)
  // function(joint_obligor_id, options)
  new_joint_obligor_credit_report_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_debt => /joint_obligors/:joint_obligor_id/debts/new(.:format)
  // function(joint_obligor_id, options)
  new_joint_obligor_debt_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"debts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_employment_record => /joint_obligors/:joint_obligor_id/employment_records/new(.:format)
  // function(joint_obligor_id, options)
  new_joint_obligor_employment_record_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"employment_records",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_family_data => /joint_obligors/:joint_obligor_id/family_data/new(.:format)
  // function(joint_obligor_id, options)
  new_joint_obligor_family_data_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"family_data",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_financial_asset => /joint_obligors/:joint_obligor_id/financial_assets/new(.:format)
  // function(joint_obligor_id, options)
  new_joint_obligor_financial_asset_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"financial_assets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_financial_data => /joint_obligors/:joint_obligor_id/financial_data/new(.:format)
  // function(joint_obligor_id, options)
  new_joint_obligor_financial_data_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_guarantor => /joint_obligors/:joint_obligor_id/guarantors/new(.:format)
  // function(joint_obligor_id, options)
  new_joint_obligor_guarantor_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_heritage => /joint_obligors/:joint_obligor_id/heritages/new(.:format)
  // function(joint_obligor_id, options)
  new_joint_obligor_heritage_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_judgment => /joint_obligors/:joint_obligor_id/judgments/new(.:format)
  // function(joint_obligor_id, options)
  new_joint_obligor_judgment_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_passiv => /joint_obligors/:joint_obligor_id/passivs/new(.:format)
  // function(joint_obligor_id, options)
  new_joint_obligor_passiv_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"passivs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_personal_data => /joint_obligors/:joint_obligor_id/personal_data/new(.:format)
  // function(joint_obligor_id, options)
  new_joint_obligor_personal_data_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"personal_data",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_personal_reference => /joint_obligors/:joint_obligor_id/personal_references/new(.:format)
  // function(joint_obligor_id, options)
  new_joint_obligor_personal_reference_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"personal_references",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae => /joint_obligor_pfae/new(.:format)
  // function(options)
  new_joint_obligor_pfae_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_joint_obligor_pfae_active => /joint_obligor_pfae/:joint_obligor_pfae_id/actives/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_active_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"actives",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_address => /joint_obligor_pfae/:joint_obligor_pfae_id/addresses/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_address_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_bank_account => /joint_obligor_pfae/:joint_obligor_pfae_id/bank_accounts/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_bank_account_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_credit_report => /joint_obligor_pfae/:joint_obligor_pfae_id/credit_report/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_credit_report_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_customer => /joint_obligor_pfae/:joint_obligor_pfae_id/customers/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_customer_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_debt => /joint_obligor_pfae/:joint_obligor_pfae_id/debts/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_debt_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"debts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_employment_record => /joint_obligor_pfae/:joint_obligor_pfae_id/employment_records/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_employment_record_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"employment_records",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_family_data => /joint_obligor_pfae/:joint_obligor_pfae_id/family_data/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_family_data_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"family_data",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_financial_asset => /joint_obligor_pfae/:joint_obligor_pfae_id/financial_assets/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_financial_asset_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"financial_assets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_financial_data => /joint_obligor_pfae/:joint_obligor_pfae_id/financial_data/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_financial_data_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_guarantor => /joint_obligor_pfae/:joint_obligor_pfae_id/guarantors/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_guarantor_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_heritage => /joint_obligor_pfae/:joint_obligor_pfae_id/heritages/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_heritage_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_judgment => /joint_obligor_pfae/:joint_obligor_pfae_id/judgments/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_judgment_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_main_competitor => /joint_obligor_pfae/:joint_obligor_pfae_id/main_competitors/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_main_competitor_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"main_competitors",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_moral_shareholder => /joint_obligor_pfae/:joint_obligor_pfae_id/moral_shareholders/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_moral_shareholder_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"moral_shareholders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_passife => /joint_obligor_pfae/:joint_obligor_pfae_id/passives/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_passife_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"passives",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_personal_data => /joint_obligor_pfae/:joint_obligor_pfae_id/personal_data/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_personal_data_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"personal_data",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_personal_reference => /joint_obligor_pfae/:joint_obligor_pfae_id/personal_references/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_personal_reference_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"personal_references",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_product_service => /joint_obligor_pfae/:joint_obligor_pfae_id/product_services/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_product_service_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_provider => /joint_obligor_pfae/:joint_obligor_pfae_id/providers/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_provider_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_share => /joint_obligor_pfae/:joint_obligor_pfae_id/shares/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_share_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"shares",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_pfae_shareholder => /joint_obligor_pfae/:joint_obligor_pfae_id/shareholders/new(.:format)
  // function(joint_obligor_pfae_id, options)
  new_joint_obligor_pfae_shareholder_path: Utils.route([["joint_obligor_pfae_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligor_pfae",false],[2,[7,"/",false],[2,[3,"joint_obligor_pfae_id",false],[2,[7,"/",false],[2,[6,"shareholders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_product_service => /joint_obligors/:joint_obligor_id/product_services/new(.:format)
  // function(joint_obligor_id, options)
  new_joint_obligor_product_service_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_joint_obligor_share => /joint_obligors/:joint_obligor_id/shares/new(.:format)
  // function(joint_obligor_id, options)
  new_joint_obligor_share_path: Utils.route([["joint_obligor_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"joint_obligors",false],[2,[7,"/",false],[2,[3,"joint_obligor_id",false],[2,[7,"/",false],[2,[6,"shares",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_observer_arbol_score_result => /observer/arbol_score_results/new(.:format)
  // function(options)
  new_observer_arbol_score_result_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_observer_password => /observers/password/new(.:format)
  // function(options)
  new_observer_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"observers",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_observer_product_application_product_application_message => /observer/product_applications/:product_application_id/product_application_messages/new(.:format)
  // function(product_application_id, options)
  new_observer_product_application_product_application_message_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"product_application_messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_observer_session => /observers/sign_in(.:format)
  // function(options)
  new_observer_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"observers",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_product => /products/new(.:format)
  // function(options)
  new_product_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_product_application => /product_applications/new(.:format)
  // function(options)
  new_product_application_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_product_application_product_application_message => /product_applications/:product_application_id/product_application_messages/new(.:format)
  // function(product_application_id, options)
  new_product_application_product_application_message_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"product_application_messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_project => /projects/new(.:format)
  // function(options)
  new_project_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_report_request => /report_requests/new(.:format)
  // function(options)
  new_report_request_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"report_requests",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_sat_report => /sat_reports/new(.:format)
  // function(options)
  new_sat_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sat_reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user => /users/new(.:format)
  // function(options)
  new_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_active => /users/:user_id/actives/new(.:format)
  // function(user_id, options)
  new_user_active_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"actives",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_address => /users/:user_id/addresses/new(.:format)
  // function(user_id, options)
  new_user_address_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_arbol_score_result => /users/:user_id/arbol_score_results/new(.:format)
  // function(user_id, options)
  new_user_arbol_score_result_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_bank_account => /users/:user_id/bank_accounts/new(.:format)
  // function(user_id, options)
  new_user_bank_account_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_bureau_requests => /user/bureau_requests/new(.:format)
  // function(options)
  new_user_bureau_requests_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"bureau_requests",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_confirmation => /users/confirmation/new(.:format)
  // function(options)
  new_user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_contact_request => /users/:user_id/contact_requests/new(.:format)
  // function(user_id, options)
  new_user_contact_request_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"contact_requests",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_credit_report => /users/:user_id/credit_report/new(.:format)
  // function(user_id, options)
  new_user_credit_report_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_customer => /users/:user_id/customers/new(.:format)
  // function(user_id, options)
  new_user_customer_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_debt => /users/:user_id/debts/new(.:format)
  // function(user_id, options)
  new_user_debt_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"debts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_employment_record => /users/:user_id/employment_records/new(.:format)
  // function(user_id, options)
  new_user_employment_record_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"employment_records",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_family_data => /users/:user_id/family_data/new(.:format)
  // function(user_id, options)
  new_user_family_data_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"family_data",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_financial_asset => /users/:user_id/financial_assets/new(.:format)
  // function(user_id, options)
  new_user_financial_asset_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"financial_assets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_financial_data => /users/:user_id/financial_data/new(.:format)
  // function(user_id, options)
  new_user_financial_data_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_financing_config => /users/:user_id/financing_config/new(.:format)
  // function(user_id, options)
  new_user_financing_config_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"financing_config",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_geolocation_data => /users/:user_id/geolocation_data/new(.:format)
  // function(user_id, options)
  new_user_geolocation_data_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"geolocation_data",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_guarantor => /users/:user_id/guarantors/new(.:format)
  // function(user_id, options)
  new_user_guarantor_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_heritage => /users/:user_id/heritages/new(.:format)
  // function(user_id, options)
  new_user_heritage_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_id_verification => /user/id_verification/new(.:format)
  // function(options)
  new_user_id_verification_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"id_verification",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_judgment => /users/:user_id/judgments/new(.:format)
  // function(user_id, options)
  new_user_judgment_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_main_competitor => /users/:user_id/main_competitors/new(.:format)
  // function(user_id, options)
  new_user_main_competitor_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"main_competitors",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_passiv => /users/:user_id/passivs/new(.:format)
  // function(user_id, options)
  new_user_passiv_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"passivs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_password => /users/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_personal_data => /users/:user_id/personal_data/new(.:format)
  // function(user_id, options)
  new_user_personal_data_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"personal_data",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_personal_reference => /users/:user_id/personal_references/new(.:format)
  // function(user_id, options)
  new_user_personal_reference_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"personal_references",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_previous_study => /users/:user_id/previous_studies/new(.:format)
  // function(user_id, options)
  new_user_previous_study_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"previous_studies",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_product_service => /users/:user_id/product_services/new(.:format)
  // function(user_id, options)
  new_user_product_service_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_provider => /users/:user_id/providers/new(.:format)
  // function(user_id, options)
  new_user_provider_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_registration => /users/sign_up(.:format)
  // function(options)
  new_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_share => /users/:user_id/shares/new(.:format)
  // function(user_id, options)
  new_user_share_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"shares",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_stp_researchable_account => /users/:user_id/stp_researchable_accounts/new(.:format)
  // function(user_id, options)
  new_user_stp_researchable_account_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"stp_researchable_accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_unlock => /users/unlock/new(.:format)
  // function(options)
  new_user_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unlock",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_warranty => /warranties/new(.:format)
  // function(options)
  new_warranty_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warranties",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_warranty_warranty_document => /warranties/:warranty_id/warranty_documents/new(.:format)
  // function(warranty_id, options)
  new_warranty_warranty_document_path: Utils.route([["warranty_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warranties",false],[2,[7,"/",false],[2,[3,"warranty_id",false],[2,[7,"/",false],[2,[6,"warranty_documents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// observer_arbol_score_result => /observer/arbol_score_results/:id(.:format)
  // function(id, options)
  observer_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// observer_arbol_score_result_address => /observer/arbol_score_results/:arbol_score_result_id/address(.:format)
  // function(arbol_score_result_id, options)
  observer_arbol_score_result_address_path: Utils.route([["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// observer_arbol_score_result_credit_report => /observer/arbol_score_results/:arbol_score_result_id/credit_report(.:format)
  // function(arbol_score_result_id, options)
  observer_arbol_score_result_credit_report_path: Utils.route([["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// observer_arbol_score_result_financial_data => /observer/arbol_score_results/:arbol_score_result_id/financial_data(.:format)
  // function(arbol_score_result_id, options)
  observer_arbol_score_result_financial_data_path: Utils.route([["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// observer_arbol_score_result_personal_data => /observer/arbol_score_results/:arbol_score_result_id/personal_data(.:format)
  // function(arbol_score_result_id, options)
  observer_arbol_score_result_personal_data_path: Utils.route([["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"personal_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// observer_arbol_score_results => /observer/arbol_score_results(.:format)
  // function(options)
  observer_arbol_score_results_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// observer_authenticated_observer => /observer(.:format)
  // function(options)
  observer_authenticated_observer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// observer_become_user => /observer/become_user/:user_id(.:format)
  // function(user_id, options)
  observer_become_user_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"become_user",false],[2,[7,"/",false],[2,[3,"user_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// observer_password => /observers/password(.:format)
  // function(options)
  observer_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"observers",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// observer_password_expired => /observers/password_expired(.:format)
  // function(options)
  observer_password_expired_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"observers",false],[2,[7,"/",false],[2,[6,"password_expired",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// observer_product_application => /observer/product_applications/:id(.:format)
  // function(id, options)
  observer_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// observer_product_application_product_application_message => /observer/product_applications/:product_application_id/product_application_messages/:id(.:format)
  // function(product_application_id, id, options)
  observer_product_application_product_application_message_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"product_application_messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// observer_product_application_product_application_messages => /observer/product_applications/:product_application_id/product_application_messages(.:format)
  // function(product_application_id, options)
  observer_product_application_product_application_messages_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"product_application_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// observer_product_applications => /observer/product_applications(.:format)
  // function(options)
  observer_product_applications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"product_applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// observer_registration => /observers(.:format)
  // function(options)
  observer_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"observers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// observer_return_to_observer => /observer/return_to_observer(.:format)
  // function(options)
  observer_return_to_observer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"return_to_observer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// observer_session => /observers/sign_in(.:format)
  // function(options)
  observer_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"observers",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oportunidad_finanzas_callbacks_internal_requests_amortization_tables => /callbacks/internal_requests/amortization_tables/oportunidad_finanzas(.:format)
  // function(options)
  oportunidad_finanzas_callbacks_internal_requests_amortization_tables_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"internal_requests",false],[2,[7,"/",false],[2,[6,"amortization_tables",false],[2,[7,"/",false],[2,[6,"oportunidad_finanzas",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// otp_code_by_call => /otp_code_by_call/:id(.:format)
  // function(id, options)
  otp_code_by_call_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"otp_code_by_call",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pdf_observer_arbol_score_result => /observer/arbol_score_results/:id/pdf(.:format)
  // function(id, options)
  pdf_observer_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"pdf",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// pdf_test => /pdf_test(.:format)
  // function(options)
  pdf_test_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pdf_test",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// pending_campuses_to_select_product_applications => /product_applications/pending_campuses_to_select(.:format)
  // function(options)
  pending_campuses_to_select_product_applications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[6,"pending_campuses_to_select",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pending_sat_reports => /sat_reports/pending(.:format)
  // function(options)
  pending_sat_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sat_reports",false],[2,[7,"/",false],[2,[6,"pending",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pin_code_by_call => /pin_code_by_call/:uuid(.:format)
  // function(uuid, options)
  pin_code_by_call_path: Utils.route([["uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pin_code_by_call",false],[2,[7,"/",false],[2,[3,"uuid",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// preview_financier_leads_importer => /financier/leads/importers/:id/preview(.:format)
  // function(id, options)
  preview_financier_leads_importer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"leads",false],[2,[7,"/",false],[2,[6,"importers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// privacy => /privacy(.:format)
  // function(options)
  privacy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"privacy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// product => /products/:id(.:format)
  // function(id, options)
  product_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// product_application => /product_applications/:id(.:format)
  // function(id, options)
  product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// product_application_complete_project => /projects/:id/product_application_complete(.:format)
  // function(id, options)
  product_application_complete_project_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"product_application_complete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// product_application_credit_options => /product_applications/:product_application_id/credit_options(.:format)
  // function(product_application_id, options)
  product_application_credit_options_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"credit_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// product_application_document_templates_zipped_templates => /product_applications/:product_application_id/document_templates/zipped_templates(.:format)
  // function(product_application_id, options)
  product_application_document_templates_zipped_templates_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"document_templates",false],[2,[7,"/",false],[2,[6,"zipped_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// product_application_generated_document_digital_signature => /product_applications/:product_application_id/generated_documents/:generated_document_id/digital_signature(.:format)
  // function(product_application_id, generated_document_id, options)
  product_application_generated_document_digital_signature_path: Utils.route([["product_application_id",true],["generated_document_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"generated_documents",false],[2,[7,"/",false],[2,[3,"generated_document_id",false],[2,[7,"/",false],[2,[6,"digital_signature",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// product_application_generated_document_physical_signature => /product_applications/:product_application_id/generated_documents/:generated_document_id/physical_signature(.:format)
  // function(product_application_id, generated_document_id, options)
  product_application_generated_document_physical_signature_path: Utils.route([["product_application_id",true],["generated_document_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"generated_documents",false],[2,[7,"/",false],[2,[3,"generated_document_id",false],[2,[7,"/",false],[2,[6,"physical_signature",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// product_application_generated_documents => /product_applications/:product_application_id/generated_documents(.:format)
  // function(product_application_id, options)
  product_application_generated_documents_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"generated_documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// product_application_moral_person_credit_report => /product_applications/:product_application_id/moral_person_credit_reports/:id(.:format)
  // function(product_application_id, id, options)
  product_application_moral_person_credit_report_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"moral_person_credit_reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// product_application_product_application_message => /product_applications/:product_application_id/product_application_messages/:id(.:format)
  // function(product_application_id, id, options)
  product_application_product_application_message_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"product_application_messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// product_application_product_application_messages => /product_applications/:product_application_id/product_application_messages(.:format)
  // function(product_application_id, options)
  product_application_product_application_messages_path: Utils.route([["product_application_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"product_application_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// product_applications => /product_applications(.:format)
  // function(options)
  product_applications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// products => /products(.:format)
  // function(options)
  products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// project => /projects/:id(.:format)
  // function(id, options)
  project_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// project_geolocalization => /projects/:project_id/geolocalization(.:format)
  // function(project_id, options)
  project_geolocalization_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"geolocalization",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_kyc => /projects/:project_id/kyc(.:format)
  // function(project_id, options)
  project_kyc_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"kyc",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_moral_bureau_request => /projects/:project_id/moral_bureau_requests/:id(.:format)
  // function(project_id, id, options)
  project_moral_bureau_request_path: Utils.route([["project_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"moral_bureau_requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// project_otp => /projects/:project_id/otp(.:format)
  // function(project_id, options)
  project_otp_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"otp",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_otp_media_record => /projects/:project_id/otp_media_record(.:format)
  // function(project_id, options)
  project_otp_media_record_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"otp_media_record",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_summary_report => /projects/:project_id/summary_reports/:id(.:format)
  // function(project_id, id, options)
  project_summary_report_path: Utils.route([["project_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"summary_reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// project_video_record => /projects/:project_id/video_record(.:format)
  // function(project_id, options)
  project_video_record_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"video_record",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// project_videocall => /projects/:project_id/videocalls/:id(.:format)
  // function(project_id, id, options)
  project_videocall_path: Utils.route([["project_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"videocalls",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// projects => /projects(.:format)
  // function(options)
  projects_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// projects_by_parameters => /projects_by_parameters(.:format)
  // function(options)
  projects_by_parameters_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"projects_by_parameters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// prosperus_callbacks_internal_requests_amortization_tables => /callbacks/internal_requests/amortization_tables/prosperus(.:format)
  // function(options)
  prosperus_callbacks_internal_requests_amortization_tables_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"internal_requests",false],[2,[7,"/",false],[2,[6,"amortization_tables",false],[2,[7,"/",false],[2,[6,"prosperus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// questionary_obligor => /questionary_obligor/:id(.:format)
  // function(id, options)
  questionary_obligor_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"questionary_obligor",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// questionary_obligor_response => /questionary_obligor/:id(.:format)
  // function(id, options)
  questionary_obligor_response_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"questionary_obligor",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// questionary_verification => /questionary_verification/:id(.:format)
  // function(id, options)
  questionary_verification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"questionary_verification",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// questionary_verification_response => /questionary_verification/:id/:response(.:format)
  // function(id, response, options)
  questionary_verification_response_path: Utils.route([["id",true],["response",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"questionary_verification",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[3,"response",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reactivate_admin_admin => /admin/admins/:id/reactivate(.:format)
  // function(id, options)
  reactivate_admin_admin_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reactivate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// reactivate_financier_arbol_score_result => /financier/arbol_score_results/:id/reactivate(.:format)
  // function(id, options)
  reactivate_financier_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reactivate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// recalculate_score_financier_arbol_score_result => /financier/arbol_score_results/:id/recalculate_score(.:format)
  // function(id, options)
  recalculate_score_financier_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"recalculate_score",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// recalculate_score_user_arbol_score_result => /users/:user_id/arbol_score_results/:id/recalculate_score(.:format)
  // function(user_id, id, options)
  recalculate_score_user_arbol_score_result_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"recalculate_score",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// receive_report_api_v2_project_credit_report => /api/v2/projects/:project_id/credit_report/receive_report(.:format)
  // function(project_id, options)
  receive_report_api_v2_project_credit_report_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[2,[7,"/",false],[2,[6,"receive_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// register_actors_project => /projects/:id/register_actors(.:format)
  // function(id, options)
  register_actors_project_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"register_actors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reject_verification_observer_product_application => /observer/product_applications/:id/reject_verification(.:format)
  // function(id, options)
  reject_verification_observer_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reject_verification",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// reminder_missing_information_financier_product_application => /financier/product_applications/:id/reminder_missing_information(.:format)
  // function(id, options)
  reminder_missing_information_financier_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reminder_missing_information",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// renewal_projects => /projects/renewal(.:format)
  // function(options)
  renewal_projects_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[6,"renewal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// report_folios_financier_product_applications => /financier/product_applications/report_folios(.:format)
  // function(options)
  report_folios_financier_product_applications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[6,"report_folios",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// report_request => /report_requests/:id(.:format)
  // function(id, options)
  report_request_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"report_requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// report_requests => /report_requests(.:format)
  // function(options)
  report_requests_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"report_requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// request_external_score_financier_product_application => /financier/product_applications/:id/request_external_score(.:format)
  // function(id, options)
  request_external_score_financier_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"request_external_score",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// request_help_project => /projects/:id/request_help(.:format)
  // function(id, options)
  request_help_project_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"request_help",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// request_otp_code_financier_product_application_otp_request => /financier/product_applications/:product_application_id/otp_requests/:id/request_otp_code(.:format)
  // function(product_application_id, id, options)
  request_otp_code_financier_product_application_otp_request_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"otp_requests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"request_otp_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// request_otp_code_project_kyc => /projects/:project_id/kyc/request_otp_code(.:format)
  // function(project_id, options)
  request_otp_code_project_kyc_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"kyc",false],[2,[7,"/",false],[2,[6,"request_otp_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// request_otp_code_project_otp => /projects/:project_id/otp/request_otp_code(.:format)
  // function(project_id, options)
  request_otp_code_project_otp_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"otp",false],[2,[7,"/",false],[2,[6,"request_otp_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// request_otp_code_project_otp_media_record => /projects/:project_id/otp_media_record/request_otp_code(.:format)
  // function(project_id, options)
  request_otp_code_project_otp_media_record_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"otp_media_record",false],[2,[7,"/",false],[2,[6,"request_otp_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// request_pin_code_api_v1_user_credit_report => /api/v1/users/:user_id/credit_report/request_pin_code(.:format)
  // function(user_id, options)
  request_pin_code_api_v1_user_credit_report_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[2,[7,"/",false],[2,[6,"request_pin_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// request_pin_code_api_v2_project_credit_report => /api/v2/projects/:project_id/credit_report/request_pin_code(.:format)
  // function(project_id, options)
  request_pin_code_api_v2_project_credit_report_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[2,[7,"/",false],[2,[6,"request_pin_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// request_report_api_v1_user_credit_report => /api/v1/users/:user_id/credit_report/request_report(.:format)
  // function(user_id, options)
  request_report_api_v1_user_credit_report_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[2,[7,"/",false],[2,[6,"request_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// request_report_api_v2_project_credit_report => /api/v2/projects/:project_id/credit_report/request_report(.:format)
  // function(project_id, options)
  request_report_api_v2_project_credit_report_path: Utils.route([["project_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"project_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[2,[7,"/",false],[2,[6,"request_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// resend_code_financier_user_two_factor_authentication => /financier_users/two_factor_authentication/resend_code(.:format)
  // function(options)
  resend_code_financier_user_two_factor_authentication_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[6,"two_factor_authentication",false],[2,[7,"/",false],[2,[6,"resend_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// resend_code_user_two_factor_authentication => /users/two_factor_authentication/resend_code(.:format)
  // function(options)
  resend_code_user_two_factor_authentication_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"two_factor_authentication",false],[2,[7,"/",false],[2,[6,"resend_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reset_password_api_v3_financier_users_passwords => /api/v3/financier_users/passwords/reset_password(.:format)
  // function(options)
  reset_password_api_v3_financier_users_passwords_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[6,"passwords",false],[2,[7,"/",false],[2,[6,"reset_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// reset_password_api_v3_users_passwords => /api/v3/users/passwords/reset_password(.:format)
  // function(options)
  reset_password_api_v3_users_passwords_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"passwords",false],[2,[7,"/",false],[2,[6,"reset_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// reset_password_instructions_financier_product_application => /financier/product_applications/:id/reset_password_instructions(.:format)
  // function(id, options)
  reset_password_instructions_financier_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset_password_instructions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_financier_document_template => /financier/document_templates/:id/restore(.:format)
  // function(id, options)
  restore_financier_document_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"document_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// result_api_v1_preapproval => /api/v1/preapprovals/:id/result(.:format)
  // function(id, options)
  result_api_v1_preapproval_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"preapprovals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"result",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// retry_with_new_joint_moral_obligor_financier_arbol_score_result => /financier/arbol_score_results/:id/retry_with_new_joint_moral_obligor(.:format)
  // function(id, options)
  retry_with_new_joint_moral_obligor_financier_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"retry_with_new_joint_moral_obligor",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// retry_with_new_joint_moral_obligor_observer_arbol_score_result => /observer/arbol_score_results/:id/retry_with_new_joint_moral_obligor(.:format)
  // function(id, options)
  retry_with_new_joint_moral_obligor_observer_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"retry_with_new_joint_moral_obligor",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// retry_with_new_joint_moral_obligor_user_arbol_score_result => /users/:user_id/arbol_score_results/:id/retry_with_new_joint_moral_obligor(.:format)
  // function(user_id, id, options)
  retry_with_new_joint_moral_obligor_user_arbol_score_result_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"retry_with_new_joint_moral_obligor",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// retry_with_new_joint_obligor_financier_arbol_score_result => /financier/arbol_score_results/:id/retry_with_new_joint_obligor(.:format)
  // function(id, options)
  retry_with_new_joint_obligor_financier_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"retry_with_new_joint_obligor",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// retry_with_new_joint_obligor_observer_arbol_score_result => /observer/arbol_score_results/:id/retry_with_new_joint_obligor(.:format)
  // function(id, options)
  retry_with_new_joint_obligor_observer_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"retry_with_new_joint_obligor",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// retry_with_new_joint_obligor_pfae_financier_arbol_score_result => /financier/arbol_score_results/:id/retry_with_new_joint_obligor_pfae(.:format)
  // function(id, options)
  retry_with_new_joint_obligor_pfae_financier_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"retry_with_new_joint_obligor_pfae",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// retry_with_new_joint_obligor_pfae_observer_arbol_score_result => /observer/arbol_score_results/:id/retry_with_new_joint_obligor_pfae(.:format)
  // function(id, options)
  retry_with_new_joint_obligor_pfae_observer_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"retry_with_new_joint_obligor_pfae",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// retry_with_new_joint_obligor_pfae_user_arbol_score_result => /users/:user_id/arbol_score_results/:id/retry_with_new_joint_obligor_pfae(.:format)
  // function(user_id, id, options)
  retry_with_new_joint_obligor_pfae_user_arbol_score_result_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"retry_with_new_joint_obligor_pfae",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// retry_with_new_joint_obligor_user_arbol_score_result => /users/:user_id/arbol_score_results/:id/retry_with_new_joint_obligor(.:format)
  // function(user_id, id, options)
  retry_with_new_joint_obligor_user_arbol_score_result_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"retry_with_new_joint_obligor",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// sample_financier_leads_importers => /financier/leads/importers/sample(.:format)
  // function(options)
  sample_financier_leads_importers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"leads",false],[2,[7,"/",false],[2,[6,"importers",false],[2,[7,"/",false],[2,[6,"sample",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sat_report => /sat_reports/:id(.:format)
  // function(id, options)
  sat_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sat_reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sat_reports => /sat_reports(.:format)
  // function(options)
  sat_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sat_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// satws_file_download_satws_reports => /satws_reports/satws_file_download(.:format)
  // function(options)
  satws_file_download_satws_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"satws_reports",false],[2,[7,"/",false],[2,[6,"satws_file_download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// schema_user_data_financier_product_application => /financier/product_applications/:id/schema_user_data(.:format)
  // function(id, options)
  schema_user_data_financier_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"schema_user_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// search_api_v1_car_versions => /api/v1/car_versions/search(.:format)
  // function(options)
  search_api_v1_car_versions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"car_versions",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// search_products => /products/search(.:format)
  // function(options)
  search_products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// send_reset_password_mail_api_v3_financier_users_passwords => /api/v3/financier_users/passwords/send_reset_password_mail(.:format)
  // function(options)
  send_reset_password_mail_api_v3_financier_users_passwords_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[6,"passwords",false],[2,[7,"/",false],[2,[6,"send_reset_password_mail",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// send_reset_password_mail_api_v3_users_passwords => /api/v3/users/passwords/send_reset_password_mail(.:format)
  // function(options)
  send_reset_password_mail_api_v3_users_passwords_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"passwords",false],[2,[7,"/",false],[2,[6,"send_reset_password_mail",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sidekiq_web => /sidekiq
  // function(options)
  sidekiq_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"sidekiq",false]]),
// signed_contract_document_callbacks_customers_kavak_clouds => /callbacks/customers/kavak_clouds/signed_contract_document(.:format)
  // function(options)
  signed_contract_document_callbacks_customers_kavak_clouds_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"kavak_clouds",false],[2,[7,"/",false],[2,[6,"signed_contract_document",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// start_api_v1_preapproval => /api/v1/preapprovals/:id/start(.:format)
  // function(id, options)
  start_api_v1_preapproval_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"preapprovals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"start",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// start_preapproval_car_dealership_user_user => /agencia/clientes/:id/start_preapproval(.:format)
  // function(id, options)
  start_preapproval_car_dealership_user_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"agencia",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"start_preapproval",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// status_financier_product_applications => /financier/product_applications/status/:status(.:format)
  // function(status, options)
  status_financier_product_applications_path: Utils.route([["status",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[6,"status",false],[2,[7,"/",false],[2,[3,"status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// status_update_financier_product_application_product_application_document => /financier/product_applications/:product_application_id/product_application_documents/:id/status_update(.:format)
  // function(product_application_id, id, options)
  status_update_financier_product_application_product_application_document_path: Utils.route([["product_application_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"product_application_id",false],[2,[7,"/",false],[2,[6,"product_application_documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"status_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// subjects_order_financier_product_custom_preapproval_form => /financier/products/:product_id/custom_preapproval_form/subjects_order(.:format)
  // function(product_id, options)
  subjects_order_financier_product_custom_preapproval_form_path: Utils.route([["product_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_preapproval_form",false],[2,[7,"/",false],[2,[6,"subjects_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// terms => /terms(.:format)
  // function(options)
  terms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"terms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// tvazteca => /tvazteca(.:format)
  // function(options)
  tvazteca_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tvazteca",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// unblock_financier_product_application => /financier/product_applications/:id/unblock(.:format)
  // function(id, options)
  unblock_financier_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unblock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_and_send_applications_project => /projects/:id/update_and_send_applications(.:format)
  // function(id, options)
  update_and_send_applications_project_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_and_send_applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_comments_product_application => /product_applications/:id/update_comments(.:format)
  // function(id, options)
  update_comments_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_data_api_v3_folio => /api/v3/folios/:id/update_data(.:format)
  // function(id, options)
  update_data_api_v3_folio_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"folios",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_employment_document_observer_product_application => /observer/product_applications/:id/update_employment_document(.:format)
  // function(id, options)
  update_employment_document_observer_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_employment_document",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_offer_params_project => /projects/:id/update_offer_params(.:format)
  // function(id, options)
  update_offer_params_project_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_offer_params",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_owner_financier_product_application => /financier/product_applications/:id/update_owner(.:format)
  // function(id, options)
  update_owner_financier_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_owner",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_pending_product_applications => /product_applications/update_pending(.:format)
  // function(options)
  update_pending_product_applications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[6,"update_pending",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_registered_satws_satws_reports => /satws_reports/update_registered_satws(.:format)
  // function(options)
  update_registered_satws_satws_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"satws_reports",false],[2,[7,"/",false],[2,[6,"update_registered_satws",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_to_verify_project => /projects/:id/update_to_verify(.:format)
  // function(id, options)
  update_to_verify_project_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_to_verify",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// upload_admin_car_dealership_car_prices => /admin/car_dealerships/:car_dealership_id/car_prices/upload(.:format)
  // function(car_dealership_id, options)
  upload_admin_car_dealership_car_prices_path: Utils.route([["car_dealership_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"car_dealerships",false],[2,[7,"/",false],[2,[3,"car_dealership_id",false],[2,[7,"/",false],[2,[6,"car_prices",false],[2,[7,"/",false],[2,[6,"upload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// upload_admin_tooltip_texts => /admin/tooltip_texts/upload(.:format)
  // function(options)
  upload_admin_tooltip_texts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tooltip_texts",false],[2,[7,"/",false],[2,[6,"upload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// upload_template_financier_financier_users => /financier/financier_users/upload_template(.:format)
  // function(options)
  upload_template_financier_financier_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"financier_users",false],[2,[7,"/",false],[2,[6,"upload_template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user => /users/:id(.:format)
  // function(id, options)
  user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_active => /users/:user_id/actives/:id(.:format)
  // function(user_id, id, options)
  user_active_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"actives",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_actives => /users/:user_id/actives(.:format)
  // function(user_id, options)
  user_actives_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"actives",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_actors_actor_obligor => /user/actors/actor_obligors/:id(.:format)
  // function(id, options)
  user_actors_actor_obligor_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"actors",false],[2,[7,"/",false],[2,[6,"actor_obligors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_actors_actor_obligors => /user/actors/actor_obligors(.:format)
  // function(options)
  user_actors_actor_obligors_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"actors",false],[2,[7,"/",false],[2,[6,"actor_obligors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_actors_actors_clone => /user/actors/actors_clone/:id(.:format)
  // function(id, options)
  user_actors_actors_clone_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"actors",false],[2,[7,"/",false],[2,[6,"actors_clone",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_actors_actors_clone_index => /user/actors/actors_clone(.:format)
  // function(options)
  user_actors_actors_clone_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"actors",false],[2,[7,"/",false],[2,[6,"actors_clone",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_address => /users/:user_id/addresses/:id(.:format)
  // function(user_id, id, options)
  user_address_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_addresses => /users/:user_id/addresses(.:format)
  // function(user_id, options)
  user_addresses_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_arbol_score_result => /users/:user_id/arbol_score_results/:id(.:format)
  // function(user_id, id, options)
  user_arbol_score_result_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_arbol_score_result_address => /users/:user_id/arbol_score_results/:arbol_score_result_id/address(.:format)
  // function(user_id, arbol_score_result_id, options)
  user_arbol_score_result_address_path: Utils.route([["user_id",true],["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// user_arbol_score_result_credit_report => /users/:user_id/arbol_score_results/:arbol_score_result_id/credit_report(.:format)
  // function(user_id, arbol_score_result_id, options)
  user_arbol_score_result_credit_report_path: Utils.route([["user_id",true],["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// user_arbol_score_result_family_data => /users/:user_id/arbol_score_results/:arbol_score_result_id/family_data(.:format)
  // function(user_id, arbol_score_result_id, options)
  user_arbol_score_result_family_data_path: Utils.route([["user_id",true],["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"family_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// user_arbol_score_result_financial_data => /users/:user_id/arbol_score_results/:arbol_score_result_id/financial_data(.:format)
  // function(user_id, arbol_score_result_id, options)
  user_arbol_score_result_financial_data_path: Utils.route([["user_id",true],["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// user_arbol_score_result_geolocation_data => /users/:user_id/arbol_score_results/:arbol_score_result_id/geolocation_data(.:format)
  // function(user_id, arbol_score_result_id, options)
  user_arbol_score_result_geolocation_data_path: Utils.route([["user_id",true],["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"geolocation_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// user_arbol_score_result_personal_data => /users/:user_id/arbol_score_results/:arbol_score_result_id/personal_data(.:format)
  // function(user_id, arbol_score_result_id, options)
  user_arbol_score_result_personal_data_path: Utils.route([["user_id",true],["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"personal_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// user_arbol_score_result_rejections => /users/:user_id/arbol_score_results/:arbol_score_result_id/rejections(.:format)
  // function(user_id, arbol_score_result_id, options)
  user_arbol_score_result_rejections_path: Utils.route([["user_id",true],["arbol_score_result_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"arbol_score_result_id",false],[2,[7,"/",false],[2,[6,"rejections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// user_arbol_score_results => /users/:user_id/arbol_score_results(.:format)
  // function(user_id, options)
  user_arbol_score_results_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_asr_thank_you_page => /user/asr_thank_you_page(.:format)
  // function(options)
  user_asr_thank_you_page_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"asr_thank_you_page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_authorization_medias => /users/:user_id/authorization_medias(.:format)
  // function(user_id, options)
  user_authorization_medias_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"authorization_medias",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_bank_account => /users/:user_id/bank_accounts/:id(.:format)
  // function(user_id, id, options)
  user_bank_account_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_bank_accounts => /users/:user_id/bank_accounts(.:format)
  // function(user_id, options)
  user_bank_accounts_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"bank_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_bureau_request_document => /users/:user_id/bureau_request_documents/:id(.:format)
  // function(user_id, id, options)
  user_bureau_request_document_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"bureau_request_documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_bureau_request_documents => /users/:user_id/bureau_request_documents(.:format)
  // function(user_id, options)
  user_bureau_request_documents_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"bureau_request_documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_bureau_request_kikoya_fiel_document => /users/:user_id/bureau_request_kikoya_fiel_documents/:id(.:format)
  // function(user_id, id, options)
  user_bureau_request_kikoya_fiel_document_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"bureau_request_kikoya_fiel_documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_bureau_request_kikoya_fiel_documents => /users/:user_id/bureau_request_kikoya_fiel_documents(.:format)
  // function(user_id, options)
  user_bureau_request_kikoya_fiel_documents_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"bureau_request_kikoya_fiel_documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_circulo_report_authorizations => /user/circulo_reports/:circulo_report_id/authorizations(.:format)
  // function(circulo_report_id, options)
  user_circulo_report_authorizations_path: Utils.route([["circulo_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"circulo_reports",false],[2,[7,"/",false],[2,[3,"circulo_report_id",false],[2,[7,"/",false],[2,[6,"authorizations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_circulo_report_pin_codes => /user/circulo_reports/:circulo_report_id/pin_codes(.:format)
  // function(circulo_report_id, options)
  user_circulo_report_pin_codes_path: Utils.route([["circulo_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"circulo_reports",false],[2,[7,"/",false],[2,[3,"circulo_report_id",false],[2,[7,"/",false],[2,[6,"pin_codes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_confirmation => /users/confirmation(.:format)
  // function(options)
  user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_confirmation_status => /users/:user_id/confirmation_status(.:format)
  // function(user_id, options)
  user_confirmation_status_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"confirmation_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_confirmations => /user/confirmations(.:format)
  // function(options)
  user_confirmations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"confirmations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_contact_request => /users/:user_id/contact_requests/:id(.:format)
  // function(user_id, id, options)
  user_contact_request_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"contact_requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_contact_requests => /users/:user_id/contact_requests(.:format)
  // function(user_id, options)
  user_contact_requests_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"contact_requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_credit_report => /users/:user_id/credit_report(.:format)
  // function(user_id, options)
  user_credit_report_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"credit_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_customer => /users/:user_id/customers/:id(.:format)
  // function(user_id, id, options)
  user_customer_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_customers => /users/:user_id/customers(.:format)
  // function(user_id, options)
  user_customers_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_debt => /users/:user_id/debts/:id(.:format)
  // function(user_id, id, options)
  user_debt_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"debts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_debts => /users/:user_id/debts(.:format)
  // function(user_id, options)
  user_debts_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"debts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_document => /users/:user_id/documents/:id(.:format)
  // function(user_id, id, options)
  user_document_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_document_type => /user/:user_id/document_types/:type(.:format)
  // function(user_id, type, options)
  user_document_type_path: Utils.route([["user_id",true],["type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_document_types => /user/:user_id/document_types(.:format)
  // function(user_id, options)
  user_document_types_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"document_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_documents => /users/:user_id/documents(.:format)
  // function(user_id, options)
  user_documents_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_employment_record => /users/:user_id/employment_records/:id(.:format)
  // function(user_id, id, options)
  user_employment_record_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"employment_records",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_employment_records => /users/:user_id/employment_records(.:format)
  // function(user_id, options)
  user_employment_records_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"employment_records",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_family_data => /users/:user_id/family_data(.:format)
  // function(user_id, options)
  user_family_data_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"family_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_financial_asset => /users/:user_id/financial_assets/:id(.:format)
  // function(user_id, id, options)
  user_financial_asset_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"financial_assets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_financial_assets => /users/:user_id/financial_assets(.:format)
  // function(user_id, options)
  user_financial_assets_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"financial_assets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_financial_data => /users/:user_id/financial_data(.:format)
  // function(user_id, options)
  user_financial_data_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"financial_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_financing_config => /users/:user_id/financing_config(.:format)
  // function(user_id, options)
  user_financing_config_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"financing_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_geolocation_data => /users/:user_id/geolocation_data(.:format)
  // function(user_id, options)
  user_geolocation_data_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"geolocation_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_guarantor => /users/:user_id/guarantors/:id(.:format)
  // function(user_id, id, options)
  user_guarantor_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_guarantors => /users/:user_id/guarantors(.:format)
  // function(user_id, options)
  user_guarantors_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"guarantors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_heritage => /users/:user_id/heritages/:id(.:format)
  // function(user_id, id, options)
  user_heritage_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_heritages => /users/:user_id/heritages(.:format)
  // function(user_id, options)
  user_heritages_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"heritages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_id_verification => /user/id_verification(.:format)
  // function(options)
  user_id_verification_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"id_verification",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_id_verification_status => /user/id_verification_status(.:format)
  // function(options)
  user_id_verification_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"id_verification_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_judgment => /users/:user_id/judgments/:id(.:format)
  // function(user_id, id, options)
  user_judgment_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_judgments => /users/:user_id/judgments(.:format)
  // function(user_id, options)
  user_judgments_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"judgments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_main_competitor => /users/:user_id/main_competitors/:id(.:format)
  // function(user_id, id, options)
  user_main_competitor_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"main_competitors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_main_competitors => /users/:user_id/main_competitors(.:format)
  // function(user_id, options)
  user_main_competitors_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"main_competitors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_mi_fiel => /user/mi_fiel/signatures/stored_signed_document/:id(.:format)
  // function(id, options)
  user_mi_fiel_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"mi_fiel",false],[2,[7,"/",false],[2,[6,"signatures",false],[2,[7,"/",false],[2,[6,"stored_signed_document",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// user_mi_fiel_certificates => /user/mi_fiel/certificates(.:format)
  // function(options)
  user_mi_fiel_certificates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"mi_fiel",false],[2,[7,"/",false],[2,[6,"certificates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_mi_fiel_signature => /user/mi_fiel/signatures/:id(.:format)
  // function(id, options)
  user_mi_fiel_signature_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"mi_fiel",false],[2,[7,"/",false],[2,[6,"signatures",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_money_plus_request => /users/:user_id/money_plus_requests/:id(.:format)
  // function(user_id, id, options)
  user_money_plus_request_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"money_plus_requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_passiv => /users/:user_id/passivs/:id(.:format)
  // function(user_id, id, options)
  user_passiv_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"passivs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_passivs => /users/:user_id/passivs(.:format)
  // function(user_id, options)
  user_passivs_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"passivs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_password => /users/password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_personal_data => /users/:user_id/personal_data(.:format)
  // function(user_id, options)
  user_personal_data_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"personal_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_personal_reference => /users/:user_id/personal_references/:id(.:format)
  // function(user_id, id, options)
  user_personal_reference_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"personal_references",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_personal_references => /users/:user_id/personal_references(.:format)
  // function(user_id, options)
  user_personal_references_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"personal_references",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_preapproval => /users/:user_id/preapproval(.:format)
  // function(user_id, options)
  user_preapproval_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"preapproval",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_previous_studies => /users/:user_id/previous_studies(.:format)
  // function(user_id, options)
  user_previous_studies_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"previous_studies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_previous_study => /users/:user_id/previous_studies/:id(.:format)
  // function(user_id, id, options)
  user_previous_study_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"previous_studies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_product_service => /users/:user_id/product_services/:id(.:format)
  // function(user_id, id, options)
  user_product_service_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_product_services => /users/:user_id/product_services(.:format)
  // function(user_id, options)
  user_product_services_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"product_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_project => /user/projects/:id(.:format)
  // function(id, options)
  user_project_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"projects",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_provider => /users/:user_id/providers/:id(.:format)
  // function(user_id, id, options)
  user_provider_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_providers => /users/:user_id/providers(.:format)
  // function(user_id, options)
  user_providers_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"providers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_registration => /users(.:format)
  // function(options)
  user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_share => /users/:user_id/shares/:id(.:format)
  // function(user_id, id, options)
  user_share_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"shares",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_shares => /users/:user_id/shares(.:format)
  // function(user_id, options)
  user_shares_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"shares",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_stp_researchable_account => /users/:user_id/stp_researchable_accounts/:id(.:format)
  // function(user_id, id, options)
  user_stp_researchable_account_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"stp_researchable_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_stp_researchable_accounts => /users/:user_id/stp_researchable_accounts(.:format)
  // function(user_id, options)
  user_stp_researchable_accounts_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"stp_researchable_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_thank_you => /user/thank_you(.:format)
  // function(options)
  user_thank_you_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"thank_you",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_token_authentications => /user/token_authentications(.:format)
  // function(options)
  user_token_authentications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"token_authentications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_two_factor_authentication => /users/two_factor_authentication(.:format)
  // function(options)
  user_two_factor_authentication_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"two_factor_authentication",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_unlock => /users/unlock(.:format)
  // function(options)
  user_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_waitings => /user/waitings(.:format)
  // function(options)
  user_waitings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"waitings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users => /users(.:format)
  // function(options)
  users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// users_boa_vista_reports => /users/boa_vista_reports(.:format)
  // function(options)
  users_boa_vista_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"boa_vista_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// va_servicios_callbacks_internal_requests_amortization_tables => /callbacks/internal_requests/amortization_tables/va_servicios(.:format)
  // function(options)
  va_servicios_callbacks_internal_requests_amortization_tables_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"internal_requests",false],[2,[7,"/",false],[2,[6,"amortization_tables",false],[2,[7,"/",false],[2,[6,"va_servicios",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// validate_financier_leads_importer => /financier/leads/importers/:id/validate(.:format)
  // function(id, options)
  validate_financier_leads_importer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"leads",false],[2,[7,"/",false],[2,[6,"importers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// validate_financier_product_custom_smart_score => /financier/products/:product_id/custom_smart_scores/:id/validate(.:format)
  // function(product_id, id, options)
  validate_financier_product_custom_smart_score_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_smart_scores",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// validate_financier_product_custom_smart_score_cluster => /financier/products/:product_id/custom_smart_scores/:custom_smart_score_id/clusters/:id/validate(.:format)
  // function(product_id, custom_smart_score_id, id, options)
  validate_financier_product_custom_smart_score_cluster_path: Utils.route([["product_id",true],["custom_smart_score_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"custom_smart_scores",false],[2,[7,"/",false],[2,[3,"custom_smart_score_id",false],[2,[7,"/",false],[2,[6,"clusters",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// validate_financier_product_smart_score_rule => /financier/products/:product_id/smart_score_rules/:id/validate(.:format)
  // function(product_id, id, options)
  validate_financier_product_smart_score_rule_path: Utils.route([["product_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"products",false],[2,[7,"/",false],[2,[3,"product_id",false],[2,[7,"/",false],[2,[6,"smart_score_rules",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// veridoc_callback_callbacks_veridoc_id => /callbacks/veridoc_id/veridoc_callback(.:format)
  // function(options)
  veridoc_callback_callbacks_veridoc_id_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"veridoc_id",false],[2,[7,"/",false],[2,[6,"veridoc_callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// verification_request => /valida_ref/:id(.:format)
  // function(id, options)
  verification_request_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"valida_ref",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// verification_request_response => /verification_request_response/:id(.:format)
  // function(id, options)
  verification_request_response_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"verification_request_response",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// verify_financier_leads_importer => /financier/leads/importers/:id/verify(.:format)
  // function(id, options)
  verify_financier_leads_importer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"leads",false],[2,[7,"/",false],[2,[6,"importers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"verify",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// verify_observer_product_application => /observer/product_applications/:id/verify(.:format)
  // function(id, options)
  verify_observer_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"observer",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"verify",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// vertical_type_financier_product_applications => /financier/product_applications/vertical_type/:vertical_type(.:format)
  // function(vertical_type, options)
  vertical_type_financier_product_applications_path: Utils.route([["vertical_type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[6,"vertical_type",false],[2,[7,"/",false],[2,[3,"vertical_type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// videocalls_callbacks_services => /callbacks/services/videocalls(.:format)
  // function(options)
  videocalls_callbacks_services_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"callbacks",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[6,"videocalls",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// waiting_response_financier_request_mailer => /financier/request_mailers/:id/waiting_response(.:format)
  // function(id, options)
  waiting_response_financier_request_mailer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"request_mailers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"waiting_response",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// warranties => /warranties(.:format)
  // function(options)
  warranties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warranties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// warranty => /warranties/:id(.:format)
  // function(id, options)
  warranty_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warranties",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// warranty_document => /warranties/:warranty_id/documents/:id(.:format)
  // function(warranty_id, id, options)
  warranty_document_path: Utils.route([["warranty_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warranties",false],[2,[7,"/",false],[2,[3,"warranty_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// warranty_documents => /warranties/:warranty_id/documents(.:format)
  // function(warranty_id, options)
  warranty_documents_path: Utils.route([["warranty_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warranties",false],[2,[7,"/",false],[2,[3,"warranty_id",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// warranty_warranty_document => /warranties/:warranty_id/warranty_documents/:id(.:format)
  // function(warranty_id, id, options)
  warranty_warranty_document_path: Utils.route([["warranty_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warranties",false],[2,[7,"/",false],[2,[3,"warranty_id",false],[2,[7,"/",false],[2,[6,"warranty_documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// warranty_warranty_documents => /warranties/:warranty_id/warranty_documents(.:format)
  // function(warranty_id, options)
  warranty_warranty_documents_path: Utils.route([["warranty_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warranties",false],[2,[7,"/",false],[2,[3,"warranty_id",false],[2,[7,"/",false],[2,[6,"warranty_documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// welcome_with_password_financier_product_application => /financier/product_applications/:id/welcome_with_password(.:format)
  // function(id, options)
  welcome_with_password_financier_product_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"product_applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"welcome_with_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// zipfile_financier_arbol_score_result => /financier/arbol_score_results/:id/zipfile(.:format)
  // function(id, options)
  zipfile_financier_arbol_score_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"financier",false],[2,[7,"/",false],[2,[6,"arbol_score_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"zipfile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    try {
      module.exports = result;
    } catch (error1) {
      error = error1;
      if (error.name !== 'TypeError') {
        throw error;
      }
    }
  } else {
    Utils.namespace(this, null, result);
  }

  return result;

}).call(this);

